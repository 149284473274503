import {
  TGiftDependantFragment,
  TGiftEmployeeFragment,
  TGiftFieldsFragment,
  TGiftRecipientFieldsFragment,
  TListEmployeeAndDependatsGiftsQuery,
} from '../../generated/graphql';

export type TGift = Exclude<
  TListEmployeeAndDependatsGiftsQuery['listEmployeeAndDependatsGifts']['items'][number],
  null | undefined
>;

const isEmployeeRecipient = (recipient: TGiftRecipientFieldsFragment): recipient is TGiftEmployeeFragment => {
  return (recipient as TGiftDependantFragment).type == null;
};

export const getGiftRecipientType = (gift: TGiftFieldsFragment) => {
  if (gift.recipient == null) return undefined;

  if (isEmployeeRecipient(gift.recipient)) {
    return 'Employee';
  } else {
    switch (gift.recipient.type) {
      case 'child':
        return 'Child';
      case 'significantOther':
        return 'Signficant Other';
      case 'pet':
        return gift.recipient.petType || 'Pet';
    }
  }
};

export const getGiftEventType = (gift: TGift) => {
  switch (gift?.event?.type) {
    case 'birthday':
      return 'Birthday';
    case 'workAnniversary':
      return 'Work Anniversary';
    case 'newHire':
      return 'New Hire';
    case 'babyGift':
      return 'Baby Gift';
    case 'wedding':
      return 'Wedding/Engagement';
    case 'replacementWorkAnniversary':
      return 'Replacement Work Anniversary';
    case 'replacementEmployeeBirthday':
      return 'Replacement Employee Birthday';
    case 'carePackage':
      return 'Care Package';
    case 'customBricks':
      return 'Custom Bricks';
    case 'other':
      return gift?.event?.customType;
  }
};
