import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { useUpdateEmployeeMutation } from '../../../generated/graphql';
import { useCallback, useMemo } from 'react';
import { EMPLOYEES_PATH, EMPLOYEE_ACCOUNT_PATH, EMPLOYEE_PATH } from '../../../routes/paths';
import { useFragmentOrFetchEmployee } from '../../../entities/employee/shared';
import { ErrorPage, InfoCardInner, LoadingPage, Modal } from '@chocolate-soup-inc/cs-frontend-components';

import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import _ from 'lodash';

import styles from './EmployeeForm.module.scss';

export const EmployeeAcceptGoogleAddressForm = () => {
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const company = usePrivateCompanyContext();

  const [update, { loading }] = useUpdateEmployeeMutation();

  const closeModal = useCallback(() => {
    if (employeeId) {
      navigate(
        generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${EMPLOYEE_ACCOUNT_PATH}`, {
          employeeId,
        }),
      );
    } else {
      navigate(generatePath(EMPLOYEES_PATH));
    }
  }, [employeeId, navigate]);

  const {
    data: employee,
    error: getEmployeeError,
    loading: getEmployeeLoading,
  } = useFragmentOrFetchEmployee({
    id: employeeId as string,
    companyId: company.id,
  });

  const updateAddress = useCallback(() => {
    if (employee && employee.address?.addressFromGoogle) {
      return update({
        variables: {
          id: employee.id as string,
          companyId: employee.companyId as string,
          version: employee._version as number,
          input: {
            address: {
              ..._.pick(employee.address.addressFromGoogle, [
                'address1',
                'address2',
                'city',
                'state',
                'country',
                'zipCode',
              ]),
              useGoogleAddress: true,
            },
          },
        },
      })
        .then(() => {
          toast.success('New address accepted successfully.');
          closeModal();
        })
        .catch((error) => {
          console.log(serializeError(error));
          toast.error('There was an error accepting the new address');
        });
    }
  }, [closeModal, employee, update]);

  const title = useMemo(() => {
    if (employee?.fullName) {
      return `Update ${employee.fullName} Address`;
    } else {
      return 'Update Employee Address';
    }
  }, [employee?.fullName]);

  const onCancel = useCallback(() => {
    if (employee?.address?.missingInfo) {
      toast.error(
        'The address is missing some required fields. While these fields are not filled, gifts will not be sent to this address.',
      );
    } else if (!employee?.address?.googleValidated) {
      toast.warning(
        'The address could not be validated by Google. Make sure it is correct otherwise it might be returned when sent.',
      );
    }

    closeModal();
  }, [closeModal, employee?.address?.googleValidated, employee?.address?.missingInfo]);

  if (getEmployeeError) {
    return <ErrorPage error={getEmployeeError} />;
  }

  if (employee == null || getEmployeeLoading) {
    return (
      <Modal closeModal={onCancel}>
        <LoadingPage />
      </Modal>
    );
  }

  if (employee.address == null || employee.address.addressFromGoogle == null) {
    return null;
  }

  return (
    <Modal
      cancelLabel='Use Address as Inputted'
      closeModal={onCancel}
      confirmLabel='Accept Google Validated Address'
      confirmLoading={loading}
      headline={title}
      onCancelClick={onCancel}
      onConfirmClick={updateAddress}
      showCancelButton={true}
      showConfirmButton={true}
      size='large'
      supportingText='A different address was found on Google. Use new address?'
    >
      <div className={styles.acceptAddressContainer}>
        <InfoCardInner
          data={employee.address}
          sections={[
            {
              title: 'Inputted Address',
              columns: [
                {
                  label: 'Address 1',
                  accessor: 'address1',
                },
                {
                  label: 'Address 2',
                  accessor: 'address2',
                },
                {
                  label: 'City',
                  accessor: 'city',
                },
                {
                  label: 'State',
                  accessor: 'state',
                },
                {
                  label: 'Country',
                  accessor: 'country',
                },
                {
                  label: 'Postal Code',
                  accessor: 'zipCode',
                },
              ],
            },
          ]}
        />
        <InfoCardInner
          data={employee.address.addressFromGoogle}
          sections={[
            {
              title: 'Address from Google',
              columns: [
                {
                  label: 'Address 1',
                  accessor: 'address1',
                },
                {
                  label: 'Address 2',
                  accessor: 'address2',
                },
                {
                  label: 'City',
                  accessor: 'city',
                },
                {
                  label: 'State',
                  accessor: 'state',
                },
                {
                  label: 'Country',
                  accessor: 'country',
                },
                {
                  label: 'Postal Code',
                  accessor: 'zipCode',
                },
              ],
            },
          ]}
        />
      </div>
    </Modal>
  );
};
