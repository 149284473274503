import { useCallback, useMemo, useState } from 'react';
import {
  ErrorPage,
  ExtendedFAB,
  LoadingPage,
  InfoCardInner,
  TMenuItemProps,
  Modal,
  TConfirmationModalProps,
  ConfirmationModal,
  TInfoCardSectionColumnProps,
  FloatingMenu,
  IconButton,
  Tooltip,
  Chip,
} from '@chocolate-soup-inc/cs-frontend-components';

import styles from './EmployeeDependants.module.scss';

import { generatePath, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { DEPENDANT_PATH, EDIT_PATH, EMPLOYEES_PATH, NEW_PATH } from '../../../routes/paths';
import {
  TDependantType,
  TEmployee,
  TListEmployeeDependantsQuery,
  useDeleteDependantMutation,
} from '../../../generated/graphql';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { useFragmentOrFetchEmployee } from '../../../entities/employee/shared';
import { useQueryAllEmployeeDependants } from '../../../entities/dependant/shared';
import { EmployeeTabsModal } from '../EmployeeTabs/EmployeeTabsModal';
import { serializeError } from 'serialize-error';
import { toast } from 'react-toastify';

type TDependant = Exclude<TListEmployeeDependantsQuery['listEmployeeDependants']['items'][number], null>;

type TDependantCardProps = {
  dependant: TDependant;
  index: number;
};

const DependantCard = (props: TDependantCardProps) => {
  const { dependant, index } = props;

  const navigate = useNavigate();

  const [confirmationProps, setConfirmationProps] =
    useState<Omit<TConfirmationModalProps, 'closeModal' | 'confirmLoading' | 'onCancelClick'>>();
  const [deleteDependant, { loading: deleteLoading }] = useDeleteDependantMutation();

  const dependantColumns = useCallback((dependant: TDependant) => {
    const cols: TInfoCardSectionColumnProps<TDependant>[] = [
      {
        label: 'Relationship',
        accessor: 'type',
        render: (data) => {
          if (data?.type === 'significantOther') {
            return 'Significant Other';
          } else {
            return _.capitalize(data?.type);
          }
        },
      },
      {
        label: 'Date of Birth',
        accessor: 'birthDate',
      },
      {
        label: 'First Name',
        accessor: 'firstName',
      },
    ];

    if (dependant.type === TDependantType.Pet) {
      cols.push({
        label: 'Pet Type',
        accessor: 'petType',
      });
    } else {
      cols.push({
        label: 'Last Name',
        accessor: 'lastName',
      });
    }

    if (dependant.type === TDependantType.SignificantOther) {
      cols.push({
        label: 'Donate Birthday Gift',
        render: (data) => {
          if (data?.donateBirthdayGift) {
            return 'yes';
          } else {
            return 'no';
          }
        },
      });
    }

    return cols;
  }, []);

  const onEditClick = useCallback(
    (dependant: TDependant) => {
      navigate(
        generatePath(`${DEPENDANT_PATH}/${EDIT_PATH}`, {
          dependantId: dependant.id,
        }),
      );
    },
    [navigate],
  );

  const onActualDelete = useCallback(
    (dependant: TDependant) => {
      deleteDependant({
        variables: {
          id: dependant.id,
          companyId: dependant.companyId,
          version: dependant._version,
        },
      })
        .then()
        .catch((error) => {
          console.error(serializeError(error));
          toast.error(error.message);
        })
        .then(() => {
          setConfirmationProps(undefined);
        });
    },
    [deleteDependant],
  );

  const onDeleteClick = useCallback(
    (dependant: TDependant) => {
      setConfirmationProps({
        headline: 'Delete dependant?',
        supportingText: `Are you sure you want to delete the dependant ${dependant?.fullName}?`,
        confirmLabel: 'Delete',
        onConfirmClick: () => onActualDelete(dependant),
      });
    },
    [onActualDelete],
  );

  const options: TMenuItemProps[] = useMemo(() => {
    return [
      {
        disabled: deleteLoading,
        label: 'Edit Dependant',
        onClick: () => onEditClick(dependant),
        type: 'text',
      },
      {
        disabled: deleteLoading,
        label: 'Remove Dependant',
        loading: deleteLoading,
        onClick: () => onDeleteClick(dependant),
        type: 'text',
      },
    ];
  }, [deleteLoading, dependant, onDeleteClick, onEditClick]);

  const [optionsOpen, setOptionsOpen] = useState<boolean>(false);

  const topComponent = useMemo(() => {
    return (
      <div className={styles.dependantTopComponent}>
        <h1>{`Dependant #${index + 1}`}</h1>
        <div className={styles.dependantTopComponentAlerts}>
          {dependant.birthDate == null && (
            <Tooltip message='Missing birth date.'>
              <Chip
                className={styles.errorChip}
                label='Missing birth date'
                readonly={true}
                selected={true}
                variant='suggestion'
              />
            </Tooltip>
          )}
        </div>
        <FloatingMenu autoPlacement={true} onOpenChange={setOptionsOpen} open={optionsOpen} options={options}>
          <IconButton
            className={styles.dependantTopComponentIcon}
            icon='more_vert'
            onClick={() => setOptionsOpen(true)}
            variant='standard'
            iconProps={{ weight: 300 }}
          />
        </FloatingMenu>
      </div>
    );
  }, [dependant.birthDate, index, options, optionsOpen]);

  return (
    <>
      {!_.isEmpty(confirmationProps) && (
        <ConfirmationModal
          {...confirmationProps}
          closeModal={() => setConfirmationProps(undefined)}
          confirmLoading={deleteLoading}
          onCancelClick={() => setConfirmationProps(undefined)}
        />
      )}
      <InfoCardInner<TDependant>
        key={dependant.id}
        sections={[
          {
            title: `Dependant #${index + 1}`,
            topComponent: () => topComponent,
            columns: dependantColumns(dependant),
          },
          {
            columns: [
              {
                label: 'Allergies & Dietary Restrictions',
                accessor: 'foodPreferences',
              },
            ],
          },
        ]}
        data={dependant}
      />
    </>
  );
};

export const EmployeeDependants = () => {
  const navigate = useNavigate();
  const { employeeId } = useParams();

  const { id: companyId } = usePrivateCompanyContext();

  const {
    data: employee,
    error,
    loading,
  } = useFragmentOrFetchEmployee({
    id: employeeId as string,
    companyId,
  });

  const { data, loading: queryingDependants } = useQueryAllEmployeeDependants({
    employeeId: employeeId as string,
    companyId,
  });

  const title = useMemo(() => {
    const fullName = employee?.fullName;
    if (fullName) return `${fullName} Dependants`;
    else return 'Employee Dependants';
  }, [employee?.fullName]);

  const dependants: TDependant[] = useMemo(() => {
    return _.compact(data?.listEmployeeDependants?.items || []);
  }, [data]);

  if (employee == null || loading || queryingDependants) {
    return (
      <Modal closeModal={() => navigate(generatePath(EMPLOYEES_PATH))} headline={title}>
        <LoadingPage />
      </Modal>
    );
  }
  if (error) return <ErrorPage error={error} />;

  return (
    <>
      <EmployeeTabsModal employee={employee as TEmployee} headline={title}>
        <div className={styles.employeeDependants}>
          <ExtendedFAB
            leadingIcon='add'
            label='Add Dependant'
            variant='surface'
            onClick={() => {
              navigate(generatePath(NEW_PATH));
            }}
          />
          {dependants.map((dependant, index) => (
            <DependantCard key={dependant.id} dependant={dependant} index={index} />
          ))}
        </div>
      </EmployeeTabsModal>
    </>
  );
};
