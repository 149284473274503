import { useFragment } from '@apollo/client';
import { getFragmentName } from '@chocolate-soup-inc/cs-api-consumer-utils';
import {
  Card,
  ConfirmationModal,
  ErrorPage,
  ExtendedFAB,
  FloatingMenu,
  IconButton,
  LoadingPage,
  TableInner,
  TConfirmationModalProps,
  TMenuItemProps,
  useAuthContext,
} from '@chocolate-soup-inc/cs-frontend-components';
import { CellContext } from '@tanstack/react-table';
import clsx from 'clsx';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { TListUser, useQueryAllUsers } from '../../../entities/user/shared';
import {
  TUserType,
  useDeleteUserMutation,
  UserFieldsFragmentDoc,
  useTransferOwnershipMutation,
  useUpdateUserMutation,
} from '../../../generated/graphql';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { NEW_PATH } from '../../../routes/paths';
import styles from './Users.module.scss';

const UserOptions = (
  props: CellContext<TListUser, unknown> & {
    setConfirmationProps: (v: any) => void;
    setActionLoading: (v: boolean) => void;
  },
) => {
  const { cell, setConfirmationProps, setActionLoading } = props;

  const { companyId, email, isCompanyOwner, name, type, _version: version } = cell.row.original;

  const { user: userAttributes } = useAuthContext();

  const { data: currentUser } = useFragment<TListUser, any>({
    from: {
      ...(userAttributes || {}),
      __typename: 'User',
    },
    fragment: UserFieldsFragmentDoc,
    fragmentName: getFragmentName(UserFieldsFragmentDoc),
  });

  const [deleteUser, { loading: deleteUserLoading }] = useDeleteUserMutation({
    variables: {
      email,
      companyId,
      version,
    },
  });

  const onActualDelete = useCallback(() => {
    setActionLoading(true);
    return deleteUser()
      .then()
      .catch((error) => {
        console.error(serializeError(error));
        toast.error(error.message);
      })
      .then(() => {
        setConfirmationProps(undefined);
        setActionLoading(false);
      });
  }, [deleteUser, setActionLoading, setConfirmationProps]);

  const onDeleteClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Delete user?',
      supportingText: `Are you sure you want to delete the user ${name}?`,
      confirm: 'Delete',
      onConfirmClick: onActualDelete,
    });
  }, [name, onActualDelete, setConfirmationProps]);

  const targetType = useMemo(() => {
    return type === TUserType.Admin ? TUserType.TeamLeader : TUserType.Admin;
  }, [type]);

  const [updateRole, { loading: updateRoleLoading }] = useUpdateUserMutation({
    variables: {
      email,
      companyId,
      version,
      input: {
        type: targetType,
      },
    },
  });

  const onActualUpdateRole = useCallback(() => {
    setActionLoading(true);
    return updateRole()
      .then()
      .catch((error) => {
        console.error(serializeError(error));
        toast.error(error.message);
      })
      .then(() => {
        setConfirmationProps(undefined);
        setActionLoading(false);
      });
  }, [setActionLoading, setConfirmationProps, updateRole]);

  const onUpdateRoleClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Delete user?',
      supportingText: `Are you sure you want to update the user ${name} role to ${
        targetType === TUserType.Admin ? 'admin' : 'team leader'
      }?`,
      confirm: 'Delete',
      onConfirmClick: onActualUpdateRole,
    });
  }, [name, onActualUpdateRole, setConfirmationProps, targetType]);

  const [transferOwnership, { loading: transferOwnershipLoading }] = useTransferOwnershipMutation();

  const onActualTransferOwnership = useCallback(() => {
    if (currentUser?.isCompanyOwner && !isCompanyOwner) {
      setActionLoading(true);
      return transferOwnership({
        variables: {
          oldOwnerEmail: currentUser?.email as string,
          _oldOwnerVersion: currentUser?._version as number,
          newOwnerEmail: email,
          companyId,
        },
      })
        .then()
        .catch((error) => {
          console.error(serializeError(error));
          toast.error(error.message);
        })
        .then(() => {
          setConfirmationProps(undefined);
          setActionLoading(false);
        });
    }
  }, [
    companyId,
    currentUser?._version,
    currentUser?.email,
    currentUser?.isCompanyOwner,
    email,
    isCompanyOwner,
    setActionLoading,
    setConfirmationProps,
    transferOwnership,
  ]);

  const onTransferOwnershipClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Transfer ownership?',
      supportingText: `Are you sure you want to transfer the ownership of the company to the user ${name}?`,
      confirm: 'Transfer',
      onConfirmClick: onActualTransferOwnership,
    });
  }, [name, onActualTransferOwnership, setConfirmationProps]);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const options = useMemo(() => {
    const list: TMenuItemProps[] = [];
    const disabled = deleteUserLoading || updateRoleLoading || transferOwnershipLoading;

    if (!isCompanyOwner) {
      list.push({
        className: styles.editUserTypeMenuItem,
        disabled,
        label: type === 'admin' ? 'Change Role to Team Leader' : 'Change Role to Administrator',
        loading: updateRoleLoading,
        onClick: onUpdateRoleClick,
        type: 'text',
      });

      if (currentUser?.isCompanyOwner && !isCompanyOwner) {
        list.push({
          disabled,
          label: 'Transfer ownership',
          loading: transferOwnershipLoading,
          onClick: onTransferOwnershipClick,
          type: 'text',
        });
      }

      if (email !== currentUser?.email) {
        list.push({
          className: styles.removeUserMenuItem,
          disabled,
          label: 'Remove User',
          loading: deleteUserLoading,
          onClick: onDeleteClick,
          type: 'text',
        });
      }
    }

    // if (email === currentUser?.email) {
    //   list.push({
    //     label: 'Edit account',
    //     type: 'text',
    //   })
    // }

    return list;
  }, [
    currentUser?.email,
    currentUser?.isCompanyOwner,
    deleteUserLoading,
    email,
    isCompanyOwner,
    onDeleteClick,
    onTransferOwnershipClick,
    onUpdateRoleClick,
    transferOwnershipLoading,
    type,
    updateRoleLoading,
  ]);

  if (options.length === 0) return null;

  return (
    <div className={styles.userOptions}>
      <FloatingMenu placement='bottom-end' open={menuOpen} onOpenChange={setMenuOpen} options={options}>
        <IconButton icon='more_vert' onClick={() => setMenuOpen(!menuOpen)} variant='standard' />
      </FloatingMenu>
    </div>
  );
};

export const Users = () => {
  const navigate = useNavigate();
  const company = usePrivateCompanyContext();

  const { data, error, loading } = useQueryAllUsers({
    companyId: company.id,
  });

  const [confirmationProps, setConfirmationProps] =
    useState<Omit<TConfirmationModalProps, 'closeModal' | 'confirmLoading' | 'onCancelClick'>>();

  const [actionLoading, setActionLoading] = useState<boolean>();

  const users: TListUser[] = useMemo(() => {
    return _.compact(data?.listUsers.items || []).sort((a, b) => a.name.localeCompare(b.name));
  }, [data?.listUsers.items]);

  const getRowId = useCallback((user: TListUser) => {
    return user.email;
  }, []);

  if (error) return <ErrorPage error={error} />;
  if (data == null || loading) return <LoadingPage />;

  return (
    <>
      {!_.isEmpty(confirmationProps) && (
        <ConfirmationModal
          {...confirmationProps}
          closeModal={() => setConfirmationProps(undefined)}
          confirmLoading={actionLoading}
          onCancelClick={() => setConfirmationProps(undefined)}
        />
      )}
      <div className={clsx(styles.companyUserRoles, 'grid grid-cols-12 h-full')}>
        <div className='col-span-8 col-start-3'>
          <ExtendedFAB
            className={styles.addNew}
            leadingIcon='add'
            label='Add User'
            variant='surface'
            onClick={() => navigate(NEW_PATH)}
          />
          <Card className={styles.card} readOnly={true} variant='outlined'>
            <h1 className={styles.title}>{`Users (${users.length})`}</h1>
            <TableInner<TListUser>
              className={styles.usersTable}
              columns={[
                {
                  header: 'Name',
                  cell: ({ cell }: CellContext<TListUser, unknown>) => {
                    const { name, email } = cell.row.original;

                    return (
                      <div className={styles.userName}>
                        <span className={styles.name}>{name}</span>
                        <span className={styles.email}>{email}</span>
                      </div>
                    );
                  },
                },
                {
                  header: 'Account type',
                  cell: ({ cell }: CellContext<TListUser, unknown>) => {
                    const { isCompanyOwner, type } = cell.row.original;

                    return (
                      <span>
                        {isCompanyOwner && 'Primary Owner'}
                        {!isCompanyOwner && (type === 'admin' ? 'Admin' : 'Team Leader')}
                      </span>
                    );
                  },
                },
                {
                  header: '',
                  accessorKey: 'type',
                  cell: (cell) => (
                    <UserOptions
                      {...cell}
                      setConfirmationProps={setConfirmationProps}
                      setActionLoading={setActionLoading}
                    />
                  ),
                },
              ]}
              data={users}
              emptyText='No users registered yet.'
              getRowId={getRowId}
            />
          </Card>
        </div>
      </div>
    </>
  );
};
