import { ErrorPage, InfoCardInner, LoadingPage, Modal, readableDate } from '@chocolate-soup-inc/cs-frontend-components';
import _ from 'lodash';
import { useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useFragmentOrFetchEmployee } from '../../../entities/employee/shared';
import { TListGift, useQueryAllEmployeeAndDependantsGifts } from '../../../entities/gift/shared';
import { TEmployee } from '../../../generated/graphql';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { EMPLOYEES_PATH } from '../../../routes/paths';
import { getGiftEventType, getGiftRecipientType } from '../../../shared/gifts/utils';
import { StatusCell, TrackingLink } from '../../../shared/shipments/utils';
import { EmployeeTabsModal } from '../EmployeeTabs/EmployeeTabsModal';
import styles from './EmployeeGifts.module.scss';

export const EmployeeGifts = () => {
  const { employeeId } = useParams();
  const navigate = useNavigate();

  const company = usePrivateCompanyContext();

  const {
    data: employee,
    error: employeeError,
    loading: employeeLoading,
  } = useFragmentOrFetchEmployee({
    id: employeeId as string,
    companyId: company?.id,
  });

  const { data, error, loading } = useQueryAllEmployeeAndDependantsGifts({
    employeeId: employeeId as string,
    companyId: company?.id,
  });

  const title = useMemo(() => {
    const fullName = employee?.fullName;
    if (fullName) return `${fullName} and Dependants Gifts`;
    else return 'Employee and Dependants Dependants';
  }, [employee?.fullName]);

  const gifts: TListGift[] = useMemo(() => {
    return _.compact(data?.listEmployeeAndDependatsGifts.items || []);
  }, [data]);

  if (employee == null || employeeLoading || loading) {
    return (
      <Modal closeModal={() => navigate(generatePath(EMPLOYEES_PATH))} headline={title}>
        <LoadingPage />
      </Modal>
    );
  }
  if (employeeError || error) return <ErrorPage error={employeeError || error} />;
  if (!gifts) return null;

  return (
    <EmployeeTabsModal employee={employee as TEmployee} headline={title}>
      <div className={styles.employeeGifts}>
        {(gifts == null || gifts.length === 0) && <p className={styles.emptyText}>No gifts for this employee yet.</p>}
        {gifts?.map((gift, index) => (
          <InfoCardInner<TListGift>
            key={gift.id}
            data={gift}
            sections={[
              {
                title: `Gift #${gifts.length - index}`,
                columns: [
                  {
                    label: 'Recipient Name',
                    render: (data) => data?.recipient?.fullName,
                  },
                  {
                    label: 'Relationship',
                    render: () => {
                      return <span>{getGiftRecipientType(gift)}</span>;
                    },
                  },
                  {
                    label: 'Event Type',
                    render: () => {
                      return <span>{getGiftEventType(gift)}</span>;
                    },
                  },
                  {
                    label: 'Event Date',
                    render: (data) => readableDate(data?.event?.eventDate),
                  },
                ],
              },
              {
                columns: [
                  {
                    label: 'Status',
                    render: () => StatusCell(gift.shipment),
                  },
                  {
                    label: 'Shipping Date',
                    render: (data) => readableDate(data?.shipment?.actualShippingDate || data?.shipment?.shippingDate),
                  },
                  {
                    label: 'Tracking Number',
                    render: () => (gift.shipment ? TrackingLink(gift.shipment) : null),
                  },
                ],
              },
            ]}
          />
        ))}
      </div>
    </EmployeeTabsModal>
  );
};
