import { ErrorPage, InfoCardInner, LoadingPage, readableDate } from '@chocolate-soup-inc/cs-frontend-components';
import _ from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFragmentOrFetchShipment } from '../../../entities/shipment/shared';
import { TGetShipmentQuery } from '../../../generated/graphql';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { ShipmentModal } from './ShipmentModal';
import { getGiftEventType, getGiftRecipientType } from '../../../shared/gifts/utils';

type TShipment = Exclude<TGetShipmentQuery['getShipment'], null | undefined>;

type TGift = Exclude<Exclude<TShipment['gifts'], null | undefined>['items'][number], null | undefined>;

export const ShipmentGifts = () => {
  const { deliveryId } = useParams();
  const { id: companyId } = usePrivateCompanyContext();

  const {
    data: shipment,
    error,
    loading,
  } = useFragmentOrFetchShipment({
    id: deliveryId as string,
    companyId,
  });

  const gifts = useMemo(() => {
    return _.compact(shipment?.gifts?.items || []);
  }, [shipment?.gifts?.items]);

  if (error) return <ErrorPage error={error} />;

  return (
    <ShipmentModal headline='Shipment Gifts'>
      {loading && <LoadingPage />}
      {!loading &&
        gifts.map((gift, index) => (
          <InfoCardInner
            key={gift.id}
            data={gift as TGift}
            sections={[
              {
                title: `Gift #${index + 1}`,
                columns: [
                  {
                    label: 'Send To',
                    render: (data) => data?.recipient?.fullName,
                  },
                  {
                    label: 'Relationship',
                    render: () => {
                      return <span>{getGiftRecipientType(gift as TGift)}</span>;
                    },
                  },
                  {
                    label: 'Event Type',
                    render: () => {
                      return <span>{getGiftEventType(gift as TGift)}</span>;
                    },
                  },
                  {
                    label: 'Event Date',
                    render: (data) => readableDate(data?.event?.eventDate),
                  },
                ],
              },
            ]}
          />
        ))}
    </ShipmentModal>
  );
};
