import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { useUpdateOfficeMutation } from '../../../generated/graphql';
import { useCallback, useMemo } from 'react';
import { ADMINISTRATION_PATH, OFFICES_PATH } from '../../../routes/paths';
import { ErrorPage, InfoCardInner, LoadingPage, Modal } from '@chocolate-soup-inc/cs-frontend-components';

import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import _ from 'lodash';

import styles from './OfficeForm.module.scss';
import { useFragmentOrFetchOffice } from '../../../entities/office/shared';

export const OfficeAcceptGoogleAddressForm = () => {
  const navigate = useNavigate();
  const { officeId } = useParams();
  const company = usePrivateCompanyContext();

  const [update, { loading }] = useUpdateOfficeMutation();

  const closeModal = useCallback(() => {
    navigate(generatePath(`${ADMINISTRATION_PATH}/${OFFICES_PATH}`));
  }, [navigate]);

  const {
    data: office,
    error: getOfficeError,
    loading: getOfficeLoading,
  } = useFragmentOrFetchOffice({
    id: officeId as string,
    companyId: company.id,
  });

  const updateAddress = useCallback(() => {
    if (office && office.address?.addressFromGoogle) {
      return update({
        variables: {
          id: office.id as string,
          companyId: office.companyId as string,
          version: office._version as number,
          input: {
            address: {
              ..._.pick(office.address.addressFromGoogle, [
                'address1',
                'address2',
                'city',
                'state',
                'country',
                'zipCode',
              ]),
              useGoogleAddress: true,
            },
          },
        },
      })
        .then(() => {
          toast.success('New address accepted successfully.');
          closeModal();
        })
        .catch((error) => {
          console.log(serializeError(error));
          toast.error('There was an error accepting the new address');
        });
    }
  }, [closeModal, office, update]);

  const title = useMemo(() => {
    if (office?.name) {
      return `Update ${office.name} Address`;
    } else {
      return 'Update Office Address';
    }
  }, [office?.name]);

  const onCancel = useCallback(() => {
    if (office?.address?.missingInfo) {
      toast.error(
        'The address is missing some required fields. While these fields are not filled, gifts will not be sent to this address.',
      );
    } else if (!office?.address?.googleValidated) {
      toast.warning(
        'The address could not be validated by Google. Make sure it is correct otherwise it might be returned when sent.',
      );
    }

    closeModal();
  }, [closeModal, office?.address?.googleValidated, office?.address?.missingInfo]);

  if (getOfficeError) {
    return <ErrorPage error={getOfficeError} />;
  }

  if (office == null || getOfficeLoading) {
    return (
      <Modal closeModal={onCancel}>
        <LoadingPage />
      </Modal>
    );
  }

  if (office.address == null || office.address.addressFromGoogle == null) {
    return null;
  }

  return (
    <Modal
      cancelLabel='Use Address as Inputted'
      closeModal={onCancel}
      confirmLabel='Accept Google Validated Address'
      confirmLoading={loading}
      headline={title}
      onCancelClick={onCancel}
      onConfirmClick={updateAddress}
      showCancelButton={true}
      showConfirmButton={true}
      size='large'
      supportingText='A different address was found on Google. Use new address?'
    >
      <div className={styles.acceptAddressContainer}>
        <InfoCardInner
          data={office.address}
          sections={[
            {
              title: 'Inputted Address',
              columns: [
                {
                  label: 'Address 1',
                  accessor: 'address1',
                },
                {
                  label: 'Address 2',
                  accessor: 'address2',
                },
                {
                  label: 'City',
                  accessor: 'city',
                },
                {
                  label: 'State',
                  accessor: 'state',
                },
                {
                  label: 'Country',
                  accessor: 'country',
                },
                {
                  label: 'Postal Code',
                  accessor: 'zipCode',
                },
              ],
            },
          ]}
        />
        <InfoCardInner
          data={office.address.addressFromGoogle}
          sections={[
            {
              title: 'Address from Google',
              columns: [
                {
                  label: 'Address 1',
                  accessor: 'address1',
                },
                {
                  label: 'Address 2',
                  accessor: 'address2',
                },
                {
                  label: 'City',
                  accessor: 'city',
                },
                {
                  label: 'State',
                  accessor: 'state',
                },
                {
                  label: 'Country',
                  accessor: 'country',
                },
                {
                  label: 'Postal Code',
                  accessor: 'zipCode',
                },
              ],
            },
          ]}
        />
      </div>
    </Modal>
  );
};
