import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AWSDate: { input: any; output: any };
  AWSDateTime: { input: any; output: any };
  AWSEmail: { input: any; output: any };
  AWSIPAddress: { input: any; output: any };
  AWSJSON: { input: any; output: any };
  AWSPhone: { input: any; output: any };
  AWSTime: { input: any; output: any };
  AWSTimestamp: { input: any; output: any };
  AWSURL: { input: any; output: any };
};

export type TAddress = {
  __typename?: 'Address';
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  _version: Scalars['Int']['output'];
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  addressFromGoogle?: Maybe<TAddressFromGoogle>;
  alert?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  employee?: Maybe<TEmployee>;
  googleValidated?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  invalid?: Maybe<Scalars['Boolean']['output']>;
  missingInfo?: Maybe<Scalars['Boolean']['output']>;
  office?: Maybe<TOffice>;
  parentType: TAddressParentTypes;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  useGoogleAddress?: Maybe<Scalars['Boolean']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type TAddressFromGoogle = {
  __typename?: 'AddressFromGoogle';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export enum TAddressParentTypes {
  Company = 'company',
  Employee = 'employee',
  Office = 'office',
}

export type TAddressesConnection = {
  __typename?: 'AddressesConnection';
  items: Array<Maybe<TAddress>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type TAlert = {
  __typename?: 'Alert';
  acknowledgedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  acknowledgedBy?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  message: Scalars['String']['output'];
  newItem?: Maybe<Scalars['AWSJSON']['output']>;
  oldItem?: Maybe<Scalars['AWSJSON']['output']>;
};

export type TAlertInput = {
  acknowledgedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  acknowledgedBy?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  newItem?: InputMaybe<Scalars['AWSJSON']['input']>;
  oldItem?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export enum TCarriers {
  CanadaPost = 'canada_post',
  DhlExpressCanada = 'dhl_express_canada',
  PurolatorCa = 'purolator_ca',
  Ups = 'ups',
}

export type TCompaniesConnection = {
  __typename?: 'CompaniesConnection';
  items: Array<Maybe<TCompany>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type TCompany = {
  __typename?: 'Company';
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  _version: Scalars['Int']['output'];
  accountManager?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  address?: Maybe<TAddress>;
  bambooHRAPIKey?: Maybe<Scalars['String']['output']>;
  bambooHRPetsTableName?: Maybe<Scalars['String']['output']>;
  bambooHRSubdomain?: Maybe<Scalars['String']['output']>;
  childBirthdayActivated: Scalars['Boolean']['output'];
  companyWideLegoCustomization?: Maybe<Scalars['String']['output']>;
  configuration?: Maybe<TConfigurationType>;
  contact?: Maybe<TContact>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  customGiftConfiguration?: Maybe<TCustomGiftConfigurationType>;
  customLego?: Maybe<Scalars['Boolean']['output']>;
  customShippingConfiguration?: Maybe<Array<Maybe<TCustomShippingConfigurationType>>>;
  customWorkAnniversaryNotes?: Maybe<Scalars['String']['output']>;
  deliveryMethod: TDeliveryMethod;
  emailDomains: Array<Scalars['String']['output']>;
  employeeBirthdayActivated: Scalars['Boolean']['output'];
  employeeNewHireKitActivated: Scalars['Boolean']['output'];
  employeeWorkAnniversaryActivated: Scalars['Boolean']['output'];
  employees: TEmployeesConnection;
  endDate?: Maybe<Scalars['AWSDate']['output']>;
  fullyConfigured?: Maybe<Scalars['Boolean']['output']>;
  googleLoginEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasCustomOnboardBrick?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importFromBambooHR: Scalars['Boolean']['output'];
  isLive?: Maybe<Scalars['Boolean']['output']>;
  lastRosterUpload?: Maybe<Scalars['AWSDateTime']['output']>;
  legoActivated?: Maybe<Scalars['Boolean']['output']>;
  legoColor?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['AWSURL']['output']>;
  name: Scalars['String']['output'];
  normalizedName?: Maybe<Scalars['String']['output']>;
  offices: TOfficesConnection;
  oldSystemId?: Maybe<Scalars['Int']['output']>;
  passwordLoginEnabled?: Maybe<Scalars['Boolean']['output']>;
  petBirthdayActivated: Scalars['Boolean']['output'];
  rosterId?: Maybe<Scalars['String']['output']>;
  signAs?: Maybe<Scalars['String']['output']>;
  significantOtherBirthdayActivated: Scalars['Boolean']['output'];
  specialNote?: Maybe<Scalars['String']['output']>;
  spreadsheetId?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['AWSDate']['output']>;
  subdomain: Scalars['String']['output'];
  subscriptionPriceCurrency?: Maybe<Scalars['String']['output']>;
  subscriptionPricePerUser?: Maybe<Scalars['Float']['output']>;
  swag?: Maybe<Scalars['String']['output']>;
  tissueColor: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userPoolId?: Maybe<Scalars['String']['output']>;
  users: TUsersConnection;
  workAnniversaryGiftVariation?: Maybe<TWorkAnniversaryGiftVariations>;
};

export type TCompanyEmployeesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TCompanyOfficesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TCompanyUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TConfigurationType = {
  __typename?: 'ConfigurationType';
  authCloudfrontDomain?: Maybe<Scalars['String']['output']>;
  authUserPoolClientId?: Maybe<Scalars['String']['output']>;
  authUserPoolClientName?: Maybe<Scalars['String']['output']>;
  authUserPoolDomainURL?: Maybe<Scalars['String']['output']>;
  authUserPoolId?: Maybe<Scalars['String']['output']>;
  authUserPoolName?: Maybe<Scalars['String']['output']>;
  frontendCertificateArn?: Maybe<Scalars['String']['output']>;
  frontendDistributionArn?: Maybe<Scalars['String']['output']>;
  frontendDistributionDomainName?: Maybe<Scalars['String']['output']>;
  frontendDistributionId?: Maybe<Scalars['String']['output']>;
};

export type TContact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['AWSEmail']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type TCreateAddressMutationInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  useGoogleAddress?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type TCreateCompanyMutationInput = {
  accountManager?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<TCreateAddressMutationInput>;
  bambooHRAPIKey?: InputMaybe<Scalars['String']['input']>;
  bambooHRPetsTableName?: InputMaybe<Scalars['String']['input']>;
  bambooHRSubdomain?: InputMaybe<Scalars['String']['input']>;
  childBirthdayActivated?: InputMaybe<Scalars['Boolean']['input']>;
  companyWideLegoCustomization?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<TCreateContactMutationInput>;
  customLego?: InputMaybe<Scalars['Boolean']['input']>;
  customShippingConfiguration?: InputMaybe<Array<InputMaybe<TCustomShippingConfigurationInput>>>;
  customWorkAnniversaryNotes?: InputMaybe<Scalars['String']['input']>;
  deliveryMethod: TDeliveryMethod;
  emailDomains: Array<Scalars['String']['input']>;
  employeeBirthdayActivated?: InputMaybe<Scalars['Boolean']['input']>;
  employeeNewHireKitActivated?: InputMaybe<Scalars['Boolean']['input']>;
  employeeWorkAnniversaryActivated?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['AWSDate']['input']>;
  googleLoginEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasCustomOnboardBrick?: InputMaybe<Scalars['Boolean']['input']>;
  importFromBambooHR?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  legoActivated?: InputMaybe<Scalars['Boolean']['input']>;
  legoColor?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['AWSURL']['input']>;
  name: Scalars['String']['input'];
  oldSystemId?: InputMaybe<Scalars['Int']['input']>;
  passwordLoginEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  petBirthdayActivated?: InputMaybe<Scalars['Boolean']['input']>;
  rosterId?: InputMaybe<Scalars['String']['input']>;
  signAs?: InputMaybe<Scalars['String']['input']>;
  significantOtherBirthdayActivated?: InputMaybe<Scalars['Boolean']['input']>;
  specialNote?: InputMaybe<Scalars['String']['input']>;
  spreadsheetId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['AWSDate']['input']>;
  subdomain: Scalars['String']['input'];
  subscriptionPriceCurrency?: InputMaybe<Scalars['String']['input']>;
  subscriptionPricePerUser?: InputMaybe<Scalars['Float']['input']>;
  swag?: InputMaybe<Scalars['String']['input']>;
  tissueColor: Scalars['String']['input'];
  workAnniversaryGiftVariation?: InputMaybe<TWorkAnniversaryGiftVariations>;
};

export type TCreateContactMutationInput = {
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type TCreateDependantMutationInput = {
  birthDate?: InputMaybe<Scalars['AWSDate']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  donateBirthdayGift?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId?: InputMaybe<Scalars['ID']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  foodPreferences?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  oldSystemId?: InputMaybe<Scalars['Int']['input']>;
  petType?: InputMaybe<Scalars['String']['input']>;
  preferredFirstName?: InputMaybe<Scalars['String']['input']>;
  type: TDependantType;
};

export type TCreateEmployeeMutationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<TCreateAddressMutationInput>;
  birthDate?: InputMaybe<Scalars['AWSDate']['input']>;
  companyId: Scalars['ID']['input'];
  customOnboardBrick?: InputMaybe<Scalars['String']['input']>;
  deliveryMethod?: InputMaybe<TDeliveryMethod>;
  dependants?: InputMaybe<Array<InputMaybe<TCreateDependantMutationInput>>>;
  donateBirthdayGift?: InputMaybe<Scalars['Boolean']['input']>;
  donateWorkAnniversaryGift?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  foodPreferences?: InputMaybe<Scalars['String']['input']>;
  giftOnHoldFrom?: InputMaybe<Scalars['AWSDate']['input']>;
  giftOnHoldTo?: InputMaybe<Scalars['AWSDate']['input']>;
  giftPausedFrom?: InputMaybe<Scalars['AWSDate']['input']>;
  giftPausedTo?: InputMaybe<Scalars['AWSDate']['input']>;
  hireDate?: InputMaybe<Scalars['AWSDate']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['ID']['input']>;
  oldSystemId?: InputMaybe<Scalars['Int']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferredFirstName?: InputMaybe<Scalars['String']['input']>;
  sweaterSize?: InputMaybe<Scalars['String']['input']>;
  tShirtSize?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type TCreateEventGiftResponse = {
  __typename?: 'CreateEventGiftResponse';
  companyId: Scalars['ID']['output'];
  event?: Maybe<TEvent>;
  gift?: Maybe<TGift>;
};

export type TCreateEventMutationInput = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
  age: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  customType?: InputMaybe<Scalars['String']['input']>;
  deliveredAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  employeeId: Scalars['ID']['input'];
  eventDate: Scalars['AWSDate']['input'];
  giftId?: InputMaybe<Scalars['ID']['input']>;
  groupedToEventId?: InputMaybe<Scalars['ID']['input']>;
  isPaused?: InputMaybe<Scalars['Boolean']['input']>;
  recipientId: Scalars['ID']['input'];
  referenceEventId?: InputMaybe<Scalars['ID']['input']>;
  skippedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  type: TEventTypes;
  wasPaused?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TCreateGiftMutationInput = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
  companyId: Scalars['ID']['input'];
  customType?: InputMaybe<Scalars['String']['input']>;
  doneAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  employeeId: Scalars['ID']['input'];
  eventDate: Scalars['AWSDate']['input'];
  eventId: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  oldSystemId?: InputMaybe<Scalars['Int']['input']>;
  rack?: InputMaybe<Scalars['String']['input']>;
  recipientId: Scalars['ID']['input'];
  recreatedFromId?: InputMaybe<Scalars['ID']['input']>;
  shipmentId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<TGiftStatuses>;
  type: TGiftTypes;
};

export type TCreateGiftPackageMutationInput = {
  name: Scalars['String']['input'];
  products: Array<InputMaybe<Scalars['String']['input']>>;
  type: TGiftPackageType;
};

export type TCreateOfficeMutationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<TCreateAddressMutationInput>;
  companyId: Scalars['ID']['input'];
  contact?: InputMaybe<TCreateContactMutationInput>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  oldSystemId?: InputMaybe<Scalars['Int']['input']>;
};

export type TCreateProductMutationInput = {
  country: Scalars['String']['input'];
  description: Scalars['String']['input'];
  hsCode: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};

export type TCreateReferenceEventMutationInput = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
  companyId: Scalars['ID']['input'];
  employeeId: Scalars['ID']['input'];
  fullDate: Scalars['AWSDate']['input'];
  recipientId: Scalars['ID']['input'];
  type: TReferenceEventTypes;
};

export type TCreateRosterFileInput = {
  changelog?: InputMaybe<Scalars['AWSJSON']['input']>;
  changelogFile?: InputMaybe<TCreateRosterFileS3ObjectInput>;
  companyId: Scalars['ID']['input'];
  file?: InputMaybe<TCreateRosterFileS3ObjectInput>;
  headerMapping?: InputMaybe<Scalars['AWSJSON']['input']>;
  mapping: Scalars['AWSJSON']['input'];
  officeMapping?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type TCreateRosterFileS3ObjectInput = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  region: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type TCreateShipmentMutationInput = {
  addressId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  customsData?: InputMaybe<Array<InputMaybe<TCustomsDataInput>>>;
  label?: InputMaybe<TShipmentLabelInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  oldSystemId?: InputMaybe<Scalars['Int']['input']>;
  packageInfo?: InputMaybe<TPackageInfoInput>;
  rack?: InputMaybe<Scalars['String']['input']>;
  shippingDate?: InputMaybe<Scalars['AWSDate']['input']>;
  status: TShipmentStatuses;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type TCreateShipmentWithGiftsMutationInput = {
  addressId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  customsData?: InputMaybe<Array<InputMaybe<TCustomsDataInput>>>;
  giftsIds: Array<Scalars['ID']['input']>;
  label?: InputMaybe<TShipmentLabelInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  oldSystemId?: InputMaybe<Scalars['Int']['input']>;
  packageInfo?: InputMaybe<TPackageInfoInput>;
  rack?: InputMaybe<Scalars['String']['input']>;
  shippingDate?: InputMaybe<Scalars['AWSDate']['input']>;
  status?: InputMaybe<TShipmentStatuses>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type TCreateUserMutationInput = {
  companyId: Scalars['ID']['input'];
  email: Scalars['AWSEmail']['input'];
  name: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type: TUserType;
};

export type TCustomGiftConfigurationInput = {
  childBirthday?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  employeeBirthday?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  newHire?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  petBirthday?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  spouseBirthday?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  workAnniversary?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TCustomGiftConfigurationType = {
  __typename?: 'CustomGiftConfigurationType';
  childBirthday?: Maybe<Array<Scalars['String']['output']>>;
  employeeBirthday?: Maybe<Array<Scalars['String']['output']>>;
  newHire?: Maybe<Array<Scalars['String']['output']>>;
  petBirthday?: Maybe<Array<Scalars['String']['output']>>;
  spouseBirthday?: Maybe<Array<Scalars['String']['output']>>;
  workAnniversary?: Maybe<Array<Scalars['String']['output']>>;
};

export type TCustomShippingConfigurationInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  carrier?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
};

export type TCustomShippingConfigurationType = {
  __typename?: 'CustomShippingConfigurationType';
  accountNumber?: Maybe<Scalars['String']['output']>;
  carrier?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type TCustomsData = {
  __typename?: 'CustomsData';
  country: Scalars['String']['output'];
  description: Scalars['String']['output'];
  hsCode: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type TCustomsDataInput = {
  country: Scalars['String']['input'];
  description: Scalars['String']['input'];
  hsCode: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};

export enum TDeliveryMethod {
  Default = 'default',
  Home = 'home',
  Hybrid = 'hybrid',
  Office = 'office',
}

export type TDependant = TRecipient & {
  __typename?: 'Dependant';
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  _version: Scalars['Int']['output'];
  address?: Maybe<TAddress>;
  birthDate?: Maybe<Scalars['AWSDate']['output']>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  donateBirthdayGift?: Maybe<Scalars['Boolean']['output']>;
  employee?: Maybe<TEmployee>;
  employeeId: Scalars['ID']['output'];
  events: TEventsConnection;
  firstName?: Maybe<Scalars['String']['output']>;
  foodPreferences?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gifts: TGiftsConnection;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  oldSystemId?: Maybe<Scalars['Int']['output']>;
  petType?: Maybe<Scalars['String']['output']>;
  preferredFirstName?: Maybe<Scalars['String']['output']>;
  type: TDependantType;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type TDependantEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TDependantGiftsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export enum TDependantType {
  Child = 'child',
  Pet = 'pet',
  SignificantOther = 'significantOther',
}

export type TDependantsConnection = {
  __typename?: 'DependantsConnection';
  items: Array<Maybe<TDependant>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type TEmployee = TRecipient & {
  __typename?: 'Employee';
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  _version: Scalars['Int']['output'];
  active: Scalars['Boolean']['output'];
  address?: Maybe<TAddress>;
  birthDate?: Maybe<Scalars['AWSDate']['output']>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  customOnboardBrick?: Maybe<Scalars['String']['output']>;
  deliveryMethod?: Maybe<TDeliveryMethod>;
  dependants: TDependantsConnection;
  donateBirthdayGift?: Maybe<Scalars['Boolean']['output']>;
  donateWorkAnniversaryGift?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['AWSEmail']['output']>;
  events: TEventsConnection;
  externalId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  foodPreferences?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gifts: TGiftsConnection;
  hireDate?: Maybe<Scalars['AWSDate']['output']>;
  id: Scalars['ID']['output'];
  isPauseEnabled?: Maybe<Scalars['Boolean']['output']>;
  isPaused?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  office?: Maybe<TOffice>;
  officeId?: Maybe<Scalars['ID']['output']>;
  oldSystemId?: Maybe<Scalars['Int']['output']>;
  oldSystemIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  organization?: Maybe<Scalars['String']['output']>;
  pausePeriod?: Maybe<TPausePeriod>;
  pausePeriods?: Maybe<Array<Maybe<TPausePeriod>>>;
  paused?: Maybe<Scalars['Boolean']['output']>;
  phoneNumber?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  preferredFirstName?: Maybe<Scalars['String']['output']>;
  sweaterSize?: Maybe<Scalars['String']['output']>;
  tShirtSize?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type TEmployeeDependantsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TEmployeeEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TEmployeeGiftsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TEmployeesConnection = {
  __typename?: 'EmployeesConnection';
  items: Array<Maybe<TEmployee>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type TEvent = {
  __typename?: 'Event';
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  _version: Scalars['Int']['output'];
  address?: Maybe<TAddress>;
  addressId?: Maybe<Scalars['ID']['output']>;
  age?: Maybe<Scalars['Int']['output']>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  customType?: Maybe<Scalars['String']['output']>;
  deliveredAt?: Maybe<Scalars['AWSDateTime']['output']>;
  employee?: Maybe<TEmployee>;
  employeeId: Scalars['ID']['output'];
  eventDate: Scalars['AWSDate']['output'];
  gift?: Maybe<TGift>;
  giftId?: Maybe<Scalars['ID']['output']>;
  groupedToEvent?: Maybe<TEvent>;
  groupedToEventId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isPaused?: Maybe<Scalars['Boolean']['output']>;
  recipient?: Maybe<TRecipient>;
  recipientId: Scalars['ID']['output'];
  referenceEvent?: Maybe<TReferenceEvent>;
  referenceEventId?: Maybe<Scalars['ID']['output']>;
  skippedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  type: TEventTypes;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  wasPaused?: Maybe<Scalars['Boolean']['output']>;
};

export enum TEventTypes {
  BabyGift = 'babyGift',
  Birthday = 'birthday',
  CarePackage = 'carePackage',
  CustomBricks = 'customBricks',
  NewHire = 'newHire',
  Other = 'other',
  ReplacementEmployeeBirthday = 'replacementEmployeeBirthday',
  ReplacementWorkAnniversary = 'replacementWorkAnniversary',
  Wedding = 'wedding',
  WorkAnniversary = 'workAnniversary',
}

export type TEventsConnection = {
  __typename?: 'EventsConnection';
  items: Array<Maybe<TEvent>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type TGift = {
  __typename?: 'Gift';
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  _version: Scalars['Int']['output'];
  address?: Maybe<TAddress>;
  addressId?: Maybe<Scalars['ID']['output']>;
  alerts?: Maybe<Array<Maybe<TAlert>>>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  customType?: Maybe<Scalars['String']['output']>;
  doneAt?: Maybe<Scalars['AWSDateTime']['output']>;
  employee?: Maybe<TEmployee>;
  employeeId: Scalars['ID']['output'];
  event?: Maybe<TEvent>;
  eventDate: Scalars['AWSDate']['output'];
  eventId: Scalars['ID']['output'];
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDone?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  oldSystemId?: Maybe<Scalars['Int']['output']>;
  rack?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<TRecipient>;
  recipientData?: Maybe<Scalars['AWSJSON']['output']>;
  recipientId: Scalars['ID']['output'];
  recreatedFromId?: Maybe<Scalars['ID']['output']>;
  shipment?: Maybe<TShipment>;
  shipmentId?: Maybe<Scalars['ID']['output']>;
  status: TGiftStatuses;
  type: TGiftTypes;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type TGiftPackage = {
  __typename?: 'GiftPackage';
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _version: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  products: Array<Maybe<Scalars['String']['output']>>;
  type: TGiftPackageType;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type TGiftPackageConnection = {
  __typename?: 'GiftPackageConnection';
  items: Array<Maybe<TGiftPackage>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export enum TGiftPackageType {
  ChildBirthday = 'childBirthday',
  EmployeeBirthday = 'employeeBirthday',
  NewHire = 'newHire',
  PetBirthday = 'petBirthday',
  SpouseBirthday = 'spouseBirthday',
  WorkAnniversary = 'workAnniversary',
}

export enum TGiftStatuses {
  Assembling = 'assembling',
  Done = 'done',
  Qa = 'qa',
  Todo = 'todo',
}

export enum TGiftTypes {
  BabyGift = 'babyGift',
  Birthday = 'birthday',
  CarePackage = 'carePackage',
  CustomBricks = 'customBricks',
  NewHire = 'newHire',
  Other = 'other',
  ReplacementEmployeeBirthday = 'replacementEmployeeBirthday',
  ReplacementWorkAnniversary = 'replacementWorkAnniversary',
  Wedding = 'wedding',
  WorkAnniversary = 'workAnniversary',
}

export type TGiftsConnection = {
  __typename?: 'GiftsConnection';
  items: Array<Maybe<TGift>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type TLambdaConnection = {
  __typename?: 'LambdaConnection';
  data?: Maybe<Scalars['AWSJSON']['output']>;
  error: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export type TLog = {
  __typename?: 'Log';
  arguments?: Maybe<Scalars['AWSJSON']['output']>;
  companyId?: Maybe<Scalars['ID']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identity?: Maybe<Scalars['AWSJSON']['output']>;
  occurredAt?: Maybe<Scalars['AWSDateTime']['output']>;
  parentTypeName?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['ID']['output']>;
  response?: Maybe<Scalars['AWSJSON']['output']>;
  selectionSetGraphQL?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  userType?: Maybe<TLogUserTypes>;
  variables?: Maybe<Scalars['AWSJSON']['output']>;
};

export enum TLogUserTypes {
  Client = 'client',
  Internal = 'internal',
  System = 'system',
}

export type TLogsConnection = {
  __typename?: 'LogsConnection';
  items: Array<Maybe<TLog>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type TMoveGiftMutationInput = {
  status: TGiftStatuses;
};

export type TMoveShipmentMutationInput = {
  status: TShipmentStatuses;
};

export type TMutation = {
  __typename?: 'Mutation';
  addAlertToGift?: Maybe<TGift>;
  addAlertToShipment?: Maybe<TShipment>;
  addGiftsToShipment?: Maybe<TShipment>;
  cancelShipmentForPickup?: Maybe<TShipment>;
  createCSUser?: Maybe<TUser>;
  createCompany?: Maybe<TCompany>;
  createDependant?: Maybe<TDependant>;
  createEmployee?: Maybe<TEmployee>;
  createEvent?: Maybe<TEvent>;
  createEventGift?: Maybe<TCreateEventGiftResponse>;
  createGift?: Maybe<TGift>;
  createGiftPackage?: Maybe<TGiftPackage>;
  createOffice?: Maybe<TOffice>;
  createOneOffEvent?: Maybe<TLambdaConnection>;
  createProduct?: Maybe<TProduct>;
  createReferenceEvent?: Maybe<TReferenceEvent>;
  createRosterFile?: Maybe<TRosterFile>;
  createShipment?: Maybe<TShipment>;
  createShipmentWithGifts?: Maybe<TShipment>;
  createUser?: Maybe<TUser>;
  deleteCompany?: Maybe<TCompany>;
  deleteDependant?: Maybe<TDependant>;
  deleteEmployee?: Maybe<TEmployee>;
  deleteEvent?: Maybe<TEvent>;
  deleteGift?: Maybe<TGift>;
  deleteGiftPackage?: Maybe<TGiftPackage>;
  deleteOffice?: Maybe<TOffice>;
  deleteProduct?: Maybe<TProduct>;
  deleteReferenceEvent?: Maybe<TReferenceEvent>;
  deleteShipment?: Maybe<TShipment>;
  deleteUser?: Maybe<TUser>;
  moveGift?: Maybe<TGift>;
  moveShipment?: Maybe<TShipment>;
  notifyNewHires?: Maybe<Scalars['Boolean']['output']>;
  pauseEmployeeGifts?: Maybe<TEmployee>;
  printShipmentLabel?: Maybe<TShipment>;
  recreateShipment?: Maybe<TShipmentRecreatedResponse>;
  removeGiftFromGroup?: Maybe<TRemovedGiftFromGroupResponse>;
  reshipShipment?: Maybe<TShipment>;
  resyncShipmentWithShipStation?: Maybe<TShipment>;
  setShipmentAsDelivered?: Maybe<TShipment>;
  setShipmentAsPickedUp?: Maybe<TShipment>;
  setShipmentAsReadyToShip?: Maybe<TShipment>;
  setShipmentAsReturned?: Maybe<TShipment>;
  setShipmentAsShipped?: Maybe<TShipment>;
  setShipmentForPickup?: Maybe<TShipment>;
  shipmentLabelPrinted?: Maybe<TShipment>;
  skipAddressAlert?: Maybe<TAddress>;
  skipEvent?: Maybe<TEvent>;
  transferOwnership?: Maybe<Array<Maybe<TUser>>>;
  unpauseEmployeeGifts?: Maybe<TEmployee>;
  unskipEvent?: Maybe<TEvent>;
  updateCompany?: Maybe<TCompany>;
  updateCompanyConfiguration?: Maybe<TCompany>;
  updateDependant?: Maybe<TDependant>;
  updateEmployee?: Maybe<TEmployee>;
  updateEvent?: Maybe<TEvent>;
  updateGift?: Maybe<TGift>;
  updateGiftPackage?: Maybe<TGiftPackage>;
  updateOffice?: Maybe<TOffice>;
  updateProduct?: Maybe<TProduct>;
  updateReferenceEvent?: Maybe<TReferenceEvent>;
  updateRosterFile?: Maybe<TRosterFile>;
  updateShipment?: Maybe<TShipment>;
  updateUser?: Maybe<TUser>;
};

export type TMutationAddAlertToGiftArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TAlertInput;
};

export type TMutationAddAlertToShipmentArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TAlertInput;
};

export type TMutationAddGiftsToShipmentArgs = {
  companyId: Scalars['ID']['input'];
  giftsIds: Array<Scalars['ID']['input']>;
  shipmentId: Scalars['ID']['input'];
};

export type TMutationCancelShipmentForPickupArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationCreateCsUserArgs = {
  companyId: Scalars['ID']['input'];
};

export type TMutationCreateCompanyArgs = {
  input: TCreateCompanyMutationInput;
};

export type TMutationCreateDependantArgs = {
  input: TCreateDependantMutationInput;
};

export type TMutationCreateEmployeeArgs = {
  input: TCreateEmployeeMutationInput;
};

export type TMutationCreateEventArgs = {
  input: TCreateEventMutationInput;
};

export type TMutationCreateEventGiftArgs = {
  _eventVersion: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  eventId: Scalars['ID']['input'];
  group?: InputMaybe<Scalars['String']['input']>;
};

export type TMutationCreateGiftArgs = {
  input: TCreateGiftMutationInput;
};

export type TMutationCreateGiftPackageArgs = {
  input: TCreateGiftPackageMutationInput;
};

export type TMutationCreateOfficeArgs = {
  input: TCreateOfficeMutationInput;
};

export type TMutationCreateOneOffEventArgs = {
  input: Scalars['AWSJSON']['input'];
};

export type TMutationCreateProductArgs = {
  input: TCreateProductMutationInput;
};

export type TMutationCreateReferenceEventArgs = {
  input: TCreateReferenceEventMutationInput;
};

export type TMutationCreateRosterFileArgs = {
  input: TCreateRosterFileInput;
};

export type TMutationCreateShipmentArgs = {
  input: TCreateShipmentMutationInput;
};

export type TMutationCreateShipmentWithGiftsArgs = {
  input: TCreateShipmentWithGiftsMutationInput;
};

export type TMutationCreateUserArgs = {
  input: TCreateUserMutationInput;
};

export type TMutationDeleteCompanyArgs = {
  _version: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationDeleteDependantArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationDeleteEmployeeArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationDeleteEventArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationDeleteGiftArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationDeleteGiftPackageArgs = {
  _version: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationDeleteOfficeArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationDeleteProductArgs = {
  _version: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationDeleteReferenceEventArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationDeleteShipmentArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationDeleteUserArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  email: Scalars['AWSEmail']['input'];
};

export type TMutationMoveGiftArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TMoveGiftMutationInput;
};

export type TMutationMoveShipmentArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TMoveShipmentMutationInput;
};

export type TMutationNotifyNewHiresArgs = {
  events: Array<InputMaybe<TNotifyNewHireMutationInput>>;
};

export type TMutationPauseEmployeeGiftsArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  fromDate?: InputMaybe<Scalars['AWSDate']['input']>;
  id: Scalars['ID']['input'];
  toDate?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type TMutationPrintShipmentLabelArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TPrintShipmentLabelMutationInput;
};

export type TMutationRecreateShipmentArgs = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationRemoveGiftFromGroupArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationReshipShipmentArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationResyncShipmentWithShipStationArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationSetShipmentAsDeliveredArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationSetShipmentAsPickedUpArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationSetShipmentAsReadyToShipArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TSetShipmentAsReadyToShipMutationInput;
};

export type TMutationSetShipmentAsReturnedArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationSetShipmentAsShippedArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationSetShipmentForPickupArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationShipmentLabelPrintedArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TShipmentLabelPrintedMutationInput;
};

export type TMutationSkipAddressAlertArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationSkipEventArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationTransferOwnershipArgs = {
  _oldOwnerVersion: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  newOwnerEmail: Scalars['AWSEmail']['input'];
  oldOwnerEmail: Scalars['AWSEmail']['input'];
};

export type TMutationUnpauseEmployeeGiftsArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationUnskipEventArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TMutationUpdateCompanyArgs = {
  _version: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  input: TUpdateCompanyMutationInput;
};

export type TMutationUpdateCompanyConfigurationArgs = {
  _version: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  input: TUpdateCompanyConfigurationMutationInput;
};

export type TMutationUpdateDependantArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TUpdateDependantMutationInput;
};

export type TMutationUpdateEmployeeArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TUpdateEmployeeMutationInput;
};

export type TMutationUpdateEventArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TUpdateEventMutationInput;
};

export type TMutationUpdateGiftArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TUpdateGiftMutationInput;
};

export type TMutationUpdateGiftPackageArgs = {
  _version: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  input: TUpdateGiftPackageMutationInput;
};

export type TMutationUpdateOfficeArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TUpdateOfficeMutationInput;
};

export type TMutationUpdateProductArgs = {
  _version: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  input: TUpdateProductMutationInput;
};

export type TMutationUpdateReferenceEventArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TUpdateReferenceEventMutationInput;
};

export type TMutationUpdateRosterFileArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TUpdateRosterFileInput;
};

export type TMutationUpdateShipmentArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: TUpdateShipmentMutationInput;
};

export type TMutationUpdateUserArgs = {
  _version: Scalars['Int']['input'];
  companyId: Scalars['ID']['input'];
  email: Scalars['AWSEmail']['input'];
  input: TUpdateUserMutationInput;
};

export type TNotifyNewHireMutationInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  employeeName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type TOffice = {
  __typename?: 'Office';
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  _version: Scalars['Int']['output'];
  active: Scalars['Boolean']['output'];
  address?: Maybe<TAddress>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID']['output'];
  contact?: Maybe<TContact>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  employees: TEmployeesConnection;
  id: Scalars['ID']['output'];
  legalName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  normalizedName?: Maybe<Scalars['String']['output']>;
  oldSystemId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type TOfficeEmployeesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TOfficesConnection = {
  __typename?: 'OfficesConnection';
  items: Array<Maybe<TOffice>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export enum TOrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type TPackageInfo = {
  __typename?: 'PackageInfo';
  height: Scalars['Float']['output'];
  length: Scalars['Float']['output'];
  weight: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
};

export type TPackageInfoInput = {
  height: Scalars['Float']['input'];
  length: Scalars['Float']['input'];
  weight: Scalars['Float']['input'];
  width: Scalars['Float']['input'];
};

export type TPausePeriod = {
  __typename?: 'PausePeriod';
  fromDate: Scalars['AWSDate']['output'];
  toDate?: Maybe<Scalars['AWSDate']['output']>;
  unpausedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type TPrintShipmentLabelMutationInput = {
  actualShippingDate: Scalars['AWSDate']['input'];
  customsData?: InputMaybe<Array<InputMaybe<TCustomsDataInput>>>;
};

export type TProduct = {
  __typename?: 'Product';
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _version: Scalars['Int']['output'];
  country: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  description: Scalars['String']['output'];
  hsCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  value: Scalars['Float']['output'];
};

export type TProductsConnection = {
  __typename?: 'ProductsConnection';
  items: Array<Maybe<TProduct>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type TQuery = {
  __typename?: 'Query';
  getAddress?: Maybe<TAddress>;
  getAddressById?: Maybe<TAddress>;
  getCompany?: Maybe<TCompany>;
  getCompanyByName?: Maybe<TCompany>;
  getCompanyByOldSystemId?: Maybe<TCompany>;
  getCompanyByRosterId?: Maybe<TCompany>;
  getCompanyBySubdomain?: Maybe<TCompany>;
  getCompanyByUserPoolId?: Maybe<TCompany>;
  getDependant?: Maybe<TDependant>;
  getDependantByOldSystemId?: Maybe<TDependant>;
  getEmployee?: Maybe<TEmployee>;
  getEmployeeByExternalId?: Maybe<TEmployee>;
  getEmployeeByOldSystemId?: Maybe<TEmployee>;
  getEvent?: Maybe<TEvent>;
  getGift?: Maybe<TGift>;
  getGiftByOldSystemId?: Maybe<TGift>;
  getGiftPackage?: Maybe<TGiftPackage>;
  getMyCompany?: Maybe<TCompany>;
  getOffice?: Maybe<TOffice>;
  getOfficeByName?: Maybe<TOffice>;
  getOfficeByOldSystemId?: Maybe<TOffice>;
  getProduct?: Maybe<TProduct>;
  getRecipient?: Maybe<TRecipient>;
  getReferenceEvent?: Maybe<TReferenceEvent>;
  getRosterFile?: Maybe<TRosterFile>;
  getShipment?: Maybe<TShipment>;
  getShipmentByOldSystemId?: Maybe<TShipment>;
  getShipmentByTrackingNumber?: Maybe<TShipment>;
  getUser?: Maybe<TUser>;
  listAddresses: TAddressesConnection;
  listAdminCognitoUsers?: Maybe<Scalars['AWSJSON']['output']>;
  listAllEvents: TEventsConnection;
  listAllLogs: TLogsConnection;
  listAllOffices: TOfficesConnection;
  listAllReferenceEvents: TReferenceEventsConnection;
  listClosedShipments: TShipmentsConnection;
  listCompanies: TCompaniesConnection;
  listCompanyDependants: TDependantsConnection;
  listCompanyEmployees: TEmployeesConnection;
  listCompanyReferenceEvents: TReferenceEventsConnection;
  listCompanyShipments: TShipmentsConnection;
  listCompanyUpcomingEvents: TEventsConnection;
  listDependants: TDependantsConnection;
  listEmployeeAndDependantsNotSkippedWithoutGiftEvents: TEventsConnection;
  listEmployeeAndDependatsGifts: TGiftsConnection;
  listEmployeeDependants: TDependantsConnection;
  listEmployeeReferenceEvents: TReferenceEventsConnection;
  listEmployees: TEmployeesConnection;
  listGiftPackages: TGiftPackageConnection;
  listGiftsByGroup: TGiftsConnection;
  listNotDoneGifts: TGiftsConnection;
  listNotSkippedEventsWithoutGift: TEventsConnection;
  listOffices: TOfficesConnection;
  listOpenShipments: TShipmentsConnection;
  listProducts: TProductsConnection;
  listRecentSetForPickupShipments?: Maybe<TShipmentsConnection>;
  listRecentShippedShipments?: Maybe<TShipmentsConnection>;
  listRecentlyDoneGifts: TGiftsConnection;
  listRecipientGifts: TGiftsConnection;
  listRecipientReferenceEvents: TReferenceEventsConnection;
  listReferenceEventEvents: TEventsConnection;
  listReferenceEventEventsWithGift: TEventsConnection;
  listReferenceEventEventsWithoutGift: TEventsConnection;
  listReferenceEventOnProgressEvents: TEventsConnection;
  listReferenceEventUpcomingEvents: TEventsConnection;
  listReferenceEventsByDate: TReferenceEventsConnection;
  listRosterFiles: TRosterFilesConnection;
  listRosterFilesByCompanyId: TRosterFilesConnection;
  listSetForPickupShipments?: Maybe<TShipmentsConnection>;
  listShipmentGifts: TGiftsConnection;
  listShipmentsByAddress: TShipmentsConnection;
  listShipmentsByAddressAndStatus: TShipmentsConnection;
  listShippedShipments?: Maybe<TShipmentsConnection>;
  listSkippedEvents: TEventsConnection;
  listTodayReferenceEvents: TReferenceEventsConnection;
  listUsers: TUsersConnection;
  listUsersIncludingCSUsers: TUsersConnection;
};

export type TQueryGetAddressArgs = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TQueryGetAddressByIdArgs = {
  id: Scalars['ID']['input'];
};

export type TQueryGetCompanyArgs = {
  id: Scalars['ID']['input'];
};

export type TQueryGetCompanyByNameArgs = {
  name: Scalars['String']['input'];
};

export type TQueryGetCompanyByOldSystemIdArgs = {
  oldSystemId: Scalars['Int']['input'];
};

export type TQueryGetCompanyByRosterIdArgs = {
  rosterId: Scalars['String']['input'];
};

export type TQueryGetCompanyBySubdomainArgs = {
  subdomain: Scalars['String']['input'];
};

export type TQueryGetCompanyByUserPoolIdArgs = {
  userPoolId: Scalars['String']['input'];
};

export type TQueryGetDependantArgs = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TQueryGetDependantByOldSystemIdArgs = {
  oldSystemId: Scalars['Int']['input'];
};

export type TQueryGetEmployeeArgs = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TQueryGetEmployeeByExternalIdArgs = {
  externalId: Scalars['String']['input'];
};

export type TQueryGetEmployeeByOldSystemIdArgs = {
  oldSystemId: Scalars['Int']['input'];
};

export type TQueryGetEventArgs = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TQueryGetGiftArgs = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TQueryGetGiftByOldSystemIdArgs = {
  oldSystemId: Scalars['Int']['input'];
};

export type TQueryGetGiftPackageArgs = {
  id: Scalars['ID']['input'];
};

export type TQueryGetOfficeArgs = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TQueryGetOfficeByNameArgs = {
  companyId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type TQueryGetOfficeByOldSystemIdArgs = {
  oldSystemId: Scalars['Int']['input'];
};

export type TQueryGetProductArgs = {
  id: Scalars['ID']['input'];
};

export type TQueryGetRecipientArgs = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TQueryGetReferenceEventArgs = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TQueryGetRosterFileArgs = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TQueryGetShipmentArgs = {
  companyId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type TQueryGetShipmentByOldSystemIdArgs = {
  oldSystemId: Scalars['Int']['input'];
};

export type TQueryGetShipmentByTrackingNumberArgs = {
  trackingNumber: Scalars['ID']['input'];
};

export type TQueryGetUserArgs = {
  companyId: Scalars['ID']['input'];
  email: Scalars['AWSEmail']['input'];
};

export type TQueryListAddressesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListAdminCognitoUsersArgs = {
  userPoolId: Scalars['String']['input'];
};

export type TQueryListAllEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListAllLogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
  userType: TLogUserTypes;
};

export type TQueryListAllOfficesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListAllReferenceEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListClosedShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListCompanyDependantsArgs = {
  companyId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListCompanyEmployeesArgs = {
  companyId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListCompanyReferenceEventsArgs = {
  companyId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListCompanyShipmentsArgs = {
  companyId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListCompanyUpcomingEventsArgs = {
  companyId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListDependantsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListEmployeeAndDependantsNotSkippedWithoutGiftEventsArgs = {
  employeeId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListEmployeeAndDependatsGiftsArgs = {
  companyId: Scalars['ID']['input'];
  employeeId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListEmployeeDependantsArgs = {
  companyId: Scalars['ID']['input'];
  employeeId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListEmployeeReferenceEventsArgs = {
  companyId: Scalars['ID']['input'];
  employeeId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListEmployeesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListGiftPackagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListGiftsByGroupArgs = {
  companyId: Scalars['String']['input'];
  group: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  ordedr?: InputMaybe<TOrderDirection>;
};

export type TQueryListNotDoneGiftsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListNotSkippedEventsWithoutGiftArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListOfficesArgs = {
  companyId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListOpenShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListProductsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListRecentSetForPickupShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListRecentShippedShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListRecentlyDoneGiftsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListRecipientGiftsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
  recipientId: Scalars['ID']['input'];
};

export type TQueryListRecipientReferenceEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
  recipientId: Scalars['ID']['input'];
};

export type TQueryListReferenceEventEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
  referenceEventId: Scalars['ID']['input'];
};

export type TQueryListReferenceEventEventsWithGiftArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
  referenceEventId: Scalars['ID']['input'];
};

export type TQueryListReferenceEventEventsWithoutGiftArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
  referenceEventId: Scalars['ID']['input'];
};

export type TQueryListReferenceEventOnProgressEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
  referenceEventId: Scalars['ID']['input'];
};

export type TQueryListReferenceEventUpcomingEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
  referenceEventId: Scalars['ID']['input'];
};

export type TQueryListReferenceEventsByDateArgs = {
  date: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListRosterFilesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListRosterFilesByCompanyIdArgs = {
  companyId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListSetForPickupShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListShipmentGiftsArgs = {
  companyId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
  shipmentId: Scalars['ID']['input'];
};

export type TQueryListShipmentsByAddressArgs = {
  addressId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListShipmentsByAddressAndStatusArgs = {
  addressId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
  status: TShipmentStatuses;
};

export type TQueryListShippedShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListSkippedEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListTodayReferenceEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListUsersArgs = {
  companyId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TQueryListUsersIncludingCsUsersArgs = {
  companyId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<TOrderDirection>;
};

export type TRecipient = {
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  _version: Scalars['Int']['output'];
  address?: Maybe<TAddress>;
  birthDate?: Maybe<Scalars['AWSDate']['output']>;
  companyId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  donateBirthdayGift?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  foodPreferences?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  preferredFirstName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type TReferenceEvent = {
  __typename?: 'ReferenceEvent';
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  _version: Scalars['Int']['output'];
  address?: Maybe<TAddress>;
  addressId?: Maybe<Scalars['ID']['output']>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  date: Scalars['String']['output'];
  employee?: Maybe<TEmployee>;
  employeeId: Scalars['ID']['output'];
  fullDate: Scalars['AWSDate']['output'];
  id: Scalars['ID']['output'];
  recipient?: Maybe<TRecipient>;
  recipientId: Scalars['ID']['output'];
  type: TReferenceEventTypes;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export enum TReferenceEventTypes {
  Birthday = 'birthday',
  WorkAnniversary = 'workAnniversary',
}

export type TReferenceEventsConnection = {
  __typename?: 'ReferenceEventsConnection';
  items: Array<Maybe<TReferenceEvent>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type TRemovedGiftFromGroupResponse = {
  __typename?: 'RemovedGiftFromGroupResponse';
  companyId: Scalars['ID']['output'];
  items: Array<Maybe<TGift>>;
};

export type TRosterFile = {
  __typename?: 'RosterFile';
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  _version: Scalars['Int']['output'];
  changelog?: Maybe<Scalars['AWSJSON']['output']>;
  changelogFile?: Maybe<TRosterFileS3Object>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<TRosterFileCreatedBy>;
  file: TRosterFileS3Object;
  headerMapping?: Maybe<Scalars['AWSJSON']['output']>;
  id: Scalars['ID']['output'];
  impoortEndedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  importErrors?: Maybe<Scalars['AWSJSON']['output']>;
  importStartedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  mapping: Scalars['AWSJSON']['output'];
  officeMapping?: Maybe<Scalars['AWSJSON']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type TRosterFileCreatedBy = {
  __typename?: 'RosterFileCreatedBy';
  sub?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type TRosterFileS3Object = {
  __typename?: 'RosterFileS3Object';
  bucket: Scalars['String']['output'];
  key: Scalars['String']['output'];
  region: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export type TRosterFilesConnection = {
  __typename?: 'RosterFilesConnection';
  items: Array<Maybe<TRosterFile>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type TS3Object = {
  __typename?: 'S3Object';
  bucket: Scalars['String']['output'];
  key: Scalars['String']['output'];
  region: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export type TS3ObjectInput = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  region: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type TSetShipmentAsReadyToShipMutationInput = {
  packageInfo: TPackageInfoInput;
};

export enum TShipStationAddressVerified {
  Failed = 'failed',
  Pending = 'pending',
  Success = 'success',
  Warning = 'warning',
}

export type TShipment = {
  __typename?: 'Shipment';
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  _version: Scalars['Int']['output'];
  actualShippingDate?: Maybe<Scalars['AWSDate']['output']>;
  address?: Maybe<TAddress>;
  addressId: Scalars['ID']['output'];
  addressVerified?: Maybe<TShipStationAddressVerified>;
  alerts?: Maybe<Array<Maybe<TAlert>>>;
  company?: Maybe<TCompany>;
  companyId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  customsData?: Maybe<Array<Maybe<TCustomsData>>>;
  deliveredAt?: Maybe<Scalars['AWSDateTime']['output']>;
  gifts?: Maybe<TGiftsConnection>;
  history?: Maybe<Array<Maybe<TShipmentHistory>>>;
  id: Scalars['ID']['output'];
  label?: Maybe<TShipmentLabel>;
  lastShipStationResyncStartedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  oldSystemId?: Maybe<Scalars['Int']['output']>;
  packageInfo?: Maybe<TPackageInfo>;
  pickedUpAt?: Maybe<Scalars['AWSDateTime']['output']>;
  rack?: Maybe<Scalars['String']['output']>;
  resyncWithShipStation?: Maybe<Scalars['Boolean']['output']>;
  returnedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  setForPickupAt?: Maybe<Scalars['AWSDateTime']['output']>;
  shipStationError?: Maybe<Scalars['AWSJSON']['output']>;
  shipStationOrderId?: Maybe<Scalars['Int']['output']>;
  shipStationResyncCount?: Maybe<Scalars['Int']['output']>;
  shipStationShipmentId?: Maybe<Scalars['Int']['output']>;
  shippedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  shippingDate?: Maybe<Scalars['AWSDate']['output']>;
  status: TShipmentStatuses;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingURL?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  useGoogleAddress?: Maybe<Scalars['Boolean']['output']>;
};

export type TShipmentHistory = {
  __typename?: 'ShipmentHistory';
  actualShippingDate?: Maybe<Scalars['AWSDate']['output']>;
  addressVerified?: Maybe<TShipStationAddressVerified>;
  alerts?: Maybe<Array<Maybe<TAlert>>>;
  customsData?: Maybe<Array<Maybe<TCustomsData>>>;
  deliveredAt?: Maybe<Scalars['AWSDateTime']['output']>;
  label?: Maybe<TShipmentLabel>;
  notes?: Maybe<Scalars['String']['output']>;
  packageInfo?: Maybe<TPackageInfo>;
  pickedUpAt?: Maybe<Scalars['AWSDateTime']['output']>;
  rack?: Maybe<Scalars['String']['output']>;
  returnedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  setForPickupAt?: Maybe<Scalars['AWSDateTime']['output']>;
  shipStationOrderId?: Maybe<Scalars['Int']['output']>;
  shipStationShipmentId?: Maybe<Scalars['Int']['output']>;
  shippedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingURL?: Maybe<Scalars['String']['output']>;
};

export type TShipmentLabel = {
  __typename?: 'ShipmentLabel';
  addressData?: Maybe<TAddress>;
  carrier: Scalars['String']['output'];
  cost: Scalars['Float']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  isThirdParty?: Maybe<Scalars['Boolean']['output']>;
  labelFile?: Maybe<TS3Object>;
};

export type TShipmentLabelInput = {
  addressData?: InputMaybe<Scalars['AWSJSON']['input']>;
  carrier: Scalars['String']['input'];
  cost?: InputMaybe<Scalars['Float']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  isThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  labelFile?: InputMaybe<TS3ObjectInput>;
};

export type TShipmentLabelPrintedMutationInput = {
  label: TShipmentLabelInput;
  trackingNumber: Scalars['String']['input'];
};

export type TShipmentRecreatedResponse = {
  __typename?: 'ShipmentRecreatedResponse';
  companyId: Scalars['ID']['output'];
  items: Array<Maybe<TGift>>;
};

export enum TShipmentStatuses {
  Attention = 'attention',
  Canceled = 'canceled',
  Delivered = 'delivered',
  Error = 'error',
  LabelPrinted = 'labelPrinted',
  Packaging = 'packaging',
  PickedUp = 'pickedUp',
  PrintingLabel = 'printingLabel',
  ReadyForPickup = 'readyForPickup',
  ReadyToShip = 'readyToShip',
  Returned = 'returned',
  Shipped = 'shipped',
  Unavailable = 'unavailable',
}

export type TShipmentsConnection = {
  __typename?: 'ShipmentsConnection';
  items: Array<Maybe<TShipment>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export type TSubscription = {
  __typename?: 'Subscription';
  onAnyAddressChanged?: Maybe<TAddress>;
  onAnyCompanyChanged?: Maybe<TCompany>;
  onAnyDependantChanged?: Maybe<TDependant>;
  onAnyEmployeeChanged?: Maybe<TEmployee>;
  onAnyEventChanged?: Maybe<TEvent>;
  onAnyEventGiftCreated?: Maybe<TCreateEventGiftResponse>;
  onAnyGiftChanged?: Maybe<TGift>;
  onAnyGiftPackageChanged?: Maybe<TGiftPackage>;
  onAnyGiftRemovedFromGroup?: Maybe<TRemovedGiftFromGroupResponse>;
  onAnyOfficeChanged?: Maybe<TOffice>;
  onAnyOwnershipTransfered?: Maybe<Array<Maybe<TUser>>>;
  onAnyProductChanged?: Maybe<TProduct>;
  onAnyRosterFileChanged?: Maybe<TRosterFile>;
  onAnyShipmentChanged?: Maybe<TShipment>;
  onAnyShipmentRecreated?: Maybe<TShipmentRecreatedResponse>;
  onAnyUserChanged?: Maybe<TUser>;
  onCompanyAddressChanged?: Maybe<TAddress>;
  onCompanyChanged?: Maybe<TCompany>;
  onCompanyDependantChanged?: Maybe<TDependant>;
  onCompanyEmployeeChanged?: Maybe<TEmployee>;
  onCompanyEventChanged?: Maybe<TEvent>;
  onCompanyEventGiftCreated?: Maybe<TCreateEventGiftResponse>;
  onCompanyGiftChanged?: Maybe<TGift>;
  onCompanyGiftRemovedFromGroup?: Maybe<TRemovedGiftFromGroupResponse>;
  onCompanyOfficeChanged?: Maybe<TOffice>;
  onCompanyOwnershipTransfered?: Maybe<Array<Maybe<TUser>>>;
  onCompanyRosterFileChanged?: Maybe<TRosterFile>;
  onCompanyShipmentChanged?: Maybe<TShipment>;
  onCompanyShipmentRecreated?: Maybe<TShipmentRecreatedResponse>;
  onCompanyUserChanged?: Maybe<TUser>;
  onEmployeeDependantChanged?: Maybe<TDependant>;
  onEmployeeGiftChanged?: Maybe<TGift>;
};

export type TSubscriptionOnCompanyAddressChangedArgs = {
  companyId: Scalars['ID']['input'];
};

export type TSubscriptionOnCompanyChangedArgs = {
  id: Scalars['ID']['input'];
};

export type TSubscriptionOnCompanyDependantChangedArgs = {
  companyId: Scalars['ID']['input'];
};

export type TSubscriptionOnCompanyEmployeeChangedArgs = {
  companyId: Scalars['ID']['input'];
};

export type TSubscriptionOnCompanyEventChangedArgs = {
  companyId: Scalars['ID']['input'];
};

export type TSubscriptionOnCompanyEventGiftCreatedArgs = {
  companyId: Scalars['ID']['input'];
};

export type TSubscriptionOnCompanyGiftChangedArgs = {
  companyId: Scalars['ID']['input'];
};

export type TSubscriptionOnCompanyGiftRemovedFromGroupArgs = {
  companyId: Scalars['ID']['input'];
};

export type TSubscriptionOnCompanyOfficeChangedArgs = {
  companyId: Scalars['ID']['input'];
};

export type TSubscriptionOnCompanyOwnershipTransferedArgs = {
  companyId: Scalars['ID']['input'];
};

export type TSubscriptionOnCompanyRosterFileChangedArgs = {
  companyId: Scalars['ID']['input'];
};

export type TSubscriptionOnCompanyShipmentChangedArgs = {
  companyId: Scalars['ID']['input'];
};

export type TSubscriptionOnCompanyShipmentRecreatedArgs = {
  companyId: Scalars['ID']['input'];
};

export type TSubscriptionOnCompanyUserChangedArgs = {
  companyId: Scalars['ID']['input'];
};

export type TSubscriptionOnEmployeeDependantChangedArgs = {
  companyId: Scalars['ID']['input'];
  employeeId: Scalars['ID']['input'];
};

export type TSubscriptionOnEmployeeGiftChangedArgs = {
  companyId: Scalars['ID']['input'];
  employeeId: Scalars['ID']['input'];
};

export type TUpdateAddressMutationInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  useGoogleAddress?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type TUpdateCompanyConfigurationMutationInput = {
  authCloudfrontDomain?: InputMaybe<Scalars['String']['input']>;
  authUserPoolClientId?: InputMaybe<Scalars['String']['input']>;
  authUserPoolClientName?: InputMaybe<Scalars['String']['input']>;
  authUserPoolDomainURL?: InputMaybe<Scalars['String']['input']>;
  authUserPoolId?: InputMaybe<Scalars['String']['input']>;
  authUserPoolName?: InputMaybe<Scalars['String']['input']>;
  frontendCertificateArn?: InputMaybe<Scalars['String']['input']>;
  frontendDistributionArn?: InputMaybe<Scalars['String']['input']>;
  frontendDistributionDomainName?: InputMaybe<Scalars['String']['input']>;
  frontendDistributionId?: InputMaybe<Scalars['String']['input']>;
};

export type TUpdateCompanyMutationInput = {
  accountManager?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<TUpdateAddressMutationInput>;
  bambooHRAPIKey?: InputMaybe<Scalars['String']['input']>;
  bambooHRPetsTableName?: InputMaybe<Scalars['String']['input']>;
  bambooHRSubdomain?: InputMaybe<Scalars['String']['input']>;
  childBirthdayActivated?: InputMaybe<Scalars['Boolean']['input']>;
  companyWideLegoCustomization?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<TUpdateContactMutationInput>;
  customGiftConfiguration?: InputMaybe<TCustomGiftConfigurationInput>;
  customLego?: InputMaybe<Scalars['Boolean']['input']>;
  customShippingConfiguration?: InputMaybe<Array<InputMaybe<TCustomShippingConfigurationInput>>>;
  customWorkAnniversaryNotes?: InputMaybe<Scalars['String']['input']>;
  deliveryMethod?: InputMaybe<TDeliveryMethod>;
  emailDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  employeeBirthdayActivated?: InputMaybe<Scalars['Boolean']['input']>;
  employeeNewHireKitActivated?: InputMaybe<Scalars['Boolean']['input']>;
  employeeWorkAnniversaryActivated?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['AWSDate']['input']>;
  googleLoginEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasCustomOnboardBrick?: InputMaybe<Scalars['Boolean']['input']>;
  importFromBambooHR?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  lastRosterUpload?: InputMaybe<Scalars['AWSDateTime']['input']>;
  legoActivated?: InputMaybe<Scalars['Boolean']['input']>;
  legoColor?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['AWSURL']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oldSystemId?: InputMaybe<Scalars['Int']['input']>;
  passwordLoginEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  petBirthdayActivated?: InputMaybe<Scalars['Boolean']['input']>;
  rosterId?: InputMaybe<Scalars['String']['input']>;
  signAs?: InputMaybe<Scalars['String']['input']>;
  significantOtherBirthdayActivated?: InputMaybe<Scalars['Boolean']['input']>;
  specialNote?: InputMaybe<Scalars['String']['input']>;
  spreadsheetId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['AWSDate']['input']>;
  subdomain?: InputMaybe<Scalars['String']['input']>;
  subscriptionPriceCurrency?: InputMaybe<Scalars['String']['input']>;
  subscriptionPricePerUser?: InputMaybe<Scalars['Float']['input']>;
  swag?: InputMaybe<Scalars['String']['input']>;
  tissueColor?: InputMaybe<Scalars['String']['input']>;
  workAnniversaryGiftVariation?: InputMaybe<TWorkAnniversaryGiftVariations>;
};

export type TUpdateContactMutationInput = {
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type TUpdateDependantMutationInput = {
  birthDate?: InputMaybe<Scalars['AWSDate']['input']>;
  donateBirthdayGift?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  foodPreferences?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  oldSystemId?: InputMaybe<Scalars['Int']['input']>;
  petType?: InputMaybe<Scalars['String']['input']>;
  preferredFirstName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TDependantType>;
};

export type TUpdateEmployeeMutationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<TUpdateAddressMutationInput>;
  birthDate?: InputMaybe<Scalars['AWSDate']['input']>;
  customOnboardBrick?: InputMaybe<Scalars['String']['input']>;
  deliveryMethod?: InputMaybe<TDeliveryMethod>;
  donateBirthdayGift?: InputMaybe<Scalars['Boolean']['input']>;
  donateWorkAnniversaryGift?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  foodPreferences?: InputMaybe<Scalars['String']['input']>;
  giftOnHoldFrom?: InputMaybe<Scalars['AWSDate']['input']>;
  giftOnHoldTo?: InputMaybe<Scalars['AWSDate']['input']>;
  giftPausedFrom?: InputMaybe<Scalars['AWSDate']['input']>;
  giftPausedTo?: InputMaybe<Scalars['AWSDate']['input']>;
  hireDate?: InputMaybe<Scalars['AWSDate']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['ID']['input']>;
  oldSystemId?: InputMaybe<Scalars['Int']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferredFirstName?: InputMaybe<Scalars['String']['input']>;
  sweaterSize?: InputMaybe<Scalars['String']['input']>;
  tShirtSize?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type TUpdateEventMutationInput = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
  age?: InputMaybe<Scalars['Int']['input']>;
  deliveredAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  eventDate?: InputMaybe<Scalars['AWSDate']['input']>;
  giftId?: InputMaybe<Scalars['ID']['input']>;
  groupedToEventId?: InputMaybe<Scalars['ID']['input']>;
  isPaused?: InputMaybe<Scalars['Boolean']['input']>;
  wasPaused?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TUpdateGiftEventDataInput = {
  age?: InputMaybe<Scalars['Int']['input']>;
  eventDate: Scalars['AWSDate']['input'];
  groupedToEventId?: InputMaybe<Scalars['ID']['input']>;
  type: TEventTypes;
};

export type TUpdateGiftMutationInput = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
  alerts?: InputMaybe<Array<InputMaybe<TAlertInput>>>;
  eventData?: InputMaybe<Scalars['AWSJSON']['input']>;
  eventDate?: InputMaybe<Scalars['AWSDate']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  rack?: InputMaybe<Scalars['String']['input']>;
  recipientData?: InputMaybe<Scalars['AWSJSON']['input']>;
  shipmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type TUpdateGiftPackageMutationInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  products?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<TGiftPackageType>;
};

export type TUpdateOfficeMutationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<TUpdateAddressMutationInput>;
  contact?: InputMaybe<TUpdateContactMutationInput>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oldSystemId?: InputMaybe<Scalars['Int']['input']>;
};

export type TUpdateProductMutationInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hsCode?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type TUpdateReferenceEventMutationInput = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
  fullDate?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type TUpdateRosterFileInput = {
  impoortEndedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  importErrors?: InputMaybe<Scalars['AWSJSON']['input']>;
  importStartedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type TUpdateShipmentMutationInput = {
  actualShippingDate?: InputMaybe<Scalars['AWSDate']['input']>;
  addressId?: InputMaybe<Scalars['ID']['input']>;
  addressVerified?: InputMaybe<TShipStationAddressVerified>;
  alerts?: InputMaybe<Array<InputMaybe<TAlertInput>>>;
  label?: InputMaybe<TShipmentLabelInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  rack?: InputMaybe<Scalars['String']['input']>;
  shipStationError?: InputMaybe<Scalars['AWSJSON']['input']>;
  shipStationOrderId?: InputMaybe<Scalars['Int']['input']>;
  shipStationShipmentId?: InputMaybe<Scalars['Int']['input']>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
  useGoogleAddress?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TUpdateUserMutationInput = {
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  googleUserName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TUserType>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TUser = {
  __typename?: 'User';
  _deleted?: Maybe<Scalars['Boolean']['output']>;
  _lastChangedAt?: Maybe<Scalars['AWSTimestamp']['output']>;
  _version: Scalars['Int']['output'];
  company?: Maybe<TCompany>;
  companyId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  email: Scalars['AWSEmail']['output'];
  googleUserName?: Maybe<Scalars['String']['output']>;
  isCompanyOwner: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: TUserType;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum TUserType {
  Admin = 'admin',
  TeamLeader = 'teamLeader',
}

export type TUsersConnection = {
  __typename?: 'UsersConnection';
  items: Array<Maybe<TUser>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  scannedCount?: Maybe<Scalars['Int']['output']>;
};

export enum TWorkAnniversaryGiftVariations {
  BrickOnly = 'brickOnly',
  Custom = 'custom',
  Deluxe = 'deluxe',
  Mini = 'mini',
}

export type TAddressFieldsFragment = {
  __typename?: 'Address';
  id: string;
  companyId: string;
  _version: number;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zipCode?: string | null;
  useGoogleAddress?: boolean | null;
  googleValidated?: boolean | null;
  missingInfo?: boolean | null;
  alert?: string | null;
  addressFromGoogle?: {
    __typename?: 'AddressFromGoogle';
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zipCode?: string | null;
  } | null;
};

export type TSkipAddressAlertMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
}>;

export type TSkipAddressAlertMutation = {
  __typename?: 'Mutation';
  skipAddressAlert?: {
    __typename?: 'Address';
    id: string;
    companyId: string;
    _version: number;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zipCode?: string | null;
    useGoogleAddress?: boolean | null;
    googleValidated?: boolean | null;
    missingInfo?: boolean | null;
    alert?: string | null;
    addressFromGoogle?: {
      __typename?: 'AddressFromGoogle';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
    } | null;
  } | null;
};

export type TPublicCompanyFieldsFragment = {
  __typename?: 'Company';
  googleLoginEnabled?: boolean | null;
  passwordLoginEnabled?: boolean | null;
  id: string;
  name: string;
  subdomain: string;
  configuration?: {
    __typename?: 'ConfigurationType';
    authUserPoolId?: string | null;
    authUserPoolClientId?: string | null;
    authUserPoolDomainURL?: string | null;
  } | null;
};

export type TSubscriptionPrivateCompanyFieldsFragment = {
  __typename?: 'Company';
  id: string;
  active?: boolean | null;
  deliveryMethod: TDeliveryMethod;
  name: string;
  accountManager?: string | null;
  subdomain: string;
  emailDomains: Array<string>;
  googleLoginEnabled?: boolean | null;
  swag?: string | null;
  legoActivated?: boolean | null;
  companyWideLegoCustomization?: string | null;
  customLego?: boolean | null;
  legoColor?: string | null;
  tissueColor: string;
  signAs?: string | null;
  specialNote?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  employeeBirthdayActivated: boolean;
  employeeWorkAnniversaryActivated: boolean;
  employeeNewHireKitActivated: boolean;
  significantOtherBirthdayActivated: boolean;
  childBirthdayActivated: boolean;
  petBirthdayActivated: boolean;
  hasCustomOnboardBrick?: boolean | null;
  workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt?: any | null;
  configuration?: {
    __typename?: 'ConfigurationType';
    authUserPoolId?: string | null;
    authUserPoolClientId?: string | null;
    authUserPoolDomainURL?: string | null;
  } | null;
  contact?: { __typename?: 'Contact'; name?: string | null; email?: any | null; phoneNumber?: string | null } | null;
};

export type TPrivateCompanyFieldsFragment = {
  __typename?: 'Company';
  id: string;
  active?: boolean | null;
  deliveryMethod: TDeliveryMethod;
  name: string;
  accountManager?: string | null;
  subdomain: string;
  emailDomains: Array<string>;
  googleLoginEnabled?: boolean | null;
  swag?: string | null;
  legoActivated?: boolean | null;
  companyWideLegoCustomization?: string | null;
  customLego?: boolean | null;
  legoColor?: string | null;
  tissueColor: string;
  signAs?: string | null;
  specialNote?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  employeeBirthdayActivated: boolean;
  employeeWorkAnniversaryActivated: boolean;
  employeeNewHireKitActivated: boolean;
  significantOtherBirthdayActivated: boolean;
  childBirthdayActivated: boolean;
  petBirthdayActivated: boolean;
  hasCustomOnboardBrick?: boolean | null;
  workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt?: any | null;
  address?: {
    __typename?: 'Address';
    id: string;
    companyId: string;
    _version: number;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zipCode?: string | null;
    useGoogleAddress?: boolean | null;
    googleValidated?: boolean | null;
    missingInfo?: boolean | null;
    alert?: string | null;
    addressFromGoogle?: {
      __typename?: 'AddressFromGoogle';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
    } | null;
  } | null;
  configuration?: {
    __typename?: 'ConfigurationType';
    authUserPoolId?: string | null;
    authUserPoolClientId?: string | null;
    authUserPoolDomainURL?: string | null;
  } | null;
  contact?: { __typename?: 'Contact'; name?: string | null; email?: any | null; phoneNumber?: string | null } | null;
};

export type TGetMyCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type TGetMyCompanyQuery = {
  __typename?: 'Query';
  getMyCompany?: {
    __typename?: 'Company';
    id: string;
    active?: boolean | null;
    deliveryMethod: TDeliveryMethod;
    name: string;
    accountManager?: string | null;
    subdomain: string;
    emailDomains: Array<string>;
    googleLoginEnabled?: boolean | null;
    swag?: string | null;
    legoActivated?: boolean | null;
    companyWideLegoCustomization?: string | null;
    customLego?: boolean | null;
    legoColor?: string | null;
    tissueColor: string;
    signAs?: string | null;
    specialNote?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    employeeBirthdayActivated: boolean;
    employeeWorkAnniversaryActivated: boolean;
    employeeNewHireKitActivated: boolean;
    significantOtherBirthdayActivated: boolean;
    childBirthdayActivated: boolean;
    petBirthdayActivated: boolean;
    hasCustomOnboardBrick?: boolean | null;
    workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
    address?: {
      __typename?: 'Address';
      id: string;
      companyId: string;
      _version: number;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      useGoogleAddress?: boolean | null;
      googleValidated?: boolean | null;
      missingInfo?: boolean | null;
      alert?: string | null;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
    configuration?: {
      __typename?: 'ConfigurationType';
      authUserPoolId?: string | null;
      authUserPoolClientId?: string | null;
      authUserPoolDomainURL?: string | null;
    } | null;
    contact?: { __typename?: 'Contact'; name?: string | null; email?: any | null; phoneNumber?: string | null } | null;
  } | null;
};

export type TGetCompanyBySubdomainQueryVariables = Exact<{
  subdomain: Scalars['String']['input'];
}>;

export type TGetCompanyBySubdomainQuery = {
  __typename?: 'Query';
  getCompanyBySubdomain?: {
    __typename?: 'Company';
    googleLoginEnabled?: boolean | null;
    passwordLoginEnabled?: boolean | null;
    id: string;
    name: string;
    subdomain: string;
    configuration?: {
      __typename?: 'ConfigurationType';
      authUserPoolId?: string | null;
      authUserPoolClientId?: string | null;
      authUserPoolDomainURL?: string | null;
    } | null;
  } | null;
};

export type TOnCompanyChangedSubscriptionVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TOnCompanyChangedSubscription = {
  __typename?: 'Subscription';
  onCompanyChanged?: {
    __typename?: 'Company';
    id: string;
    active?: boolean | null;
    deliveryMethod: TDeliveryMethod;
    name: string;
    accountManager?: string | null;
    subdomain: string;
    emailDomains: Array<string>;
    googleLoginEnabled?: boolean | null;
    swag?: string | null;
    legoActivated?: boolean | null;
    companyWideLegoCustomization?: string | null;
    customLego?: boolean | null;
    legoColor?: string | null;
    tissueColor: string;
    signAs?: string | null;
    specialNote?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    employeeBirthdayActivated: boolean;
    employeeWorkAnniversaryActivated: boolean;
    employeeNewHireKitActivated: boolean;
    significantOtherBirthdayActivated: boolean;
    childBirthdayActivated: boolean;
    petBirthdayActivated: boolean;
    hasCustomOnboardBrick?: boolean | null;
    workAnniversaryGiftVariation?: TWorkAnniversaryGiftVariations | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
    configuration?: {
      __typename?: 'ConfigurationType';
      authUserPoolId?: string | null;
      authUserPoolClientId?: string | null;
      authUserPoolDomainURL?: string | null;
    } | null;
    contact?: { __typename?: 'Contact'; name?: string | null; email?: any | null; phoneNumber?: string | null } | null;
  } | null;
};

export type TContactFieldsFragment = {
  __typename?: 'Contact';
  name?: string | null;
  email?: any | null;
  phoneNumber?: string | null;
};

export type TSubscriptionDependantFieldsFragment = {
  __typename?: 'Dependant';
  id: string;
  companyId: string;
  employeeId: string;
  type: TDependantType;
  petType?: string | null;
  firstName?: string | null;
  preferredFirstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  birthDate?: any | null;
  foodPreferences?: string | null;
  donateBirthdayGift?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt?: any | null;
};

export type TDependantFieldsFragment = {
  __typename?: 'Dependant';
  id: string;
  companyId: string;
  employeeId: string;
  type: TDependantType;
  petType?: string | null;
  firstName?: string | null;
  preferredFirstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  birthDate?: any | null;
  foodPreferences?: string | null;
  donateBirthdayGift?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt?: any | null;
};

export type TDependantWithEmployeeFieldsFragment = {
  __typename?: 'Dependant';
  id: string;
  companyId: string;
  employeeId: string;
  type: TDependantType;
  petType?: string | null;
  firstName?: string | null;
  preferredFirstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  birthDate?: any | null;
  foodPreferences?: string | null;
  donateBirthdayGift?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt?: any | null;
  employee?: {
    __typename?: 'Employee';
    id: string;
    companyId: string;
    fullName?: string | null;
    email?: any | null;
    birthDate?: any | null;
    hireDate?: any | null;
    officeId?: string | null;
    isPaused?: boolean | null;
    isPauseEnabled?: boolean | null;
    _deleted?: boolean | null;
    pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
    address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
  } | null;
};

export type TListCompanyDependantsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListCompanyDependantsQuery = {
  __typename?: 'Query';
  listCompanyDependants: {
    __typename?: 'DependantsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Dependant';
      id: string;
      companyId: string;
      employeeId: string;
      type: TDependantType;
      petType?: string | null;
      firstName?: string | null;
      preferredFirstName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
      birthDate?: any | null;
      foodPreferences?: string | null;
      donateBirthdayGift?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt?: any | null;
    } | null>;
  };
};

export type TListEmployeeDependantsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  employeeId: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListEmployeeDependantsQuery = {
  __typename?: 'Query';
  listEmployeeDependants: {
    __typename?: 'DependantsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Dependant';
      id: string;
      companyId: string;
      employeeId: string;
      type: TDependantType;
      petType?: string | null;
      firstName?: string | null;
      preferredFirstName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
      birthDate?: any | null;
      foodPreferences?: string | null;
      donateBirthdayGift?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt?: any | null;
    } | null>;
  };
};

export type TGetDependantQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}>;

export type TGetDependantQuery = {
  __typename?: 'Query';
  getDependant?: {
    __typename?: 'Dependant';
    id: string;
    companyId: string;
    employeeId: string;
    type: TDependantType;
    petType?: string | null;
    firstName?: string | null;
    preferredFirstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    birthDate?: any | null;
    foodPreferences?: string | null;
    donateBirthdayGift?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
  } | null;
};

export type TCreateDependantMutationVariables = Exact<{
  input: TCreateDependantMutationInput;
}>;

export type TCreateDependantMutation = {
  __typename?: 'Mutation';
  createDependant?: {
    __typename?: 'Dependant';
    id: string;
    companyId: string;
    employeeId: string;
    type: TDependantType;
    petType?: string | null;
    firstName?: string | null;
    preferredFirstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    birthDate?: any | null;
    foodPreferences?: string | null;
    donateBirthdayGift?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
  } | null;
};

export type TUpdateDependantMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
  input: TUpdateDependantMutationInput;
}>;

export type TUpdateDependantMutation = {
  __typename?: 'Mutation';
  updateDependant?: {
    __typename?: 'Dependant';
    id: string;
    companyId: string;
    employeeId: string;
    type: TDependantType;
    petType?: string | null;
    firstName?: string | null;
    preferredFirstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    birthDate?: any | null;
    foodPreferences?: string | null;
    donateBirthdayGift?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
  } | null;
};

export type TDeleteDependantMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
}>;

export type TDeleteDependantMutation = {
  __typename?: 'Mutation';
  deleteDependant?: {
    __typename?: 'Dependant';
    id: string;
    companyId: string;
    employeeId: string;
    type: TDependantType;
    petType?: string | null;
    firstName?: string | null;
    preferredFirstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    birthDate?: any | null;
    foodPreferences?: string | null;
    donateBirthdayGift?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
  } | null;
};

export type TOnCompanyDependantChangedSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type TOnCompanyDependantChangedSubscription = {
  __typename?: 'Subscription';
  onCompanyDependantChanged?: {
    __typename?: 'Dependant';
    id: string;
    companyId: string;
    employeeId: string;
    type: TDependantType;
    petType?: string | null;
    firstName?: string | null;
    preferredFirstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    birthDate?: any | null;
    foodPreferences?: string | null;
    donateBirthdayGift?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
  } | null;
};

export type TOnEmployeeDependantChangedSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input'];
  employeeId: Scalars['ID']['input'];
}>;

export type TOnEmployeeDependantChangedSubscription = {
  __typename?: 'Subscription';
  onEmployeeDependantChanged?: {
    __typename?: 'Dependant';
    id: string;
    companyId: string;
    employeeId: string;
    type: TDependantType;
    petType?: string | null;
    firstName?: string | null;
    preferredFirstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    birthDate?: any | null;
    foodPreferences?: string | null;
    donateBirthdayGift?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
  } | null;
};

export type TListEmployeeFieldsFragment = {
  __typename?: 'Employee';
  id: string;
  companyId: string;
  fullName?: string | null;
  email?: any | null;
  birthDate?: any | null;
  hireDate?: any | null;
  officeId?: string | null;
  isPaused?: boolean | null;
  isPauseEnabled?: boolean | null;
  _deleted?: boolean | null;
  pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
  address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
};

export type TSubscriptionEmployeeFieldsFragment = {
  __typename?: 'Employee';
  active: boolean;
  externalId?: string | null;
  firstName?: string | null;
  preferredFirstName?: string | null;
  lastName?: string | null;
  phoneNumber?: Array<string | null> | null;
  foodPreferences?: string | null;
  deliveryMethod?: TDeliveryMethod | null;
  customOnboardBrick?: string | null;
  tShirtSize?: string | null;
  sweaterSize?: string | null;
  donateBirthdayGift?: boolean | null;
  donateWorkAnniversaryGift?: boolean | null;
  _version: number;
  _lastChangedAt?: any | null;
  id: string;
  companyId: string;
  fullName?: string | null;
  email?: any | null;
  birthDate?: any | null;
  hireDate?: any | null;
  officeId?: string | null;
  isPaused?: boolean | null;
  isPauseEnabled?: boolean | null;
  _deleted?: boolean | null;
  pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
  address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
};

export type TEmployeeFieldsFragment = {
  __typename?: 'Employee';
  active: boolean;
  externalId?: string | null;
  firstName?: string | null;
  preferredFirstName?: string | null;
  lastName?: string | null;
  phoneNumber?: Array<string | null> | null;
  foodPreferences?: string | null;
  deliveryMethod?: TDeliveryMethod | null;
  customOnboardBrick?: string | null;
  tShirtSize?: string | null;
  sweaterSize?: string | null;
  donateBirthdayGift?: boolean | null;
  donateWorkAnniversaryGift?: boolean | null;
  _version: number;
  _lastChangedAt?: any | null;
  id: string;
  companyId: string;
  fullName?: string | null;
  email?: any | null;
  birthDate?: any | null;
  hireDate?: any | null;
  officeId?: string | null;
  isPaused?: boolean | null;
  isPauseEnabled?: boolean | null;
  _deleted?: boolean | null;
  address?: {
    __typename?: 'Address';
    id: string;
    missingInfo?: boolean | null;
    alert?: string | null;
    companyId: string;
    _version: number;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zipCode?: string | null;
    useGoogleAddress?: boolean | null;
    googleValidated?: boolean | null;
    addressFromGoogle?: {
      __typename?: 'AddressFromGoogle';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
    } | null;
  } | null;
  office?: { __typename?: 'Office'; id: string; name: string } | null;
  pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
};

export type TListCompanyEmployeesQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListCompanyEmployeesQuery = {
  __typename?: 'Query';
  listCompanyEmployees: {
    __typename?: 'EmployeesConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Employee';
      id: string;
      companyId: string;
      fullName?: string | null;
      email?: any | null;
      birthDate?: any | null;
      hireDate?: any | null;
      officeId?: string | null;
      isPaused?: boolean | null;
      isPauseEnabled?: boolean | null;
      _deleted?: boolean | null;
      pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
      address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
    } | null>;
  };
};

export type TGetEmployeeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}>;

export type TGetEmployeeQuery = {
  __typename?: 'Query';
  getEmployee?: {
    __typename?: 'Employee';
    active: boolean;
    externalId?: string | null;
    firstName?: string | null;
    preferredFirstName?: string | null;
    lastName?: string | null;
    phoneNumber?: Array<string | null> | null;
    foodPreferences?: string | null;
    deliveryMethod?: TDeliveryMethod | null;
    customOnboardBrick?: string | null;
    tShirtSize?: string | null;
    sweaterSize?: string | null;
    donateBirthdayGift?: boolean | null;
    donateWorkAnniversaryGift?: boolean | null;
    _version: number;
    _lastChangedAt?: any | null;
    id: string;
    companyId: string;
    fullName?: string | null;
    email?: any | null;
    birthDate?: any | null;
    hireDate?: any | null;
    officeId?: string | null;
    isPaused?: boolean | null;
    isPauseEnabled?: boolean | null;
    _deleted?: boolean | null;
    address?: {
      __typename?: 'Address';
      id: string;
      missingInfo?: boolean | null;
      alert?: string | null;
      companyId: string;
      _version: number;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      useGoogleAddress?: boolean | null;
      googleValidated?: boolean | null;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
    office?: { __typename?: 'Office'; id: string; name: string } | null;
    pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
  } | null;
};

export type TCreateEmployeeMutationVariables = Exact<{
  input: TCreateEmployeeMutationInput;
}>;

export type TCreateEmployeeMutation = {
  __typename?: 'Mutation';
  createEmployee?: {
    __typename?: 'Employee';
    active: boolean;
    externalId?: string | null;
    firstName?: string | null;
    preferredFirstName?: string | null;
    lastName?: string | null;
    phoneNumber?: Array<string | null> | null;
    foodPreferences?: string | null;
    deliveryMethod?: TDeliveryMethod | null;
    customOnboardBrick?: string | null;
    tShirtSize?: string | null;
    sweaterSize?: string | null;
    donateBirthdayGift?: boolean | null;
    donateWorkAnniversaryGift?: boolean | null;
    _version: number;
    _lastChangedAt?: any | null;
    id: string;
    companyId: string;
    fullName?: string | null;
    email?: any | null;
    birthDate?: any | null;
    hireDate?: any | null;
    officeId?: string | null;
    isPaused?: boolean | null;
    isPauseEnabled?: boolean | null;
    _deleted?: boolean | null;
    address?: {
      __typename?: 'Address';
      id: string;
      missingInfo?: boolean | null;
      alert?: string | null;
      companyId: string;
      _version: number;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      useGoogleAddress?: boolean | null;
      googleValidated?: boolean | null;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
    office?: { __typename?: 'Office'; id: string; name: string } | null;
    pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
  } | null;
};

export type TUpdateEmployeeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
  input: TUpdateEmployeeMutationInput;
}>;

export type TUpdateEmployeeMutation = {
  __typename?: 'Mutation';
  updateEmployee?: {
    __typename?: 'Employee';
    active: boolean;
    externalId?: string | null;
    firstName?: string | null;
    preferredFirstName?: string | null;
    lastName?: string | null;
    phoneNumber?: Array<string | null> | null;
    foodPreferences?: string | null;
    deliveryMethod?: TDeliveryMethod | null;
    customOnboardBrick?: string | null;
    tShirtSize?: string | null;
    sweaterSize?: string | null;
    donateBirthdayGift?: boolean | null;
    donateWorkAnniversaryGift?: boolean | null;
    _version: number;
    _lastChangedAt?: any | null;
    id: string;
    companyId: string;
    fullName?: string | null;
    email?: any | null;
    birthDate?: any | null;
    hireDate?: any | null;
    officeId?: string | null;
    isPaused?: boolean | null;
    isPauseEnabled?: boolean | null;
    _deleted?: boolean | null;
    address?: {
      __typename?: 'Address';
      id: string;
      missingInfo?: boolean | null;
      alert?: string | null;
      companyId: string;
      _version: number;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      useGoogleAddress?: boolean | null;
      googleValidated?: boolean | null;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
    office?: { __typename?: 'Office'; id: string; name: string } | null;
    pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
  } | null;
};

export type TDeleteEmployeeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
}>;

export type TDeleteEmployeeMutation = {
  __typename?: 'Mutation';
  deleteEmployee?: {
    __typename?: 'Employee';
    active: boolean;
    externalId?: string | null;
    firstName?: string | null;
    preferredFirstName?: string | null;
    lastName?: string | null;
    phoneNumber?: Array<string | null> | null;
    foodPreferences?: string | null;
    deliveryMethod?: TDeliveryMethod | null;
    customOnboardBrick?: string | null;
    tShirtSize?: string | null;
    sweaterSize?: string | null;
    donateBirthdayGift?: boolean | null;
    donateWorkAnniversaryGift?: boolean | null;
    _version: number;
    _lastChangedAt?: any | null;
    id: string;
    companyId: string;
    fullName?: string | null;
    email?: any | null;
    birthDate?: any | null;
    hireDate?: any | null;
    officeId?: string | null;
    isPaused?: boolean | null;
    isPauseEnabled?: boolean | null;
    _deleted?: boolean | null;
    pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
    address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
  } | null;
};

export type TPauseEmployeeGiftsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
  fromDate?: InputMaybe<Scalars['AWSDate']['input']>;
  toDate?: InputMaybe<Scalars['AWSDate']['input']>;
}>;

export type TPauseEmployeeGiftsMutation = {
  __typename?: 'Mutation';
  pauseEmployeeGifts?: {
    __typename?: 'Employee';
    active: boolean;
    externalId?: string | null;
    firstName?: string | null;
    preferredFirstName?: string | null;
    lastName?: string | null;
    phoneNumber?: Array<string | null> | null;
    foodPreferences?: string | null;
    deliveryMethod?: TDeliveryMethod | null;
    customOnboardBrick?: string | null;
    tShirtSize?: string | null;
    sweaterSize?: string | null;
    donateBirthdayGift?: boolean | null;
    donateWorkAnniversaryGift?: boolean | null;
    _version: number;
    _lastChangedAt?: any | null;
    id: string;
    companyId: string;
    fullName?: string | null;
    email?: any | null;
    birthDate?: any | null;
    hireDate?: any | null;
    officeId?: string | null;
    isPaused?: boolean | null;
    isPauseEnabled?: boolean | null;
    _deleted?: boolean | null;
    pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
    address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
  } | null;
};

export type TUnpauseEmployeeGiftsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
}>;

export type TUnpauseEmployeeGiftsMutation = {
  __typename?: 'Mutation';
  unpauseEmployeeGifts?: {
    __typename?: 'Employee';
    active: boolean;
    externalId?: string | null;
    firstName?: string | null;
    preferredFirstName?: string | null;
    lastName?: string | null;
    phoneNumber?: Array<string | null> | null;
    foodPreferences?: string | null;
    deliveryMethod?: TDeliveryMethod | null;
    customOnboardBrick?: string | null;
    tShirtSize?: string | null;
    sweaterSize?: string | null;
    donateBirthdayGift?: boolean | null;
    donateWorkAnniversaryGift?: boolean | null;
    _version: number;
    _lastChangedAt?: any | null;
    id: string;
    companyId: string;
    fullName?: string | null;
    email?: any | null;
    birthDate?: any | null;
    hireDate?: any | null;
    officeId?: string | null;
    isPaused?: boolean | null;
    isPauseEnabled?: boolean | null;
    _deleted?: boolean | null;
    pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
    address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
  } | null;
};

export type TOnCompanyEmployeeChangedSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type TOnCompanyEmployeeChangedSubscription = {
  __typename?: 'Subscription';
  onCompanyEmployeeChanged?: {
    __typename?: 'Employee';
    active: boolean;
    externalId?: string | null;
    firstName?: string | null;
    preferredFirstName?: string | null;
    lastName?: string | null;
    phoneNumber?: Array<string | null> | null;
    foodPreferences?: string | null;
    deliveryMethod?: TDeliveryMethod | null;
    customOnboardBrick?: string | null;
    tShirtSize?: string | null;
    sweaterSize?: string | null;
    donateBirthdayGift?: boolean | null;
    donateWorkAnniversaryGift?: boolean | null;
    _version: number;
    _lastChangedAt?: any | null;
    id: string;
    companyId: string;
    fullName?: string | null;
    email?: any | null;
    birthDate?: any | null;
    hireDate?: any | null;
    officeId?: string | null;
    isPaused?: boolean | null;
    isPauseEnabled?: boolean | null;
    _deleted?: boolean | null;
    pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
    address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
  } | null;
};

export type TEventFieldsFragment = {
  __typename?: 'Event';
  id: string;
  companyId: string;
  eventDate: any;
  type: TEventTypes;
  age?: number | null;
  deliveredAt?: any | null;
  isPaused?: boolean | null;
  wasPaused?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  employee?: { __typename?: 'Employee'; id: string; companyId: string; fullName?: string | null } | null;
  recipient?:
    | {
        __typename?: 'Dependant';
        id: string;
        companyId: string;
        employeeId: string;
        type: TDependantType;
        petType?: string | null;
        firstName?: string | null;
        preferredFirstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
        birthDate?: any | null;
        foodPreferences?: string | null;
        donateBirthdayGift?: boolean | null;
        _version: number;
        _deleted?: boolean | null;
        _lastChangedAt?: any | null;
        employee?: {
          __typename?: 'Employee';
          id: string;
          companyId: string;
          fullName?: string | null;
          email?: any | null;
          birthDate?: any | null;
          hireDate?: any | null;
          officeId?: string | null;
          isPaused?: boolean | null;
          isPauseEnabled?: boolean | null;
          _deleted?: boolean | null;
          pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
          address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
        } | null;
      }
    | {
        __typename?: 'Employee';
        id: string;
        companyId: string;
        fullName?: string | null;
        email?: any | null;
        birthDate?: any | null;
        hireDate?: any | null;
        officeId?: string | null;
        isPaused?: boolean | null;
        isPauseEnabled?: boolean | null;
        _deleted?: boolean | null;
        pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
        address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
      }
    | null;
};

export type TListCompanyUpcomingEventsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListCompanyUpcomingEventsQuery = {
  __typename?: 'Query';
  listCompanyUpcomingEvents: {
    __typename?: 'EventsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Event';
      id: string;
      companyId: string;
      eventDate: any;
      type: TEventTypes;
      age?: number | null;
      deliveredAt?: any | null;
      isPaused?: boolean | null;
      wasPaused?: boolean | null;
      _version: number;
      _deleted?: boolean | null;
      employee?: { __typename?: 'Employee'; id: string; companyId: string; fullName?: string | null } | null;
      recipient?:
        | {
            __typename?: 'Dependant';
            id: string;
            companyId: string;
            employeeId: string;
            type: TDependantType;
            petType?: string | null;
            firstName?: string | null;
            preferredFirstName?: string | null;
            lastName?: string | null;
            fullName?: string | null;
            birthDate?: any | null;
            foodPreferences?: string | null;
            donateBirthdayGift?: boolean | null;
            _version: number;
            _deleted?: boolean | null;
            _lastChangedAt?: any | null;
            employee?: {
              __typename?: 'Employee';
              id: string;
              companyId: string;
              fullName?: string | null;
              email?: any | null;
              birthDate?: any | null;
              hireDate?: any | null;
              officeId?: string | null;
              isPaused?: boolean | null;
              isPauseEnabled?: boolean | null;
              _deleted?: boolean | null;
              pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
              address?: {
                __typename?: 'Address';
                id: string;
                missingInfo?: boolean | null;
                alert?: string | null;
              } | null;
            } | null;
          }
        | {
            __typename?: 'Employee';
            id: string;
            companyId: string;
            fullName?: string | null;
            email?: any | null;
            birthDate?: any | null;
            hireDate?: any | null;
            officeId?: string | null;
            isPaused?: boolean | null;
            isPauseEnabled?: boolean | null;
            _deleted?: boolean | null;
            pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
            address?: {
              __typename?: 'Address';
              id: string;
              missingInfo?: boolean | null;
              alert?: string | null;
            } | null;
          }
        | null;
    } | null>;
  };
};

export type TGetEventQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}>;

export type TGetEventQuery = {
  __typename?: 'Query';
  getEvent?: {
    __typename?: 'Event';
    id: string;
    companyId: string;
    eventDate: any;
    type: TEventTypes;
    age?: number | null;
    deliveredAt?: any | null;
    isPaused?: boolean | null;
    wasPaused?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    employee?: { __typename?: 'Employee'; id: string; companyId: string; fullName?: string | null } | null;
    recipient?:
      | {
          __typename?: 'Dependant';
          id: string;
          companyId: string;
          employeeId: string;
          type: TDependantType;
          petType?: string | null;
          firstName?: string | null;
          preferredFirstName?: string | null;
          lastName?: string | null;
          fullName?: string | null;
          birthDate?: any | null;
          foodPreferences?: string | null;
          donateBirthdayGift?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt?: any | null;
          employee?: {
            __typename?: 'Employee';
            id: string;
            companyId: string;
            fullName?: string | null;
            email?: any | null;
            birthDate?: any | null;
            hireDate?: any | null;
            officeId?: string | null;
            isPaused?: boolean | null;
            isPauseEnabled?: boolean | null;
            _deleted?: boolean | null;
            pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
            address?: {
              __typename?: 'Address';
              id: string;
              missingInfo?: boolean | null;
              alert?: string | null;
            } | null;
          } | null;
        }
      | {
          __typename?: 'Employee';
          id: string;
          companyId: string;
          fullName?: string | null;
          email?: any | null;
          birthDate?: any | null;
          hireDate?: any | null;
          officeId?: string | null;
          isPaused?: boolean | null;
          isPauseEnabled?: boolean | null;
          _deleted?: boolean | null;
          pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
          address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
        }
      | null;
  } | null;
};

export type TOnCompanyEventChangedSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type TOnCompanyEventChangedSubscription = {
  __typename?: 'Subscription';
  onCompanyEventChanged?: {
    __typename?: 'Event';
    id: string;
    companyId: string;
    eventDate: any;
    type: TEventTypes;
    age?: number | null;
    deliveredAt?: any | null;
    isPaused?: boolean | null;
    wasPaused?: boolean | null;
    _version: number;
    _deleted?: boolean | null;
    employee?: { __typename?: 'Employee'; id: string; companyId: string; fullName?: string | null } | null;
    recipient?:
      | {
          __typename?: 'Dependant';
          id: string;
          companyId: string;
          employeeId: string;
          type: TDependantType;
          petType?: string | null;
          firstName?: string | null;
          preferredFirstName?: string | null;
          lastName?: string | null;
          fullName?: string | null;
          birthDate?: any | null;
          foodPreferences?: string | null;
          donateBirthdayGift?: boolean | null;
          _version: number;
          _deleted?: boolean | null;
          _lastChangedAt?: any | null;
          employee?: {
            __typename?: 'Employee';
            id: string;
            companyId: string;
            fullName?: string | null;
            email?: any | null;
            birthDate?: any | null;
            hireDate?: any | null;
            officeId?: string | null;
            isPaused?: boolean | null;
            isPauseEnabled?: boolean | null;
            _deleted?: boolean | null;
            pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
            address?: {
              __typename?: 'Address';
              id: string;
              missingInfo?: boolean | null;
              alert?: string | null;
            } | null;
          } | null;
        }
      | {
          __typename?: 'Employee';
          id: string;
          companyId: string;
          fullName?: string | null;
          email?: any | null;
          birthDate?: any | null;
          hireDate?: any | null;
          officeId?: string | null;
          isPaused?: boolean | null;
          isPauseEnabled?: boolean | null;
          _deleted?: boolean | null;
          pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
          address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
        }
      | null;
  } | null;
};

export type TGiftEmployeeFragment = {
  __typename?: 'Employee';
  id: string;
  companyId: string;
  fullName?: string | null;
};

export type TGiftDependantFragment = {
  __typename?: 'Dependant';
  id: string;
  companyId: string;
  fullName?: string | null;
  type: TDependantType;
  petType?: string | null;
};

type TGiftRecipientFields_Dependant_Fragment = {
  __typename?: 'Dependant';
  type: TDependantType;
  petType?: string | null;
  id: string;
  companyId: string;
  fullName?: string | null;
};

type TGiftRecipientFields_Employee_Fragment = {
  __typename?: 'Employee';
  id: string;
  companyId: string;
  fullName?: string | null;
};

export type TGiftRecipientFieldsFragment =
  | TGiftRecipientFields_Dependant_Fragment
  | TGiftRecipientFields_Employee_Fragment;

export type TGiftShipmentFieldsFragment = {
  __typename?: 'Shipment';
  id: string;
  companyId: string;
  status: TShipmentStatuses;
  trackingNumber?: string | null;
  actualShippingDate?: any | null;
  shippingDate?: any | null;
  label?: { __typename?: 'ShipmentLabel'; carrier: string } | null;
};

export type TSubscriptionGiftFieldsFragment = {
  __typename?: 'Gift';
  id: string;
  companyId: string;
  employeeId: string;
  status: TGiftStatuses;
  _version: number;
  _deleted?: boolean | null;
};

export type TBasicGiftFieldsFragment = {
  __typename?: 'Gift';
  id: string;
  companyId: string;
  employeeId: string;
  status: TGiftStatuses;
  _version: number;
  _deleted?: boolean | null;
  recipient?:
    | {
        __typename?: 'Dependant';
        type: TDependantType;
        petType?: string | null;
        id: string;
        companyId: string;
        fullName?: string | null;
      }
    | { __typename?: 'Employee'; id: string; companyId: string; fullName?: string | null }
    | null;
  event?: { __typename?: 'Event'; eventDate: any; type: TEventTypes; customType?: string | null } | null;
};

export type TGiftFieldsFragment = {
  __typename?: 'Gift';
  id: string;
  companyId: string;
  employeeId: string;
  status: TGiftStatuses;
  _version: number;
  _deleted?: boolean | null;
  shipment?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    status: TShipmentStatuses;
    trackingNumber?: string | null;
    actualShippingDate?: any | null;
    shippingDate?: any | null;
    label?: { __typename?: 'ShipmentLabel'; carrier: string } | null;
  } | null;
  recipient?:
    | {
        __typename?: 'Dependant';
        type: TDependantType;
        petType?: string | null;
        id: string;
        companyId: string;
        fullName?: string | null;
      }
    | { __typename?: 'Employee'; id: string; companyId: string; fullName?: string | null }
    | null;
  event?: { __typename?: 'Event'; eventDate: any; type: TEventTypes; customType?: string | null } | null;
};

export type TListEmployeeAndDependatsGiftsQueryVariables = Exact<{
  employeeId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListEmployeeAndDependatsGiftsQuery = {
  __typename?: 'Query';
  listEmployeeAndDependatsGifts: {
    __typename?: 'GiftsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      status: TGiftStatuses;
      _version: number;
      _deleted?: boolean | null;
      shipment?: {
        __typename?: 'Shipment';
        id: string;
        companyId: string;
        status: TShipmentStatuses;
        trackingNumber?: string | null;
        actualShippingDate?: any | null;
        shippingDate?: any | null;
        label?: { __typename?: 'ShipmentLabel'; carrier: string } | null;
      } | null;
      recipient?:
        | {
            __typename?: 'Dependant';
            type: TDependantType;
            petType?: string | null;
            id: string;
            companyId: string;
            fullName?: string | null;
          }
        | { __typename?: 'Employee'; id: string; companyId: string; fullName?: string | null }
        | null;
      event?: { __typename?: 'Event'; eventDate: any; type: TEventTypes; customType?: string | null } | null;
    } | null>;
  };
};

export type TGetGiftQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}>;

export type TGetGiftQuery = {
  __typename?: 'Query';
  getGift?: {
    __typename?: 'Gift';
    id: string;
    companyId: string;
    employeeId: string;
    status: TGiftStatuses;
    _version: number;
    _deleted?: boolean | null;
    shipment?: {
      __typename?: 'Shipment';
      id: string;
      companyId: string;
      status: TShipmentStatuses;
      trackingNumber?: string | null;
      actualShippingDate?: any | null;
      shippingDate?: any | null;
      label?: { __typename?: 'ShipmentLabel'; carrier: string } | null;
    } | null;
    recipient?:
      | {
          __typename?: 'Dependant';
          type: TDependantType;
          petType?: string | null;
          id: string;
          companyId: string;
          fullName?: string | null;
        }
      | { __typename?: 'Employee'; id: string; companyId: string; fullName?: string | null }
      | null;
    event?: { __typename?: 'Event'; eventDate: any; type: TEventTypes; customType?: string | null } | null;
  } | null;
};

export type TOnCompanyEventGiftCreatedSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type TOnCompanyEventGiftCreatedSubscription = {
  __typename?: 'Subscription';
  onCompanyEventGiftCreated?: {
    __typename?: 'CreateEventGiftResponse';
    gift?: {
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      status: TGiftStatuses;
      _version: number;
      _deleted?: boolean | null;
    } | null;
  } | null;
};

export type TOnCompanyGiftRemovedFromGroupSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type TOnCompanyGiftRemovedFromGroupSubscription = {
  __typename?: 'Subscription';
  onCompanyGiftRemovedFromGroup?: {
    __typename?: 'RemovedGiftFromGroupResponse';
    items: Array<{
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      status: TGiftStatuses;
      _version: number;
      _deleted?: boolean | null;
    } | null>;
  } | null;
};

export type TOnCompanyGiftChangedSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type TOnCompanyGiftChangedSubscription = {
  __typename?: 'Subscription';
  onCompanyGiftChanged?: {
    __typename?: 'Gift';
    id: string;
    companyId: string;
    employeeId: string;
    status: TGiftStatuses;
    _version: number;
    _deleted?: boolean | null;
  } | null;
};

export type TOnEmployeeGiftChangedSubscriptionVariables = Exact<{
  employeeId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}>;

export type TOnEmployeeGiftChangedSubscription = {
  __typename?: 'Subscription';
  onEmployeeGiftChanged?: {
    __typename?: 'Gift';
    id: string;
    companyId: string;
    employeeId: string;
    status: TGiftStatuses;
    _version: number;
    _deleted?: boolean | null;
  } | null;
};

export type TSubscriptionOfficeFieldsFragment = {
  __typename?: 'Office';
  id: string;
  active: boolean;
  name: string;
  legalName?: string | null;
  companyId: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt?: any | null;
  contact?: { __typename?: 'Contact'; name?: string | null; email?: any | null; phoneNumber?: string | null } | null;
};

export type TOfficeFieldsFragment = {
  __typename?: 'Office';
  id: string;
  active: boolean;
  name: string;
  legalName?: string | null;
  companyId: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt?: any | null;
  address?: {
    __typename?: 'Address';
    id: string;
    companyId: string;
    _version: number;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zipCode?: string | null;
    useGoogleAddress?: boolean | null;
    googleValidated?: boolean | null;
    missingInfo?: boolean | null;
    alert?: string | null;
    addressFromGoogle?: {
      __typename?: 'AddressFromGoogle';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
    } | null;
  } | null;
  contact?: { __typename?: 'Contact'; name?: string | null; email?: any | null; phoneNumber?: string | null } | null;
};

export type TListOfficesQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListOfficesQuery = {
  __typename?: 'Query';
  listOffices: {
    __typename?: 'OfficesConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Office';
      id: string;
      active: boolean;
      name: string;
      legalName?: string | null;
      companyId: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt?: any | null;
      address?: {
        __typename?: 'Address';
        id: string;
        companyId: string;
        _version: number;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        useGoogleAddress?: boolean | null;
        googleValidated?: boolean | null;
        missingInfo?: boolean | null;
        alert?: string | null;
        addressFromGoogle?: {
          __typename?: 'AddressFromGoogle';
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
        } | null;
      } | null;
      contact?: {
        __typename?: 'Contact';
        name?: string | null;
        email?: any | null;
        phoneNumber?: string | null;
      } | null;
    } | null>;
  };
};

export type TGetOfficeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}>;

export type TGetOfficeQuery = {
  __typename?: 'Query';
  getOffice?: {
    __typename?: 'Office';
    id: string;
    active: boolean;
    name: string;
    legalName?: string | null;
    companyId: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
    address?: {
      __typename?: 'Address';
      id: string;
      companyId: string;
      _version: number;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      useGoogleAddress?: boolean | null;
      googleValidated?: boolean | null;
      missingInfo?: boolean | null;
      alert?: string | null;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
    contact?: { __typename?: 'Contact'; name?: string | null; email?: any | null; phoneNumber?: string | null } | null;
  } | null;
};

export type TCreateOfficeMutationVariables = Exact<{
  input: TCreateOfficeMutationInput;
}>;

export type TCreateOfficeMutation = {
  __typename?: 'Mutation';
  createOffice?: {
    __typename?: 'Office';
    id: string;
    active: boolean;
    name: string;
    legalName?: string | null;
    companyId: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
    address?: {
      __typename?: 'Address';
      id: string;
      companyId: string;
      _version: number;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      useGoogleAddress?: boolean | null;
      googleValidated?: boolean | null;
      missingInfo?: boolean | null;
      alert?: string | null;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
    contact?: { __typename?: 'Contact'; name?: string | null; email?: any | null; phoneNumber?: string | null } | null;
  } | null;
};

export type TUpdateOfficeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
  input: TUpdateOfficeMutationInput;
}>;

export type TUpdateOfficeMutation = {
  __typename?: 'Mutation';
  updateOffice?: {
    __typename?: 'Office';
    id: string;
    active: boolean;
    name: string;
    legalName?: string | null;
    companyId: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
    address?: {
      __typename?: 'Address';
      id: string;
      companyId: string;
      _version: number;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      useGoogleAddress?: boolean | null;
      googleValidated?: boolean | null;
      missingInfo?: boolean | null;
      alert?: string | null;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
    contact?: { __typename?: 'Contact'; name?: string | null; email?: any | null; phoneNumber?: string | null } | null;
  } | null;
};

export type TDeleteOfficeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
}>;

export type TDeleteOfficeMutation = {
  __typename?: 'Mutation';
  deleteOffice?: {
    __typename?: 'Office';
    id: string;
    active: boolean;
    name: string;
    legalName?: string | null;
    companyId: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
    contact?: { __typename?: 'Contact'; name?: string | null; email?: any | null; phoneNumber?: string | null } | null;
  } | null;
};

export type TOnCompanyOfficeChangedSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type TOnCompanyOfficeChangedSubscription = {
  __typename?: 'Subscription';
  onCompanyOfficeChanged?: {
    __typename?: 'Office';
    id: string;
    active: boolean;
    name: string;
    legalName?: string | null;
    companyId: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
    contact?: { __typename?: 'Contact'; name?: string | null; email?: any | null; phoneNumber?: string | null } | null;
  } | null;
};

type TRecipientFields_Dependant_Fragment = {
  __typename?: 'Dependant';
  id: string;
  companyId: string;
  employeeId: string;
  type: TDependantType;
  petType?: string | null;
  firstName?: string | null;
  preferredFirstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  birthDate?: any | null;
  foodPreferences?: string | null;
  donateBirthdayGift?: boolean | null;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt?: any | null;
  employee?: {
    __typename?: 'Employee';
    id: string;
    companyId: string;
    fullName?: string | null;
    email?: any | null;
    birthDate?: any | null;
    hireDate?: any | null;
    officeId?: string | null;
    isPaused?: boolean | null;
    isPauseEnabled?: boolean | null;
    _deleted?: boolean | null;
    pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
    address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
  } | null;
};

type TRecipientFields_Employee_Fragment = {
  __typename?: 'Employee';
  id: string;
  companyId: string;
  fullName?: string | null;
  email?: any | null;
  birthDate?: any | null;
  hireDate?: any | null;
  officeId?: string | null;
  isPaused?: boolean | null;
  isPauseEnabled?: boolean | null;
  _deleted?: boolean | null;
  pausePeriod?: { __typename?: 'PausePeriod'; fromDate: any; toDate?: any | null } | null;
  address?: { __typename?: 'Address'; id: string; missingInfo?: boolean | null; alert?: string | null } | null;
};

export type TRecipientFieldsFragment = TRecipientFields_Dependant_Fragment | TRecipientFields_Employee_Fragment;

export type TShipmentGiftFieldsFragment = {
  __typename?: 'Gift';
  id: string;
  recipient?:
    | { __typename?: 'Dependant'; type: TDependantType; petType?: string | null; fullName?: string | null }
    | { __typename?: 'Employee'; fullName?: string | null }
    | null;
  event?: { __typename?: 'Event'; eventDate: any; type: TEventTypes } | null;
};

export type TShipmentAddressEmployeeFieldsFragment = { __typename?: 'Employee'; id: string; fullName?: string | null };

export type TShipmentAddressOfficeFieldsFragment = { __typename?: 'Office'; id: string; name: string };

export type TShipmentAddressCompanyFieldsFragment = { __typename?: 'Company'; id: string; name: string };

export type TShipmentAddressFieldsFragment = {
  __typename?: 'Address';
  id: string;
  companyId: string;
  _version: number;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zipCode?: string | null;
  useGoogleAddress?: boolean | null;
  googleValidated?: boolean | null;
  missingInfo?: boolean | null;
  alert?: string | null;
  employee?: { __typename?: 'Employee'; id: string; fullName?: string | null } | null;
  office?: { __typename?: 'Office'; id: string; name: string } | null;
  company?: { __typename?: 'Company'; id: string; name: string } | null;
  addressFromGoogle?: {
    __typename?: 'AddressFromGoogle';
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zipCode?: string | null;
  } | null;
};

export type TSubscriptionShipmentFieldsFragment = {
  __typename?: 'Shipment';
  id: string;
  companyId: string;
  name?: string | null;
  status: TShipmentStatuses;
  trackingNumber?: string | null;
  trackingURL?: string | null;
  actualShippingDate?: any | null;
  shippingDate?: any | null;
  _deleted?: boolean | null;
  _version: number;
  label?: { __typename?: 'ShipmentLabel'; carrier: string } | null;
};

export type TShipmentFieldsFragment = {
  __typename?: 'Shipment';
  id: string;
  companyId: string;
  name?: string | null;
  status: TShipmentStatuses;
  trackingNumber?: string | null;
  trackingURL?: string | null;
  actualShippingDate?: any | null;
  shippingDate?: any | null;
  _deleted?: boolean | null;
  _version: number;
  address?: {
    __typename?: 'Address';
    id: string;
    companyId: string;
    _version: number;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zipCode?: string | null;
    useGoogleAddress?: boolean | null;
    googleValidated?: boolean | null;
    missingInfo?: boolean | null;
    alert?: string | null;
    employee?: { __typename?: 'Employee'; id: string; fullName?: string | null } | null;
    office?: { __typename?: 'Office'; id: string; name: string } | null;
    company?: { __typename?: 'Company'; id: string; name: string } | null;
    addressFromGoogle?: {
      __typename?: 'AddressFromGoogle';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
    } | null;
  } | null;
  gifts?: {
    __typename?: 'GiftsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      status: TGiftStatuses;
      _version: number;
      _deleted?: boolean | null;
      recipient?:
        | {
            __typename?: 'Dependant';
            type: TDependantType;
            petType?: string | null;
            id: string;
            companyId: string;
            fullName?: string | null;
          }
        | { __typename?: 'Employee'; id: string; companyId: string; fullName?: string | null }
        | null;
      event?: { __typename?: 'Event'; eventDate: any; type: TEventTypes; customType?: string | null } | null;
    } | null>;
  } | null;
  label?: { __typename?: 'ShipmentLabel'; carrier: string } | null;
};

export type TListShipmentsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListShipmentsQuery = {
  __typename?: 'Query';
  listCompanyShipments: {
    __typename?: 'ShipmentsConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Shipment';
      id: string;
      companyId: string;
      name?: string | null;
      status: TShipmentStatuses;
      trackingNumber?: string | null;
      trackingURL?: string | null;
      actualShippingDate?: any | null;
      shippingDate?: any | null;
      _deleted?: boolean | null;
      _version: number;
      address?: {
        __typename?: 'Address';
        id: string;
        companyId: string;
        _version: number;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
        useGoogleAddress?: boolean | null;
        googleValidated?: boolean | null;
        missingInfo?: boolean | null;
        alert?: string | null;
        employee?: { __typename?: 'Employee'; id: string; fullName?: string | null } | null;
        office?: { __typename?: 'Office'; id: string; name: string } | null;
        company?: { __typename?: 'Company'; id: string; name: string } | null;
        addressFromGoogle?: {
          __typename?: 'AddressFromGoogle';
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zipCode?: string | null;
        } | null;
      } | null;
      gifts?: {
        __typename?: 'GiftsConnection';
        nextToken?: string | null;
        items: Array<{
          __typename?: 'Gift';
          id: string;
          companyId: string;
          employeeId: string;
          status: TGiftStatuses;
          _version: number;
          _deleted?: boolean | null;
          recipient?:
            | {
                __typename?: 'Dependant';
                type: TDependantType;
                petType?: string | null;
                id: string;
                companyId: string;
                fullName?: string | null;
              }
            | { __typename?: 'Employee'; id: string; companyId: string; fullName?: string | null }
            | null;
          event?: { __typename?: 'Event'; eventDate: any; type: TEventTypes; customType?: string | null } | null;
        } | null>;
      } | null;
      label?: { __typename?: 'ShipmentLabel'; carrier: string } | null;
    } | null>;
  };
};

export type TGetShipmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}>;

export type TGetShipmentQuery = {
  __typename?: 'Query';
  getShipment?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    name?: string | null;
    status: TShipmentStatuses;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    actualShippingDate?: any | null;
    shippingDate?: any | null;
    _deleted?: boolean | null;
    _version: number;
    address?: {
      __typename?: 'Address';
      id: string;
      companyId: string;
      _version: number;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      useGoogleAddress?: boolean | null;
      googleValidated?: boolean | null;
      missingInfo?: boolean | null;
      alert?: string | null;
      employee?: { __typename?: 'Employee'; id: string; fullName?: string | null } | null;
      office?: { __typename?: 'Office'; id: string; name: string } | null;
      company?: { __typename?: 'Company'; id: string; name: string } | null;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
    gifts?: {
      __typename?: 'GiftsConnection';
      nextToken?: string | null;
      items: Array<{
        __typename?: 'Gift';
        id: string;
        companyId: string;
        employeeId: string;
        status: TGiftStatuses;
        _version: number;
        _deleted?: boolean | null;
        recipient?:
          | {
              __typename?: 'Dependant';
              type: TDependantType;
              petType?: string | null;
              id: string;
              companyId: string;
              fullName?: string | null;
            }
          | { __typename?: 'Employee'; id: string; companyId: string; fullName?: string | null }
          | null;
        event?: { __typename?: 'Event'; eventDate: any; type: TEventTypes; customType?: string | null } | null;
      } | null>;
    } | null;
    label?: { __typename?: 'ShipmentLabel'; carrier: string } | null;
  } | null;
};

export type TOnCompanyShipmentChangedSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type TOnCompanyShipmentChangedSubscription = {
  __typename?: 'Subscription';
  onCompanyShipmentChanged?: {
    __typename?: 'Shipment';
    id: string;
    companyId: string;
    name?: string | null;
    status: TShipmentStatuses;
    trackingNumber?: string | null;
    trackingURL?: string | null;
    actualShippingDate?: any | null;
    shippingDate?: any | null;
    _deleted?: boolean | null;
    _version: number;
    address?: {
      __typename?: 'Address';
      id: string;
      companyId: string;
      _version: number;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zipCode?: string | null;
      useGoogleAddress?: boolean | null;
      googleValidated?: boolean | null;
      missingInfo?: boolean | null;
      alert?: string | null;
      employee?: { __typename?: 'Employee'; id: string; fullName?: string | null } | null;
      office?: { __typename?: 'Office'; id: string; name: string } | null;
      company?: { __typename?: 'Company'; id: string; name: string } | null;
      addressFromGoogle?: {
        __typename?: 'AddressFromGoogle';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zipCode?: string | null;
      } | null;
    } | null;
    gifts?: {
      __typename?: 'GiftsConnection';
      nextToken?: string | null;
      items: Array<{
        __typename?: 'Gift';
        id: string;
        companyId: string;
        employeeId: string;
        status: TGiftStatuses;
        _version: number;
        _deleted?: boolean | null;
        recipient?:
          | {
              __typename?: 'Dependant';
              type: TDependantType;
              petType?: string | null;
              id: string;
              companyId: string;
              fullName?: string | null;
            }
          | { __typename?: 'Employee'; id: string; companyId: string; fullName?: string | null }
          | null;
        event?: { __typename?: 'Event'; eventDate: any; type: TEventTypes; customType?: string | null } | null;
      } | null>;
    } | null;
    label?: { __typename?: 'ShipmentLabel'; carrier: string } | null;
  } | null;
};

export type TOnCompanyShipmentRecreatedSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type TOnCompanyShipmentRecreatedSubscription = {
  __typename?: 'Subscription';
  onCompanyShipmentRecreated?: {
    __typename?: 'ShipmentRecreatedResponse';
    companyId: string;
    items: Array<{
      __typename?: 'Gift';
      id: string;
      companyId: string;
      employeeId: string;
      status: TGiftStatuses;
      _version: number;
      _deleted?: boolean | null;
    } | null>;
  } | null;
};

export type TSubscriptionUserFieldsFragment = {
  __typename?: 'User';
  companyId: string;
  email: any;
  name: string;
  type: TUserType;
  title?: string | null;
  isCompanyOwner: boolean;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt?: any | null;
};

export type TUserFieldsFragment = {
  __typename?: 'User';
  companyId: string;
  email: any;
  name: string;
  type: TUserType;
  title?: string | null;
  isCompanyOwner: boolean;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt?: any | null;
};

export type TListUsersQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TOrderDirection>;
}>;

export type TListUsersQuery = {
  __typename?: 'Query';
  listUsers: {
    __typename?: 'UsersConnection';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'User';
      companyId: string;
      email: any;
      name: string;
      type: TUserType;
      title?: string | null;
      isCompanyOwner: boolean;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt?: any | null;
    } | null>;
  };
};

export type TCreateUserMutationVariables = Exact<{
  input: TCreateUserMutationInput;
}>;

export type TCreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: {
    __typename?: 'User';
    companyId: string;
    email: any;
    name: string;
    type: TUserType;
    title?: string | null;
    isCompanyOwner: boolean;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
  } | null;
};

export type TUpdateUserMutationVariables = Exact<{
  email: Scalars['AWSEmail']['input'];
  companyId: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
  input: TUpdateUserMutationInput;
}>;

export type TUpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'User';
    companyId: string;
    email: any;
    name: string;
    type: TUserType;
    title?: string | null;
    isCompanyOwner: boolean;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
  } | null;
};

export type TDeleteUserMutationVariables = Exact<{
  email: Scalars['AWSEmail']['input'];
  companyId: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
}>;

export type TDeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser?: {
    __typename?: 'User';
    companyId: string;
    email: any;
    name: string;
    type: TUserType;
    title?: string | null;
    isCompanyOwner: boolean;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
  } | null;
};

export type TTransferOwnershipMutationVariables = Exact<{
  oldOwnerEmail: Scalars['AWSEmail']['input'];
  _oldOwnerVersion: Scalars['Int']['input'];
  newOwnerEmail: Scalars['AWSEmail']['input'];
  companyId: Scalars['ID']['input'];
}>;

export type TTransferOwnershipMutation = {
  __typename?: 'Mutation';
  transferOwnership?: Array<{
    __typename?: 'User';
    companyId: string;
    email: any;
    name: string;
    type: TUserType;
    title?: string | null;
    isCompanyOwner: boolean;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
  } | null> | null;
};

export type TOnCompanyUserChangedSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type TOnCompanyUserChangedSubscription = {
  __typename?: 'Subscription';
  onCompanyUserChanged?: {
    __typename?: 'User';
    companyId: string;
    email: any;
    name: string;
    type: TUserType;
    title?: string | null;
    isCompanyOwner: boolean;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
  } | null;
};

export type TOnCompanyOwnershipTransferedSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type TOnCompanyOwnershipTransferedSubscription = {
  __typename?: 'Subscription';
  onCompanyOwnershipTransfered?: Array<{
    __typename?: 'User';
    companyId: string;
    email: any;
    name: string;
    type: TUserType;
    title?: string | null;
    isCompanyOwner: boolean;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt?: any | null;
  } | null> | null;
};

export const PublicCompanyFieldsFragmentDoc = gql`
  fragment PublicCompanyFields on Company {
    googleLoginEnabled
    passwordLoginEnabled
    id
    name
    subdomain
    configuration {
      authUserPoolId
      authUserPoolClientId
      authUserPoolDomainURL
    }
  }
`;
export const ContactFieldsFragmentDoc = gql`
  fragment ContactFields on Contact {
    name
    email
    phoneNumber
  }
`;
export const SubscriptionPrivateCompanyFieldsFragmentDoc = gql`
  fragment SubscriptionPrivateCompanyFields on Company {
    id
    active
    deliveryMethod
    name
    accountManager
    subdomain
    configuration {
      authUserPoolId
      authUserPoolClientId
      authUserPoolDomainURL
    }
    emailDomains
    googleLoginEnabled
    swag
    legoActivated
    companyWideLegoCustomization
    customLego
    legoColor
    tissueColor
    signAs
    specialNote
    startDate
    endDate
    employeeBirthdayActivated
    employeeWorkAnniversaryActivated
    employeeNewHireKitActivated
    significantOtherBirthdayActivated
    childBirthdayActivated
    petBirthdayActivated
    hasCustomOnboardBrick
    workAnniversaryGiftVariation
    contact {
      ...ContactFields
    }
    _version
    _deleted
    _lastChangedAt
  }
  ${ContactFieldsFragmentDoc}
`;
export const AddressFieldsFragmentDoc = gql`
  fragment AddressFields on Address {
    id
    companyId
    _version
    address1
    address2
    city
    state
    country
    zipCode
    addressFromGoogle {
      address1
      address2
      city
      state
      country
      zipCode
    }
    useGoogleAddress
    googleValidated
    missingInfo
    alert
  }
`;
export const PrivateCompanyFieldsFragmentDoc = gql`
  fragment PrivateCompanyFields on Company {
    ...SubscriptionPrivateCompanyFields
    address {
      ...AddressFields
    }
  }
  ${SubscriptionPrivateCompanyFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;
export const ListEmployeeFieldsFragmentDoc = gql`
  fragment ListEmployeeFields on Employee {
    id
    companyId
    fullName
    email
    birthDate
    hireDate
    officeId
    isPaused
    isPauseEnabled
    pausePeriod {
      fromDate
      toDate
    }
    address {
      id
      missingInfo
      alert
    }
    _deleted
  }
`;
export const SubscriptionEmployeeFieldsFragmentDoc = gql`
  fragment SubscriptionEmployeeFields on Employee {
    ...ListEmployeeFields
    active
    externalId
    firstName
    preferredFirstName
    lastName
    phoneNumber
    foodPreferences
    deliveryMethod
    customOnboardBrick
    tShirtSize
    sweaterSize
    donateBirthdayGift
    donateWorkAnniversaryGift
    _version
    _lastChangedAt
  }
  ${ListEmployeeFieldsFragmentDoc}
`;
export const EmployeeFieldsFragmentDoc = gql`
  fragment EmployeeFields on Employee {
    ...SubscriptionEmployeeFields
    address {
      ...AddressFields
    }
    office {
      id
      name
    }
  }
  ${SubscriptionEmployeeFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;
export const SubscriptionDependantFieldsFragmentDoc = gql`
  fragment SubscriptionDependantFields on Dependant {
    id
    companyId
    employeeId
    type
    petType
    firstName
    preferredFirstName
    lastName
    fullName
    birthDate
    foodPreferences
    donateBirthdayGift
    _version
    _deleted
    _lastChangedAt
  }
`;
export const DependantFieldsFragmentDoc = gql`
  fragment DependantFields on Dependant {
    ...SubscriptionDependantFields
  }
  ${SubscriptionDependantFieldsFragmentDoc}
`;
export const DependantWithEmployeeFieldsFragmentDoc = gql`
  fragment DependantWithEmployeeFields on Dependant {
    ...DependantFields
    employee {
      ...ListEmployeeFields
    }
  }
  ${DependantFieldsFragmentDoc}
  ${ListEmployeeFieldsFragmentDoc}
`;
export const RecipientFieldsFragmentDoc = gql`
  fragment RecipientFields on Recipient {
    ... on Dependant {
      ...DependantWithEmployeeFields
    }
    ... on Employee {
      ...ListEmployeeFields
    }
  }
  ${DependantWithEmployeeFieldsFragmentDoc}
  ${ListEmployeeFieldsFragmentDoc}
`;
export const EventFieldsFragmentDoc = gql`
  fragment EventFields on Event {
    id
    companyId
    eventDate
    type
    age
    deliveredAt
    isPaused
    wasPaused
    employee {
      id
      companyId
      fullName
    }
    recipient {
      ...RecipientFields
    }
    _version
    _deleted
  }
  ${RecipientFieldsFragmentDoc}
`;
export const GiftEmployeeFragmentDoc = gql`
  fragment GiftEmployee on Employee {
    id
    companyId
    fullName
  }
`;
export const GiftDependantFragmentDoc = gql`
  fragment GiftDependant on Dependant {
    id
    companyId
    fullName
    type
    petType
  }
`;
export const SubscriptionGiftFieldsFragmentDoc = gql`
  fragment SubscriptionGiftFields on Gift {
    id
    companyId
    employeeId
    status
    _version
    _deleted
  }
`;
export const GiftRecipientFieldsFragmentDoc = gql`
  fragment GiftRecipientFields on Recipient {
    id
    companyId
    fullName
    ... on Dependant {
      type
      petType
    }
  }
`;
export const BasicGiftFieldsFragmentDoc = gql`
  fragment BasicGiftFields on Gift {
    ...SubscriptionGiftFields
    recipient {
      ...GiftRecipientFields
    }
    event {
      eventDate
      type
      customType
    }
  }
  ${SubscriptionGiftFieldsFragmentDoc}
  ${GiftRecipientFieldsFragmentDoc}
`;
export const GiftShipmentFieldsFragmentDoc = gql`
  fragment GiftShipmentFields on Shipment {
    id
    companyId
    status
    trackingNumber
    actualShippingDate
    shippingDate
    label {
      carrier
    }
  }
`;
export const GiftFieldsFragmentDoc = gql`
  fragment GiftFields on Gift {
    ...BasicGiftFields
    shipment {
      ...GiftShipmentFields
    }
  }
  ${BasicGiftFieldsFragmentDoc}
  ${GiftShipmentFieldsFragmentDoc}
`;
export const SubscriptionOfficeFieldsFragmentDoc = gql`
  fragment SubscriptionOfficeFields on Office {
    id
    active
    name
    legalName
    companyId
    _version
    _deleted
    _lastChangedAt
    contact {
      ...ContactFields
    }
  }
  ${ContactFieldsFragmentDoc}
`;
export const OfficeFieldsFragmentDoc = gql`
  fragment OfficeFields on Office {
    ...SubscriptionOfficeFields
    address {
      ...AddressFields
    }
  }
  ${SubscriptionOfficeFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;
export const ShipmentGiftFieldsFragmentDoc = gql`
  fragment ShipmentGiftFields on Gift {
    id
    recipient {
      fullName
      ... on Dependant {
        type
        petType
      }
    }
    event {
      eventDate
      type
    }
  }
`;
export const SubscriptionShipmentFieldsFragmentDoc = gql`
  fragment SubscriptionShipmentFields on Shipment {
    id
    companyId
    name
    status
    trackingNumber
    trackingURL
    actualShippingDate
    shippingDate
    label {
      carrier
    }
    _deleted
    _version
  }
`;
export const ShipmentAddressEmployeeFieldsFragmentDoc = gql`
  fragment ShipmentAddressEmployeeFields on Employee {
    id
    fullName
  }
`;
export const ShipmentAddressOfficeFieldsFragmentDoc = gql`
  fragment ShipmentAddressOfficeFields on Office {
    id
    name
  }
`;
export const ShipmentAddressCompanyFieldsFragmentDoc = gql`
  fragment ShipmentAddressCompanyFields on Company {
    id
    name
  }
`;
export const ShipmentAddressFieldsFragmentDoc = gql`
  fragment ShipmentAddressFields on Address {
    ...AddressFields
    employee {
      ...ShipmentAddressEmployeeFields
    }
    office {
      ...ShipmentAddressOfficeFields
    }
    company {
      ...ShipmentAddressCompanyFields
    }
  }
  ${AddressFieldsFragmentDoc}
  ${ShipmentAddressEmployeeFieldsFragmentDoc}
  ${ShipmentAddressOfficeFieldsFragmentDoc}
  ${ShipmentAddressCompanyFieldsFragmentDoc}
`;
export const ShipmentFieldsFragmentDoc = gql`
  fragment ShipmentFields on Shipment {
    ...SubscriptionShipmentFields
    address {
      ...ShipmentAddressFields
    }
    gifts {
      nextToken
      items {
        ...BasicGiftFields
      }
    }
  }
  ${SubscriptionShipmentFieldsFragmentDoc}
  ${ShipmentAddressFieldsFragmentDoc}
  ${BasicGiftFieldsFragmentDoc}
`;
export const SubscriptionUserFieldsFragmentDoc = gql`
  fragment SubscriptionUserFields on User {
    companyId
    email
    name
    type
    title
    isCompanyOwner
    _version
    _deleted
    _lastChangedAt
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    ...SubscriptionUserFields
  }
  ${SubscriptionUserFieldsFragmentDoc}
`;
export const SkipAddressAlertDocument = gql`
  mutation SkipAddressAlert($id: ID!, $companyId: ID!, $version: Int!) {
    skipAddressAlert(id: $id, companyId: $companyId, _version: $version) {
      ...AddressFields
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export type TSkipAddressAlertMutationFn = Apollo.MutationFunction<
  TSkipAddressAlertMutation,
  TSkipAddressAlertMutationVariables
>;

/**
 * __useSkipAddressAlertMutation__
 *
 * To run a mutation, you first call `useSkipAddressAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipAddressAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipAddressAlertMutation, { data, loading, error }] = useSkipAddressAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useSkipAddressAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<TSkipAddressAlertMutation, TSkipAddressAlertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TSkipAddressAlertMutation, TSkipAddressAlertMutationVariables>(
    SkipAddressAlertDocument,
    options,
  );
}
export type SkipAddressAlertMutationHookResult = ReturnType<typeof useSkipAddressAlertMutation>;
export type SkipAddressAlertMutationResult = Apollo.MutationResult<TSkipAddressAlertMutation>;
export type SkipAddressAlertMutationOptions = Apollo.BaseMutationOptions<
  TSkipAddressAlertMutation,
  TSkipAddressAlertMutationVariables
>;
export const GetMyCompanyDocument = gql`
  query GetMyCompany {
    getMyCompany {
      ...PrivateCompanyFields
    }
  }
  ${PrivateCompanyFieldsFragmentDoc}
`;

/**
 * __useGetMyCompanyQuery__
 *
 * To run a query within a React component, call `useGetMyCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<TGetMyCompanyQuery, TGetMyCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetMyCompanyQuery, TGetMyCompanyQueryVariables>(GetMyCompanyDocument, options);
}
export function useGetMyCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetMyCompanyQuery, TGetMyCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetMyCompanyQuery, TGetMyCompanyQueryVariables>(GetMyCompanyDocument, options);
}
export function useGetMyCompanySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TGetMyCompanyQuery, TGetMyCompanyQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TGetMyCompanyQuery, TGetMyCompanyQueryVariables>(GetMyCompanyDocument, options);
}
export type GetMyCompanyQueryHookResult = ReturnType<typeof useGetMyCompanyQuery>;
export type GetMyCompanyLazyQueryHookResult = ReturnType<typeof useGetMyCompanyLazyQuery>;
export type GetMyCompanySuspenseQueryHookResult = ReturnType<typeof useGetMyCompanySuspenseQuery>;
export type GetMyCompanyQueryResult = Apollo.QueryResult<TGetMyCompanyQuery, TGetMyCompanyQueryVariables>;
export const GetCompanyBySubdomainDocument = gql`
  query GetCompanyBySubdomain($subdomain: String!) {
    getCompanyBySubdomain(subdomain: $subdomain) {
      ...PublicCompanyFields
    }
  }
  ${PublicCompanyFieldsFragmentDoc}
`;

/**
 * __useGetCompanyBySubdomainQuery__
 *
 * To run a query within a React component, call `useGetCompanyBySubdomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyBySubdomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyBySubdomainQuery({
 *   variables: {
 *      subdomain: // value for 'subdomain'
 *   },
 * });
 */
export function useGetCompanyBySubdomainQuery(
  baseOptions: Apollo.QueryHookOptions<TGetCompanyBySubdomainQuery, TGetCompanyBySubdomainQueryVariables> &
    ({ variables: TGetCompanyBySubdomainQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetCompanyBySubdomainQuery, TGetCompanyBySubdomainQueryVariables>(
    GetCompanyBySubdomainDocument,
    options,
  );
}
export function useGetCompanyBySubdomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetCompanyBySubdomainQuery, TGetCompanyBySubdomainQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetCompanyBySubdomainQuery, TGetCompanyBySubdomainQueryVariables>(
    GetCompanyBySubdomainDocument,
    options,
  );
}
export function useGetCompanyBySubdomainSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TGetCompanyBySubdomainQuery, TGetCompanyBySubdomainQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TGetCompanyBySubdomainQuery, TGetCompanyBySubdomainQueryVariables>(
    GetCompanyBySubdomainDocument,
    options,
  );
}
export type GetCompanyBySubdomainQueryHookResult = ReturnType<typeof useGetCompanyBySubdomainQuery>;
export type GetCompanyBySubdomainLazyQueryHookResult = ReturnType<typeof useGetCompanyBySubdomainLazyQuery>;
export type GetCompanyBySubdomainSuspenseQueryHookResult = ReturnType<typeof useGetCompanyBySubdomainSuspenseQuery>;
export type GetCompanyBySubdomainQueryResult = Apollo.QueryResult<
  TGetCompanyBySubdomainQuery,
  TGetCompanyBySubdomainQueryVariables
>;
export const OnCompanyChangedDocument = gql`
  subscription OnCompanyChanged($id: ID!) {
    onCompanyChanged(id: $id) {
      ...SubscriptionPrivateCompanyFields
    }
  }
  ${SubscriptionPrivateCompanyFieldsFragmentDoc}
`;

/**
 * __useOnCompanyChangedSubscription__
 *
 * To run a query within a React component, call `useOnCompanyChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCompanyChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCompanyChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnCompanyChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<TOnCompanyChangedSubscription, TOnCompanyChangedSubscriptionVariables> &
    ({ variables: TOnCompanyChangedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnCompanyChangedSubscription, TOnCompanyChangedSubscriptionVariables>(
    OnCompanyChangedDocument,
    options,
  );
}
export type OnCompanyChangedSubscriptionHookResult = ReturnType<typeof useOnCompanyChangedSubscription>;
export type OnCompanyChangedSubscriptionResult = Apollo.SubscriptionResult<TOnCompanyChangedSubscription>;
export const ListCompanyDependantsDocument = gql`
  query ListCompanyDependants($companyId: ID!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listCompanyDependants(companyId: $companyId, nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...DependantFields
      }
    }
  }
  ${DependantFieldsFragmentDoc}
`;

/**
 * __useListCompanyDependantsQuery__
 *
 * To run a query within a React component, call `useListCompanyDependantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyDependantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyDependantsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListCompanyDependantsQuery(
  baseOptions: Apollo.QueryHookOptions<TListCompanyDependantsQuery, TListCompanyDependantsQueryVariables> &
    ({ variables: TListCompanyDependantsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListCompanyDependantsQuery, TListCompanyDependantsQueryVariables>(
    ListCompanyDependantsDocument,
    options,
  );
}
export function useListCompanyDependantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListCompanyDependantsQuery, TListCompanyDependantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListCompanyDependantsQuery, TListCompanyDependantsQueryVariables>(
    ListCompanyDependantsDocument,
    options,
  );
}
export function useListCompanyDependantsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TListCompanyDependantsQuery, TListCompanyDependantsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TListCompanyDependantsQuery, TListCompanyDependantsQueryVariables>(
    ListCompanyDependantsDocument,
    options,
  );
}
export type ListCompanyDependantsQueryHookResult = ReturnType<typeof useListCompanyDependantsQuery>;
export type ListCompanyDependantsLazyQueryHookResult = ReturnType<typeof useListCompanyDependantsLazyQuery>;
export type ListCompanyDependantsSuspenseQueryHookResult = ReturnType<typeof useListCompanyDependantsSuspenseQuery>;
export type ListCompanyDependantsQueryResult = Apollo.QueryResult<
  TListCompanyDependantsQuery,
  TListCompanyDependantsQueryVariables
>;
export const ListEmployeeDependantsDocument = gql`
  query ListEmployeeDependants(
    $companyId: ID!
    $employeeId: ID!
    $nextToken: String
    $limit: Int
    $order: OrderDirection
  ) {
    listEmployeeDependants(
      companyId: $companyId
      employeeId: $employeeId
      nextToken: $nextToken
      limit: $limit
      order: $order
    ) {
      nextToken
      items {
        ...DependantFields
      }
    }
  }
  ${DependantFieldsFragmentDoc}
`;

/**
 * __useListEmployeeDependantsQuery__
 *
 * To run a query within a React component, call `useListEmployeeDependantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeeDependantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeeDependantsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      employeeId: // value for 'employeeId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListEmployeeDependantsQuery(
  baseOptions: Apollo.QueryHookOptions<TListEmployeeDependantsQuery, TListEmployeeDependantsQueryVariables> &
    ({ variables: TListEmployeeDependantsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListEmployeeDependantsQuery, TListEmployeeDependantsQueryVariables>(
    ListEmployeeDependantsDocument,
    options,
  );
}
export function useListEmployeeDependantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListEmployeeDependantsQuery, TListEmployeeDependantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListEmployeeDependantsQuery, TListEmployeeDependantsQueryVariables>(
    ListEmployeeDependantsDocument,
    options,
  );
}
export function useListEmployeeDependantsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TListEmployeeDependantsQuery, TListEmployeeDependantsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TListEmployeeDependantsQuery, TListEmployeeDependantsQueryVariables>(
    ListEmployeeDependantsDocument,
    options,
  );
}
export type ListEmployeeDependantsQueryHookResult = ReturnType<typeof useListEmployeeDependantsQuery>;
export type ListEmployeeDependantsLazyQueryHookResult = ReturnType<typeof useListEmployeeDependantsLazyQuery>;
export type ListEmployeeDependantsSuspenseQueryHookResult = ReturnType<typeof useListEmployeeDependantsSuspenseQuery>;
export type ListEmployeeDependantsQueryResult = Apollo.QueryResult<
  TListEmployeeDependantsQuery,
  TListEmployeeDependantsQueryVariables
>;
export const GetDependantDocument = gql`
  query GetDependant($id: ID!, $companyId: ID!) {
    getDependant(id: $id, companyId: $companyId) {
      ...DependantFields
    }
  }
  ${DependantFieldsFragmentDoc}
`;

/**
 * __useGetDependantQuery__
 *
 * To run a query within a React component, call `useGetDependantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDependantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDependantQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetDependantQuery(
  baseOptions: Apollo.QueryHookOptions<TGetDependantQuery, TGetDependantQueryVariables> &
    ({ variables: TGetDependantQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetDependantQuery, TGetDependantQueryVariables>(GetDependantDocument, options);
}
export function useGetDependantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetDependantQuery, TGetDependantQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetDependantQuery, TGetDependantQueryVariables>(GetDependantDocument, options);
}
export function useGetDependantSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TGetDependantQuery, TGetDependantQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TGetDependantQuery, TGetDependantQueryVariables>(GetDependantDocument, options);
}
export type GetDependantQueryHookResult = ReturnType<typeof useGetDependantQuery>;
export type GetDependantLazyQueryHookResult = ReturnType<typeof useGetDependantLazyQuery>;
export type GetDependantSuspenseQueryHookResult = ReturnType<typeof useGetDependantSuspenseQuery>;
export type GetDependantQueryResult = Apollo.QueryResult<TGetDependantQuery, TGetDependantQueryVariables>;
export const CreateDependantDocument = gql`
  mutation CreateDependant($input: CreateDependantMutationInput!) {
    createDependant(input: $input) {
      ...DependantFields
    }
  }
  ${DependantFieldsFragmentDoc}
`;
export type TCreateDependantMutationFn = Apollo.MutationFunction<
  TCreateDependantMutation,
  TCreateDependantMutationVariables
>;

/**
 * __useCreateDependantMutation__
 *
 * To run a mutation, you first call `useCreateDependantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDependantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDependantMutation, { data, loading, error }] = useCreateDependantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDependantMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateDependantMutation, TCreateDependantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateDependantMutation, TCreateDependantMutationVariables>(
    CreateDependantDocument,
    options,
  );
}
export type CreateDependantMutationHookResult = ReturnType<typeof useCreateDependantMutation>;
export type CreateDependantMutationResult = Apollo.MutationResult<TCreateDependantMutation>;
export type CreateDependantMutationOptions = Apollo.BaseMutationOptions<
  TCreateDependantMutation,
  TCreateDependantMutationVariables
>;
export const UpdateDependantDocument = gql`
  mutation UpdateDependant($id: ID!, $companyId: ID!, $version: Int!, $input: UpdateDependantMutationInput!) {
    updateDependant(id: $id, companyId: $companyId, _version: $version, input: $input) {
      ...DependantFields
    }
  }
  ${DependantFieldsFragmentDoc}
`;
export type TUpdateDependantMutationFn = Apollo.MutationFunction<
  TUpdateDependantMutation,
  TUpdateDependantMutationVariables
>;

/**
 * __useUpdateDependantMutation__
 *
 * To run a mutation, you first call `useUpdateDependantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDependantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDependantMutation, { data, loading, error }] = useUpdateDependantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDependantMutation(
  baseOptions?: Apollo.MutationHookOptions<TUpdateDependantMutation, TUpdateDependantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUpdateDependantMutation, TUpdateDependantMutationVariables>(
    UpdateDependantDocument,
    options,
  );
}
export type UpdateDependantMutationHookResult = ReturnType<typeof useUpdateDependantMutation>;
export type UpdateDependantMutationResult = Apollo.MutationResult<TUpdateDependantMutation>;
export type UpdateDependantMutationOptions = Apollo.BaseMutationOptions<
  TUpdateDependantMutation,
  TUpdateDependantMutationVariables
>;
export const DeleteDependantDocument = gql`
  mutation DeleteDependant($id: ID!, $companyId: ID!, $version: Int!) {
    deleteDependant(id: $id, companyId: $companyId, _version: $version) {
      ...SubscriptionDependantFields
    }
  }
  ${SubscriptionDependantFieldsFragmentDoc}
`;
export type TDeleteDependantMutationFn = Apollo.MutationFunction<
  TDeleteDependantMutation,
  TDeleteDependantMutationVariables
>;

/**
 * __useDeleteDependantMutation__
 *
 * To run a mutation, you first call `useDeleteDependantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDependantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDependantMutation, { data, loading, error }] = useDeleteDependantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDeleteDependantMutation(
  baseOptions?: Apollo.MutationHookOptions<TDeleteDependantMutation, TDeleteDependantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TDeleteDependantMutation, TDeleteDependantMutationVariables>(
    DeleteDependantDocument,
    options,
  );
}
export type DeleteDependantMutationHookResult = ReturnType<typeof useDeleteDependantMutation>;
export type DeleteDependantMutationResult = Apollo.MutationResult<TDeleteDependantMutation>;
export type DeleteDependantMutationOptions = Apollo.BaseMutationOptions<
  TDeleteDependantMutation,
  TDeleteDependantMutationVariables
>;
export const OnCompanyDependantChangedDocument = gql`
  subscription OnCompanyDependantChanged($companyId: ID!) {
    onCompanyDependantChanged(companyId: $companyId) {
      ...SubscriptionDependantFields
    }
  }
  ${SubscriptionDependantFieldsFragmentDoc}
`;

/**
 * __useOnCompanyDependantChangedSubscription__
 *
 * To run a query within a React component, call `useOnCompanyDependantChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCompanyDependantChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCompanyDependantChangedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnCompanyDependantChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnCompanyDependantChangedSubscription,
    TOnCompanyDependantChangedSubscriptionVariables
  > &
    ({ variables: TOnCompanyDependantChangedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TOnCompanyDependantChangedSubscription,
    TOnCompanyDependantChangedSubscriptionVariables
  >(OnCompanyDependantChangedDocument, options);
}
export type OnCompanyDependantChangedSubscriptionHookResult = ReturnType<
  typeof useOnCompanyDependantChangedSubscription
>;
export type OnCompanyDependantChangedSubscriptionResult =
  Apollo.SubscriptionResult<TOnCompanyDependantChangedSubscription>;
export const OnEmployeeDependantChangedDocument = gql`
  subscription OnEmployeeDependantChanged($companyId: ID!, $employeeId: ID!) {
    onEmployeeDependantChanged(companyId: $companyId, employeeId: $employeeId) {
      ...SubscriptionDependantFields
    }
  }
  ${SubscriptionDependantFieldsFragmentDoc}
`;

/**
 * __useOnEmployeeDependantChangedSubscription__
 *
 * To run a query within a React component, call `useOnEmployeeDependantChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnEmployeeDependantChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnEmployeeDependantChangedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useOnEmployeeDependantChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnEmployeeDependantChangedSubscription,
    TOnEmployeeDependantChangedSubscriptionVariables
  > &
    ({ variables: TOnEmployeeDependantChangedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TOnEmployeeDependantChangedSubscription,
    TOnEmployeeDependantChangedSubscriptionVariables
  >(OnEmployeeDependantChangedDocument, options);
}
export type OnEmployeeDependantChangedSubscriptionHookResult = ReturnType<
  typeof useOnEmployeeDependantChangedSubscription
>;
export type OnEmployeeDependantChangedSubscriptionResult =
  Apollo.SubscriptionResult<TOnEmployeeDependantChangedSubscription>;
export const ListCompanyEmployeesDocument = gql`
  query ListCompanyEmployees($companyId: ID!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listCompanyEmployees(companyId: $companyId, nextToken: $nextToken, limit: $limit, order: $order)
      @connection(key: $companyId) {
      nextToken
      items {
        ...ListEmployeeFields
      }
    }
  }
  ${ListEmployeeFieldsFragmentDoc}
`;

/**
 * __useListCompanyEmployeesQuery__
 *
 * To run a query within a React component, call `useListCompanyEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyEmployeesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListCompanyEmployeesQuery(
  baseOptions: Apollo.QueryHookOptions<TListCompanyEmployeesQuery, TListCompanyEmployeesQueryVariables> &
    ({ variables: TListCompanyEmployeesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListCompanyEmployeesQuery, TListCompanyEmployeesQueryVariables>(
    ListCompanyEmployeesDocument,
    options,
  );
}
export function useListCompanyEmployeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListCompanyEmployeesQuery, TListCompanyEmployeesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListCompanyEmployeesQuery, TListCompanyEmployeesQueryVariables>(
    ListCompanyEmployeesDocument,
    options,
  );
}
export function useListCompanyEmployeesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TListCompanyEmployeesQuery, TListCompanyEmployeesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TListCompanyEmployeesQuery, TListCompanyEmployeesQueryVariables>(
    ListCompanyEmployeesDocument,
    options,
  );
}
export type ListCompanyEmployeesQueryHookResult = ReturnType<typeof useListCompanyEmployeesQuery>;
export type ListCompanyEmployeesLazyQueryHookResult = ReturnType<typeof useListCompanyEmployeesLazyQuery>;
export type ListCompanyEmployeesSuspenseQueryHookResult = ReturnType<typeof useListCompanyEmployeesSuspenseQuery>;
export type ListCompanyEmployeesQueryResult = Apollo.QueryResult<
  TListCompanyEmployeesQuery,
  TListCompanyEmployeesQueryVariables
>;
export const GetEmployeeDocument = gql`
  query GetEmployee($id: ID!, $companyId: ID!) {
    getEmployee(id: $id, companyId: $companyId) {
      ...EmployeeFields
    }
  }
  ${EmployeeFieldsFragmentDoc}
`;

/**
 * __useGetEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetEmployeeQuery(
  baseOptions: Apollo.QueryHookOptions<TGetEmployeeQuery, TGetEmployeeQueryVariables> &
    ({ variables: TGetEmployeeQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetEmployeeQuery, TGetEmployeeQueryVariables>(GetEmployeeDocument, options);
}
export function useGetEmployeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetEmployeeQuery, TGetEmployeeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetEmployeeQuery, TGetEmployeeQueryVariables>(GetEmployeeDocument, options);
}
export function useGetEmployeeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TGetEmployeeQuery, TGetEmployeeQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TGetEmployeeQuery, TGetEmployeeQueryVariables>(GetEmployeeDocument, options);
}
export type GetEmployeeQueryHookResult = ReturnType<typeof useGetEmployeeQuery>;
export type GetEmployeeLazyQueryHookResult = ReturnType<typeof useGetEmployeeLazyQuery>;
export type GetEmployeeSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeSuspenseQuery>;
export type GetEmployeeQueryResult = Apollo.QueryResult<TGetEmployeeQuery, TGetEmployeeQueryVariables>;
export const CreateEmployeeDocument = gql`
  mutation CreateEmployee($input: CreateEmployeeMutationInput!) {
    createEmployee(input: $input) {
      ...EmployeeFields
    }
  }
  ${EmployeeFieldsFragmentDoc}
`;
export type TCreateEmployeeMutationFn = Apollo.MutationFunction<
  TCreateEmployeeMutation,
  TCreateEmployeeMutationVariables
>;

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateEmployeeMutation, TCreateEmployeeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateEmployeeMutation, TCreateEmployeeMutationVariables>(CreateEmployeeDocument, options);
}
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = Apollo.MutationResult<TCreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<
  TCreateEmployeeMutation,
  TCreateEmployeeMutationVariables
>;
export const UpdateEmployeeDocument = gql`
  mutation UpdateEmployee($id: ID!, $companyId: ID!, $version: Int!, $input: UpdateEmployeeMutationInput!) {
    updateEmployee(id: $id, companyId: $companyId, _version: $version, input: $input) {
      ...EmployeeFields
    }
  }
  ${EmployeeFieldsFragmentDoc}
`;
export type TUpdateEmployeeMutationFn = Apollo.MutationFunction<
  TUpdateEmployeeMutation,
  TUpdateEmployeeMutationVariables
>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(
  baseOptions?: Apollo.MutationHookOptions<TUpdateEmployeeMutation, TUpdateEmployeeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUpdateEmployeeMutation, TUpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
}
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<TUpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<
  TUpdateEmployeeMutation,
  TUpdateEmployeeMutationVariables
>;
export const DeleteEmployeeDocument = gql`
  mutation DeleteEmployee($id: ID!, $companyId: ID!, $version: Int!) {
    deleteEmployee(id: $id, companyId: $companyId, _version: $version) {
      ...SubscriptionEmployeeFields
    }
  }
  ${SubscriptionEmployeeFieldsFragmentDoc}
`;
export type TDeleteEmployeeMutationFn = Apollo.MutationFunction<
  TDeleteEmployeeMutation,
  TDeleteEmployeeMutationVariables
>;

/**
 * __useDeleteEmployeeMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeMutation, { data, loading, error }] = useDeleteEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDeleteEmployeeMutation(
  baseOptions?: Apollo.MutationHookOptions<TDeleteEmployeeMutation, TDeleteEmployeeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TDeleteEmployeeMutation, TDeleteEmployeeMutationVariables>(DeleteEmployeeDocument, options);
}
export type DeleteEmployeeMutationHookResult = ReturnType<typeof useDeleteEmployeeMutation>;
export type DeleteEmployeeMutationResult = Apollo.MutationResult<TDeleteEmployeeMutation>;
export type DeleteEmployeeMutationOptions = Apollo.BaseMutationOptions<
  TDeleteEmployeeMutation,
  TDeleteEmployeeMutationVariables
>;
export const PauseEmployeeGiftsDocument = gql`
  mutation PauseEmployeeGifts($id: ID!, $companyId: ID!, $version: Int!, $fromDate: AWSDate, $toDate: AWSDate) {
    pauseEmployeeGifts(id: $id, companyId: $companyId, _version: $version, fromDate: $fromDate, toDate: $toDate) {
      ...SubscriptionEmployeeFields
    }
  }
  ${SubscriptionEmployeeFieldsFragmentDoc}
`;
export type TPauseEmployeeGiftsMutationFn = Apollo.MutationFunction<
  TPauseEmployeeGiftsMutation,
  TPauseEmployeeGiftsMutationVariables
>;

/**
 * __usePauseEmployeeGiftsMutation__
 *
 * To run a mutation, you first call `usePauseEmployeeGiftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseEmployeeGiftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseEmployeeGiftsMutation, { data, loading, error }] = usePauseEmployeeGiftsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function usePauseEmployeeGiftsMutation(
  baseOptions?: Apollo.MutationHookOptions<TPauseEmployeeGiftsMutation, TPauseEmployeeGiftsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TPauseEmployeeGiftsMutation, TPauseEmployeeGiftsMutationVariables>(
    PauseEmployeeGiftsDocument,
    options,
  );
}
export type PauseEmployeeGiftsMutationHookResult = ReturnType<typeof usePauseEmployeeGiftsMutation>;
export type PauseEmployeeGiftsMutationResult = Apollo.MutationResult<TPauseEmployeeGiftsMutation>;
export type PauseEmployeeGiftsMutationOptions = Apollo.BaseMutationOptions<
  TPauseEmployeeGiftsMutation,
  TPauseEmployeeGiftsMutationVariables
>;
export const UnpauseEmployeeGiftsDocument = gql`
  mutation UnpauseEmployeeGifts($id: ID!, $companyId: ID!, $version: Int!) {
    unpauseEmployeeGifts(id: $id, companyId: $companyId, _version: $version) {
      ...SubscriptionEmployeeFields
    }
  }
  ${SubscriptionEmployeeFieldsFragmentDoc}
`;
export type TUnpauseEmployeeGiftsMutationFn = Apollo.MutationFunction<
  TUnpauseEmployeeGiftsMutation,
  TUnpauseEmployeeGiftsMutationVariables
>;

/**
 * __useUnpauseEmployeeGiftsMutation__
 *
 * To run a mutation, you first call `useUnpauseEmployeeGiftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpauseEmployeeGiftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpauseEmployeeGiftsMutation, { data, loading, error }] = useUnpauseEmployeeGiftsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useUnpauseEmployeeGiftsMutation(
  baseOptions?: Apollo.MutationHookOptions<TUnpauseEmployeeGiftsMutation, TUnpauseEmployeeGiftsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUnpauseEmployeeGiftsMutation, TUnpauseEmployeeGiftsMutationVariables>(
    UnpauseEmployeeGiftsDocument,
    options,
  );
}
export type UnpauseEmployeeGiftsMutationHookResult = ReturnType<typeof useUnpauseEmployeeGiftsMutation>;
export type UnpauseEmployeeGiftsMutationResult = Apollo.MutationResult<TUnpauseEmployeeGiftsMutation>;
export type UnpauseEmployeeGiftsMutationOptions = Apollo.BaseMutationOptions<
  TUnpauseEmployeeGiftsMutation,
  TUnpauseEmployeeGiftsMutationVariables
>;
export const OnCompanyEmployeeChangedDocument = gql`
  subscription OnCompanyEmployeeChanged($companyId: ID!) {
    onCompanyEmployeeChanged(companyId: $companyId) {
      ...SubscriptionEmployeeFields
    }
  }
  ${SubscriptionEmployeeFieldsFragmentDoc}
`;

/**
 * __useOnCompanyEmployeeChangedSubscription__
 *
 * To run a query within a React component, call `useOnCompanyEmployeeChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCompanyEmployeeChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCompanyEmployeeChangedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnCompanyEmployeeChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnCompanyEmployeeChangedSubscription,
    TOnCompanyEmployeeChangedSubscriptionVariables
  > &
    ({ variables: TOnCompanyEmployeeChangedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnCompanyEmployeeChangedSubscription, TOnCompanyEmployeeChangedSubscriptionVariables>(
    OnCompanyEmployeeChangedDocument,
    options,
  );
}
export type OnCompanyEmployeeChangedSubscriptionHookResult = ReturnType<typeof useOnCompanyEmployeeChangedSubscription>;
export type OnCompanyEmployeeChangedSubscriptionResult =
  Apollo.SubscriptionResult<TOnCompanyEmployeeChangedSubscription>;
export const ListCompanyUpcomingEventsDocument = gql`
  query ListCompanyUpcomingEvents($companyId: ID!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listCompanyUpcomingEvents(companyId: $companyId, nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...EventFields
      }
    }
  }
  ${EventFieldsFragmentDoc}
`;

/**
 * __useListCompanyUpcomingEventsQuery__
 *
 * To run a query within a React component, call `useListCompanyUpcomingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyUpcomingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyUpcomingEventsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListCompanyUpcomingEventsQuery(
  baseOptions: Apollo.QueryHookOptions<TListCompanyUpcomingEventsQuery, TListCompanyUpcomingEventsQueryVariables> &
    ({ variables: TListCompanyUpcomingEventsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListCompanyUpcomingEventsQuery, TListCompanyUpcomingEventsQueryVariables>(
    ListCompanyUpcomingEventsDocument,
    options,
  );
}
export function useListCompanyUpcomingEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListCompanyUpcomingEventsQuery, TListCompanyUpcomingEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListCompanyUpcomingEventsQuery, TListCompanyUpcomingEventsQueryVariables>(
    ListCompanyUpcomingEventsDocument,
    options,
  );
}
export function useListCompanyUpcomingEventsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TListCompanyUpcomingEventsQuery, TListCompanyUpcomingEventsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TListCompanyUpcomingEventsQuery, TListCompanyUpcomingEventsQueryVariables>(
    ListCompanyUpcomingEventsDocument,
    options,
  );
}
export type ListCompanyUpcomingEventsQueryHookResult = ReturnType<typeof useListCompanyUpcomingEventsQuery>;
export type ListCompanyUpcomingEventsLazyQueryHookResult = ReturnType<typeof useListCompanyUpcomingEventsLazyQuery>;
export type ListCompanyUpcomingEventsSuspenseQueryHookResult = ReturnType<
  typeof useListCompanyUpcomingEventsSuspenseQuery
>;
export type ListCompanyUpcomingEventsQueryResult = Apollo.QueryResult<
  TListCompanyUpcomingEventsQuery,
  TListCompanyUpcomingEventsQueryVariables
>;
export const GetEventDocument = gql`
  query GetEvent($id: ID!, $companyId: ID!) {
    getEvent(id: $id, companyId: $companyId) {
      ...EventFields
    }
  }
  ${EventFieldsFragmentDoc}
`;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetEventQuery(
  baseOptions: Apollo.QueryHookOptions<TGetEventQuery, TGetEventQueryVariables> &
    ({ variables: TGetEventQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetEventQuery, TGetEventQueryVariables>(GetEventDocument, options);
}
export function useGetEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetEventQuery, TGetEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetEventQuery, TGetEventQueryVariables>(GetEventDocument, options);
}
export function useGetEventSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TGetEventQuery, TGetEventQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TGetEventQuery, TGetEventQueryVariables>(GetEventDocument, options);
}
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventSuspenseQueryHookResult = ReturnType<typeof useGetEventSuspenseQuery>;
export type GetEventQueryResult = Apollo.QueryResult<TGetEventQuery, TGetEventQueryVariables>;
export const OnCompanyEventChangedDocument = gql`
  subscription onCompanyEventChanged($companyId: ID!) {
    onCompanyEventChanged(companyId: $companyId) {
      ...EventFields
    }
  }
  ${EventFieldsFragmentDoc}
`;

/**
 * __useOnCompanyEventChangedSubscription__
 *
 * To run a query within a React component, call `useOnCompanyEventChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCompanyEventChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCompanyEventChangedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnCompanyEventChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnCompanyEventChangedSubscription,
    TOnCompanyEventChangedSubscriptionVariables
  > &
    ({ variables: TOnCompanyEventChangedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnCompanyEventChangedSubscription, TOnCompanyEventChangedSubscriptionVariables>(
    OnCompanyEventChangedDocument,
    options,
  );
}
export type OnCompanyEventChangedSubscriptionHookResult = ReturnType<typeof useOnCompanyEventChangedSubscription>;
export type OnCompanyEventChangedSubscriptionResult = Apollo.SubscriptionResult<TOnCompanyEventChangedSubscription>;
export const ListEmployeeAndDependatsGiftsDocument = gql`
  query ListEmployeeAndDependatsGifts(
    $employeeId: ID!
    $companyId: ID!
    $nextToken: String
    $limit: Int
    $order: OrderDirection
  ) {
    listEmployeeAndDependatsGifts(
      employeeId: $employeeId
      companyId: $companyId
      nextToken: $nextToken
      limit: $limit
      order: $order
    ) {
      nextToken
      items {
        ...GiftFields
      }
    }
  }
  ${GiftFieldsFragmentDoc}
`;

/**
 * __useListEmployeeAndDependatsGiftsQuery__
 *
 * To run a query within a React component, call `useListEmployeeAndDependatsGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeeAndDependatsGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeeAndDependatsGiftsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListEmployeeAndDependatsGiftsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TListEmployeeAndDependatsGiftsQuery,
    TListEmployeeAndDependatsGiftsQueryVariables
  > &
    ({ variables: TListEmployeeAndDependatsGiftsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListEmployeeAndDependatsGiftsQuery, TListEmployeeAndDependatsGiftsQueryVariables>(
    ListEmployeeAndDependatsGiftsDocument,
    options,
  );
}
export function useListEmployeeAndDependatsGiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TListEmployeeAndDependatsGiftsQuery,
    TListEmployeeAndDependatsGiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListEmployeeAndDependatsGiftsQuery, TListEmployeeAndDependatsGiftsQueryVariables>(
    ListEmployeeAndDependatsGiftsDocument,
    options,
  );
}
export function useListEmployeeAndDependatsGiftsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TListEmployeeAndDependatsGiftsQuery,
        TListEmployeeAndDependatsGiftsQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TListEmployeeAndDependatsGiftsQuery, TListEmployeeAndDependatsGiftsQueryVariables>(
    ListEmployeeAndDependatsGiftsDocument,
    options,
  );
}
export type ListEmployeeAndDependatsGiftsQueryHookResult = ReturnType<typeof useListEmployeeAndDependatsGiftsQuery>;
export type ListEmployeeAndDependatsGiftsLazyQueryHookResult = ReturnType<
  typeof useListEmployeeAndDependatsGiftsLazyQuery
>;
export type ListEmployeeAndDependatsGiftsSuspenseQueryHookResult = ReturnType<
  typeof useListEmployeeAndDependatsGiftsSuspenseQuery
>;
export type ListEmployeeAndDependatsGiftsQueryResult = Apollo.QueryResult<
  TListEmployeeAndDependatsGiftsQuery,
  TListEmployeeAndDependatsGiftsQueryVariables
>;
export const GetGiftDocument = gql`
  query GetGift($id: ID!, $companyId: ID!) {
    getGift(id: $id, companyId: $companyId) {
      ...GiftFields
    }
  }
  ${GiftFieldsFragmentDoc}
`;

/**
 * __useGetGiftQuery__
 *
 * To run a query within a React component, call `useGetGiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiftQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetGiftQuery(
  baseOptions: Apollo.QueryHookOptions<TGetGiftQuery, TGetGiftQueryVariables> &
    ({ variables: TGetGiftQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetGiftQuery, TGetGiftQueryVariables>(GetGiftDocument, options);
}
export function useGetGiftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TGetGiftQuery, TGetGiftQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetGiftQuery, TGetGiftQueryVariables>(GetGiftDocument, options);
}
export function useGetGiftSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TGetGiftQuery, TGetGiftQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TGetGiftQuery, TGetGiftQueryVariables>(GetGiftDocument, options);
}
export type GetGiftQueryHookResult = ReturnType<typeof useGetGiftQuery>;
export type GetGiftLazyQueryHookResult = ReturnType<typeof useGetGiftLazyQuery>;
export type GetGiftSuspenseQueryHookResult = ReturnType<typeof useGetGiftSuspenseQuery>;
export type GetGiftQueryResult = Apollo.QueryResult<TGetGiftQuery, TGetGiftQueryVariables>;
export const OnCompanyEventGiftCreatedDocument = gql`
  subscription OnCompanyEventGiftCreated($companyId: ID!) {
    onCompanyEventGiftCreated(companyId: $companyId) {
      gift {
        ...SubscriptionGiftFields
      }
    }
  }
  ${SubscriptionGiftFieldsFragmentDoc}
`;

/**
 * __useOnCompanyEventGiftCreatedSubscription__
 *
 * To run a query within a React component, call `useOnCompanyEventGiftCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCompanyEventGiftCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCompanyEventGiftCreatedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnCompanyEventGiftCreatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnCompanyEventGiftCreatedSubscription,
    TOnCompanyEventGiftCreatedSubscriptionVariables
  > &
    ({ variables: TOnCompanyEventGiftCreatedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TOnCompanyEventGiftCreatedSubscription,
    TOnCompanyEventGiftCreatedSubscriptionVariables
  >(OnCompanyEventGiftCreatedDocument, options);
}
export type OnCompanyEventGiftCreatedSubscriptionHookResult = ReturnType<
  typeof useOnCompanyEventGiftCreatedSubscription
>;
export type OnCompanyEventGiftCreatedSubscriptionResult =
  Apollo.SubscriptionResult<TOnCompanyEventGiftCreatedSubscription>;
export const OnCompanyGiftRemovedFromGroupDocument = gql`
  subscription OnCompanyGiftRemovedFromGroup($companyId: ID!) {
    onCompanyGiftRemovedFromGroup(companyId: $companyId) {
      items {
        ...SubscriptionGiftFields
      }
    }
  }
  ${SubscriptionGiftFieldsFragmentDoc}
`;

/**
 * __useOnCompanyGiftRemovedFromGroupSubscription__
 *
 * To run a query within a React component, call `useOnCompanyGiftRemovedFromGroupSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCompanyGiftRemovedFromGroupSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCompanyGiftRemovedFromGroupSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnCompanyGiftRemovedFromGroupSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnCompanyGiftRemovedFromGroupSubscription,
    TOnCompanyGiftRemovedFromGroupSubscriptionVariables
  > &
    ({ variables: TOnCompanyGiftRemovedFromGroupSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TOnCompanyGiftRemovedFromGroupSubscription,
    TOnCompanyGiftRemovedFromGroupSubscriptionVariables
  >(OnCompanyGiftRemovedFromGroupDocument, options);
}
export type OnCompanyGiftRemovedFromGroupSubscriptionHookResult = ReturnType<
  typeof useOnCompanyGiftRemovedFromGroupSubscription
>;
export type OnCompanyGiftRemovedFromGroupSubscriptionResult =
  Apollo.SubscriptionResult<TOnCompanyGiftRemovedFromGroupSubscription>;
export const OnCompanyGiftChangedDocument = gql`
  subscription OnCompanyGiftChanged($companyId: ID!) {
    onCompanyGiftChanged(companyId: $companyId) {
      ...SubscriptionGiftFields
    }
  }
  ${SubscriptionGiftFieldsFragmentDoc}
`;

/**
 * __useOnCompanyGiftChangedSubscription__
 *
 * To run a query within a React component, call `useOnCompanyGiftChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCompanyGiftChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCompanyGiftChangedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnCompanyGiftChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnCompanyGiftChangedSubscription,
    TOnCompanyGiftChangedSubscriptionVariables
  > &
    ({ variables: TOnCompanyGiftChangedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnCompanyGiftChangedSubscription, TOnCompanyGiftChangedSubscriptionVariables>(
    OnCompanyGiftChangedDocument,
    options,
  );
}
export type OnCompanyGiftChangedSubscriptionHookResult = ReturnType<typeof useOnCompanyGiftChangedSubscription>;
export type OnCompanyGiftChangedSubscriptionResult = Apollo.SubscriptionResult<TOnCompanyGiftChangedSubscription>;
export const OnEmployeeGiftChangedDocument = gql`
  subscription OnEmployeeGiftChanged($employeeId: ID!, $companyId: ID!) {
    onEmployeeGiftChanged(employeeId: $employeeId, companyId: $companyId) {
      ...SubscriptionGiftFields
    }
  }
  ${SubscriptionGiftFieldsFragmentDoc}
`;

/**
 * __useOnEmployeeGiftChangedSubscription__
 *
 * To run a query within a React component, call `useOnEmployeeGiftChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnEmployeeGiftChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnEmployeeGiftChangedSubscription({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnEmployeeGiftChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnEmployeeGiftChangedSubscription,
    TOnEmployeeGiftChangedSubscriptionVariables
  > &
    ({ variables: TOnEmployeeGiftChangedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnEmployeeGiftChangedSubscription, TOnEmployeeGiftChangedSubscriptionVariables>(
    OnEmployeeGiftChangedDocument,
    options,
  );
}
export type OnEmployeeGiftChangedSubscriptionHookResult = ReturnType<typeof useOnEmployeeGiftChangedSubscription>;
export type OnEmployeeGiftChangedSubscriptionResult = Apollo.SubscriptionResult<TOnEmployeeGiftChangedSubscription>;
export const ListOfficesDocument = gql`
  query ListOffices($companyId: ID!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listOffices(companyId: $companyId, nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...OfficeFields
      }
    }
  }
  ${OfficeFieldsFragmentDoc}
`;

/**
 * __useListOfficesQuery__
 *
 * To run a query within a React component, call `useListOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOfficesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListOfficesQuery(
  baseOptions: Apollo.QueryHookOptions<TListOfficesQuery, TListOfficesQueryVariables> &
    ({ variables: TListOfficesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListOfficesQuery, TListOfficesQueryVariables>(ListOfficesDocument, options);
}
export function useListOfficesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListOfficesQuery, TListOfficesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListOfficesQuery, TListOfficesQueryVariables>(ListOfficesDocument, options);
}
export function useListOfficesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TListOfficesQuery, TListOfficesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TListOfficesQuery, TListOfficesQueryVariables>(ListOfficesDocument, options);
}
export type ListOfficesQueryHookResult = ReturnType<typeof useListOfficesQuery>;
export type ListOfficesLazyQueryHookResult = ReturnType<typeof useListOfficesLazyQuery>;
export type ListOfficesSuspenseQueryHookResult = ReturnType<typeof useListOfficesSuspenseQuery>;
export type ListOfficesQueryResult = Apollo.QueryResult<TListOfficesQuery, TListOfficesQueryVariables>;
export const GetOfficeDocument = gql`
  query GetOffice($id: ID!, $companyId: ID!) {
    getOffice(id: $id, companyId: $companyId) {
      ...OfficeFields
    }
  }
  ${OfficeFieldsFragmentDoc}
`;

/**
 * __useGetOfficeQuery__
 *
 * To run a query within a React component, call `useGetOfficeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetOfficeQuery(
  baseOptions: Apollo.QueryHookOptions<TGetOfficeQuery, TGetOfficeQueryVariables> &
    ({ variables: TGetOfficeQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetOfficeQuery, TGetOfficeQueryVariables>(GetOfficeDocument, options);
}
export function useGetOfficeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetOfficeQuery, TGetOfficeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetOfficeQuery, TGetOfficeQueryVariables>(GetOfficeDocument, options);
}
export function useGetOfficeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TGetOfficeQuery, TGetOfficeQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TGetOfficeQuery, TGetOfficeQueryVariables>(GetOfficeDocument, options);
}
export type GetOfficeQueryHookResult = ReturnType<typeof useGetOfficeQuery>;
export type GetOfficeLazyQueryHookResult = ReturnType<typeof useGetOfficeLazyQuery>;
export type GetOfficeSuspenseQueryHookResult = ReturnType<typeof useGetOfficeSuspenseQuery>;
export type GetOfficeQueryResult = Apollo.QueryResult<TGetOfficeQuery, TGetOfficeQueryVariables>;
export const CreateOfficeDocument = gql`
  mutation CreateOffice($input: CreateOfficeMutationInput!) {
    createOffice(input: $input) {
      ...OfficeFields
    }
  }
  ${OfficeFieldsFragmentDoc}
`;
export type TCreateOfficeMutationFn = Apollo.MutationFunction<TCreateOfficeMutation, TCreateOfficeMutationVariables>;

/**
 * __useCreateOfficeMutation__
 *
 * To run a mutation, you first call `useCreateOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfficeMutation, { data, loading, error }] = useCreateOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateOfficeMutation, TCreateOfficeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateOfficeMutation, TCreateOfficeMutationVariables>(CreateOfficeDocument, options);
}
export type CreateOfficeMutationHookResult = ReturnType<typeof useCreateOfficeMutation>;
export type CreateOfficeMutationResult = Apollo.MutationResult<TCreateOfficeMutation>;
export type CreateOfficeMutationOptions = Apollo.BaseMutationOptions<
  TCreateOfficeMutation,
  TCreateOfficeMutationVariables
>;
export const UpdateOfficeDocument = gql`
  mutation UpdateOffice($id: ID!, $companyId: ID!, $version: Int!, $input: UpdateOfficeMutationInput!) {
    updateOffice(id: $id, companyId: $companyId, _version: $version, input: $input) {
      ...OfficeFields
    }
  }
  ${OfficeFieldsFragmentDoc}
`;
export type TUpdateOfficeMutationFn = Apollo.MutationFunction<TUpdateOfficeMutation, TUpdateOfficeMutationVariables>;

/**
 * __useUpdateOfficeMutation__
 *
 * To run a mutation, you first call `useUpdateOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfficeMutation, { data, loading, error }] = useUpdateOfficeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<TUpdateOfficeMutation, TUpdateOfficeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUpdateOfficeMutation, TUpdateOfficeMutationVariables>(UpdateOfficeDocument, options);
}
export type UpdateOfficeMutationHookResult = ReturnType<typeof useUpdateOfficeMutation>;
export type UpdateOfficeMutationResult = Apollo.MutationResult<TUpdateOfficeMutation>;
export type UpdateOfficeMutationOptions = Apollo.BaseMutationOptions<
  TUpdateOfficeMutation,
  TUpdateOfficeMutationVariables
>;
export const DeleteOfficeDocument = gql`
  mutation DeleteOffice($id: ID!, $companyId: ID!, $version: Int!) {
    deleteOffice(id: $id, companyId: $companyId, _version: $version) {
      ...SubscriptionOfficeFields
    }
  }
  ${SubscriptionOfficeFieldsFragmentDoc}
`;
export type TDeleteOfficeMutationFn = Apollo.MutationFunction<TDeleteOfficeMutation, TDeleteOfficeMutationVariables>;

/**
 * __useDeleteOfficeMutation__
 *
 * To run a mutation, you first call `useDeleteOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfficeMutation, { data, loading, error }] = useDeleteOfficeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDeleteOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<TDeleteOfficeMutation, TDeleteOfficeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TDeleteOfficeMutation, TDeleteOfficeMutationVariables>(DeleteOfficeDocument, options);
}
export type DeleteOfficeMutationHookResult = ReturnType<typeof useDeleteOfficeMutation>;
export type DeleteOfficeMutationResult = Apollo.MutationResult<TDeleteOfficeMutation>;
export type DeleteOfficeMutationOptions = Apollo.BaseMutationOptions<
  TDeleteOfficeMutation,
  TDeleteOfficeMutationVariables
>;
export const OnCompanyOfficeChangedDocument = gql`
  subscription OnCompanyOfficeChanged($companyId: ID!) {
    onCompanyOfficeChanged(companyId: $companyId) {
      ...SubscriptionOfficeFields
    }
  }
  ${SubscriptionOfficeFieldsFragmentDoc}
`;

/**
 * __useOnCompanyOfficeChangedSubscription__
 *
 * To run a query within a React component, call `useOnCompanyOfficeChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCompanyOfficeChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCompanyOfficeChangedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnCompanyOfficeChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnCompanyOfficeChangedSubscription,
    TOnCompanyOfficeChangedSubscriptionVariables
  > &
    ({ variables: TOnCompanyOfficeChangedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnCompanyOfficeChangedSubscription, TOnCompanyOfficeChangedSubscriptionVariables>(
    OnCompanyOfficeChangedDocument,
    options,
  );
}
export type OnCompanyOfficeChangedSubscriptionHookResult = ReturnType<typeof useOnCompanyOfficeChangedSubscription>;
export type OnCompanyOfficeChangedSubscriptionResult = Apollo.SubscriptionResult<TOnCompanyOfficeChangedSubscription>;
export const ListShipmentsDocument = gql`
  query ListShipments($companyId: ID!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listCompanyShipments(companyId: $companyId, nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...ShipmentFields
      }
    }
  }
  ${ShipmentFieldsFragmentDoc}
`;

/**
 * __useListShipmentsQuery__
 *
 * To run a query within a React component, call `useListShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListShipmentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListShipmentsQuery(
  baseOptions: Apollo.QueryHookOptions<TListShipmentsQuery, TListShipmentsQueryVariables> &
    ({ variables: TListShipmentsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListShipmentsQuery, TListShipmentsQueryVariables>(ListShipmentsDocument, options);
}
export function useListShipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListShipmentsQuery, TListShipmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListShipmentsQuery, TListShipmentsQueryVariables>(ListShipmentsDocument, options);
}
export function useListShipmentsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TListShipmentsQuery, TListShipmentsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TListShipmentsQuery, TListShipmentsQueryVariables>(ListShipmentsDocument, options);
}
export type ListShipmentsQueryHookResult = ReturnType<typeof useListShipmentsQuery>;
export type ListShipmentsLazyQueryHookResult = ReturnType<typeof useListShipmentsLazyQuery>;
export type ListShipmentsSuspenseQueryHookResult = ReturnType<typeof useListShipmentsSuspenseQuery>;
export type ListShipmentsQueryResult = Apollo.QueryResult<TListShipmentsQuery, TListShipmentsQueryVariables>;
export const GetShipmentDocument = gql`
  query GetShipment($id: ID!, $companyId: ID!) {
    getShipment(id: $id, companyId: $companyId) {
      ...ShipmentFields
    }
  }
  ${ShipmentFieldsFragmentDoc}
`;

/**
 * __useGetShipmentQuery__
 *
 * To run a query within a React component, call `useGetShipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetShipmentQuery(
  baseOptions: Apollo.QueryHookOptions<TGetShipmentQuery, TGetShipmentQueryVariables> &
    ({ variables: TGetShipmentQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetShipmentQuery, TGetShipmentQueryVariables>(GetShipmentDocument, options);
}
export function useGetShipmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetShipmentQuery, TGetShipmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetShipmentQuery, TGetShipmentQueryVariables>(GetShipmentDocument, options);
}
export function useGetShipmentSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TGetShipmentQuery, TGetShipmentQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TGetShipmentQuery, TGetShipmentQueryVariables>(GetShipmentDocument, options);
}
export type GetShipmentQueryHookResult = ReturnType<typeof useGetShipmentQuery>;
export type GetShipmentLazyQueryHookResult = ReturnType<typeof useGetShipmentLazyQuery>;
export type GetShipmentSuspenseQueryHookResult = ReturnType<typeof useGetShipmentSuspenseQuery>;
export type GetShipmentQueryResult = Apollo.QueryResult<TGetShipmentQuery, TGetShipmentQueryVariables>;
export const OnCompanyShipmentChangedDocument = gql`
  subscription OnCompanyShipmentChanged($companyId: ID!) {
    onCompanyShipmentChanged(companyId: $companyId) {
      ...ShipmentFields
    }
  }
  ${ShipmentFieldsFragmentDoc}
`;

/**
 * __useOnCompanyShipmentChangedSubscription__
 *
 * To run a query within a React component, call `useOnCompanyShipmentChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCompanyShipmentChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCompanyShipmentChangedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnCompanyShipmentChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnCompanyShipmentChangedSubscription,
    TOnCompanyShipmentChangedSubscriptionVariables
  > &
    ({ variables: TOnCompanyShipmentChangedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnCompanyShipmentChangedSubscription, TOnCompanyShipmentChangedSubscriptionVariables>(
    OnCompanyShipmentChangedDocument,
    options,
  );
}
export type OnCompanyShipmentChangedSubscriptionHookResult = ReturnType<typeof useOnCompanyShipmentChangedSubscription>;
export type OnCompanyShipmentChangedSubscriptionResult =
  Apollo.SubscriptionResult<TOnCompanyShipmentChangedSubscription>;
export const OnCompanyShipmentRecreatedDocument = gql`
  subscription OnCompanyShipmentRecreated($companyId: ID!) {
    onCompanyShipmentRecreated(companyId: $companyId) {
      companyId
      items {
        ...SubscriptionGiftFields
      }
    }
  }
  ${SubscriptionGiftFieldsFragmentDoc}
`;

/**
 * __useOnCompanyShipmentRecreatedSubscription__
 *
 * To run a query within a React component, call `useOnCompanyShipmentRecreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCompanyShipmentRecreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCompanyShipmentRecreatedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnCompanyShipmentRecreatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnCompanyShipmentRecreatedSubscription,
    TOnCompanyShipmentRecreatedSubscriptionVariables
  > &
    ({ variables: TOnCompanyShipmentRecreatedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TOnCompanyShipmentRecreatedSubscription,
    TOnCompanyShipmentRecreatedSubscriptionVariables
  >(OnCompanyShipmentRecreatedDocument, options);
}
export type OnCompanyShipmentRecreatedSubscriptionHookResult = ReturnType<
  typeof useOnCompanyShipmentRecreatedSubscription
>;
export type OnCompanyShipmentRecreatedSubscriptionResult =
  Apollo.SubscriptionResult<TOnCompanyShipmentRecreatedSubscription>;
export const ListUsersDocument = gql`
  query ListUsers($companyId: ID!, $nextToken: String, $limit: Int, $order: OrderDirection) {
    listUsers(companyId: $companyId, nextToken: $nextToken, limit: $limit, order: $order) {
      nextToken
      items {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListUsersQuery(
  baseOptions: Apollo.QueryHookOptions<TListUsersQuery, TListUsersQueryVariables> &
    ({ variables: TListUsersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TListUsersQuery, TListUsersQueryVariables>(ListUsersDocument, options);
}
export function useListUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TListUsersQuery, TListUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TListUsersQuery, TListUsersQueryVariables>(ListUsersDocument, options);
}
export function useListUsersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TListUsersQuery, TListUsersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TListUsersQuery, TListUsersQueryVariables>(ListUsersDocument, options);
}
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersSuspenseQueryHookResult = ReturnType<typeof useListUsersSuspenseQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<TListUsersQuery, TListUsersQueryVariables>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserMutationInput!) {
    createUser(input: $input) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type TCreateUserMutationFn = Apollo.MutationFunction<TCreateUserMutation, TCreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateUserMutation, TCreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateUserMutation, TCreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<TCreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<TCreateUserMutation, TCreateUserMutationVariables>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($email: AWSEmail!, $companyId: ID!, $version: Int!, $input: UpdateUserMutationInput!) {
    updateUser(email: $email, companyId: $companyId, _version: $version, input: $input) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type TUpdateUserMutationFn = Apollo.MutationFunction<TUpdateUserMutation, TUpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<TUpdateUserMutation, TUpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUpdateUserMutation, TUpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<TUpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<TUpdateUserMutation, TUpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($email: AWSEmail!, $companyId: ID!, $version: Int!) {
    deleteUser(email: $email, companyId: $companyId, _version: $version) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type TDeleteUserMutationFn = Apollo.MutationFunction<TDeleteUserMutation, TDeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<TDeleteUserMutation, TDeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TDeleteUserMutation, TDeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<TDeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<TDeleteUserMutation, TDeleteUserMutationVariables>;
export const TransferOwnershipDocument = gql`
  mutation TransferOwnership(
    $oldOwnerEmail: AWSEmail!
    $_oldOwnerVersion: Int!
    $newOwnerEmail: AWSEmail!
    $companyId: ID!
  ) {
    transferOwnership(
      oldOwnerEmail: $oldOwnerEmail
      _oldOwnerVersion: $_oldOwnerVersion
      newOwnerEmail: $newOwnerEmail
      companyId: $companyId
    ) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type TTransferOwnershipMutationFn = Apollo.MutationFunction<
  TTransferOwnershipMutation,
  TTransferOwnershipMutationVariables
>;

/**
 * __useTransferOwnershipMutation__
 *
 * To run a mutation, you first call `useTransferOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferOwnershipMutation, { data, loading, error }] = useTransferOwnershipMutation({
 *   variables: {
 *      oldOwnerEmail: // value for 'oldOwnerEmail'
 *      _oldOwnerVersion: // value for '_oldOwnerVersion'
 *      newOwnerEmail: // value for 'newOwnerEmail'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTransferOwnershipMutation(
  baseOptions?: Apollo.MutationHookOptions<TTransferOwnershipMutation, TTransferOwnershipMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TTransferOwnershipMutation, TTransferOwnershipMutationVariables>(
    TransferOwnershipDocument,
    options,
  );
}
export type TransferOwnershipMutationHookResult = ReturnType<typeof useTransferOwnershipMutation>;
export type TransferOwnershipMutationResult = Apollo.MutationResult<TTransferOwnershipMutation>;
export type TransferOwnershipMutationOptions = Apollo.BaseMutationOptions<
  TTransferOwnershipMutation,
  TTransferOwnershipMutationVariables
>;
export const OnCompanyUserChangedDocument = gql`
  subscription OnCompanyUserChanged($companyId: ID!) {
    onCompanyUserChanged(companyId: $companyId) {
      ...SubscriptionUserFields
    }
  }
  ${SubscriptionUserFieldsFragmentDoc}
`;

/**
 * __useOnCompanyUserChangedSubscription__
 *
 * To run a query within a React component, call `useOnCompanyUserChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCompanyUserChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCompanyUserChangedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnCompanyUserChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnCompanyUserChangedSubscription,
    TOnCompanyUserChangedSubscriptionVariables
  > &
    ({ variables: TOnCompanyUserChangedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnCompanyUserChangedSubscription, TOnCompanyUserChangedSubscriptionVariables>(
    OnCompanyUserChangedDocument,
    options,
  );
}
export type OnCompanyUserChangedSubscriptionHookResult = ReturnType<typeof useOnCompanyUserChangedSubscription>;
export type OnCompanyUserChangedSubscriptionResult = Apollo.SubscriptionResult<TOnCompanyUserChangedSubscription>;
export const OnCompanyOwnershipTransferedDocument = gql`
  subscription OnCompanyOwnershipTransfered($companyId: ID!) {
    onCompanyOwnershipTransfered(companyId: $companyId) {
      ...SubscriptionUserFields
    }
  }
  ${SubscriptionUserFieldsFragmentDoc}
`;

/**
 * __useOnCompanyOwnershipTransferedSubscription__
 *
 * To run a query within a React component, call `useOnCompanyOwnershipTransferedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCompanyOwnershipTransferedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCompanyOwnershipTransferedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnCompanyOwnershipTransferedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnCompanyOwnershipTransferedSubscription,
    TOnCompanyOwnershipTransferedSubscriptionVariables
  > &
    ({ variables: TOnCompanyOwnershipTransferedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TOnCompanyOwnershipTransferedSubscription,
    TOnCompanyOwnershipTransferedSubscriptionVariables
  >(OnCompanyOwnershipTransferedDocument, options);
}
export type OnCompanyOwnershipTransferedSubscriptionHookResult = ReturnType<
  typeof useOnCompanyOwnershipTransferedSubscription
>;
export type OnCompanyOwnershipTransferedSubscriptionResult =
  Apollo.SubscriptionResult<TOnCompanyOwnershipTransferedSubscription>;
