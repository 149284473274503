import {
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  OperationVariables,
  TypedDocumentNode,
  useFragment,
} from '@apollo/client';
import _ from 'lodash';
import { useEffect } from 'react';
import { subscribe } from '../../config/apollo/cache';

type TUserFragmentOrFetchProps<TQuery, TQueryVariables extends OperationVariables, TSubscriptionVariables> = {
  fragmentDoc: DocumentNode | TypedDocumentNode;
  fragmentName?: string;
  subscriptionDoc?: DocumentNode | TypedDocumentNode;
  subscriptionVariables?: TSubscriptionVariables;
  useLazyQuery: (
    baseOptions: LazyQueryHookOptions<TQuery, TQueryVariables>,
  ) => LazyQueryResultTuple<TQuery, TQueryVariables>;
  variables?: LazyQueryHookOptions<TQuery, TQueryVariables>['variables'];
  __typename: string;
};

export const useFragmentOrFetch = <
  T extends Record<string, any>,
  TQuery,
  TQueryVariables extends OperationVariables,
  TSubscriptionVariables = undefined,
>(
  props: TUserFragmentOrFetchProps<TQuery, TQueryVariables, TSubscriptionVariables>,
) => {
  const { fragmentDoc, fragmentName, subscriptionDoc, subscriptionVariables, useLazyQuery, variables, __typename } =
    props;

  const { data: fragmentData, missing } = useFragment<T, TQueryVariables>({
    from: {
      ...(variables || {}),
      __typename,
    },
    fragment: fragmentDoc,
    fragmentName,
  });

  const [fetchData, { data: fetchedData, error, loading }] = useLazyQuery({
    variables,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if ((_.isEmpty(fragmentData) || !_.isEmpty(missing)) && !loading && !error) {
      fetchData({
        variables,
      });
    }
  }, [error, fetchData, fragmentData, loading, missing, variables]);

  useEffect(() => {
    if (subscriptionDoc && subscriptionVariables) {
      subscribe({
        query: subscriptionDoc,
        variables: subscriptionVariables,
      });
    }
  }, [subscriptionDoc, subscriptionVariables]);

  return {
    data: fragmentData,
    fetchedData,
    error,
    loading,
  };
};
