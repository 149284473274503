import { ApolloError, SingleExecutionResult } from '@apollo/client';
import {
  ErrorPage,
  LoadingPage,
  Modal,
  ModalForm,
  TControlledFormProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { joiResolver } from '@hookform/resolvers/joi';
import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  TEmployee,
  TUpdateEmployeeMutation,
  TUpdateEmployeeMutationInput,
  useUpdateEmployeeMutation,
} from '../../../generated/graphql';
import { ACCEPT_ADDRESS_PATH, EMPLOYEES_PATH, EMPLOYEE_ACCOUNT_PATH, EMPLOYEE_PATH } from '../../../routes/paths';
import { useEmployeeForm } from './useEmployeeForm';
import styles from './EmployeeForm.module.scss';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { useFragmentOrFetchEmployee } from '../../../entities/employee/shared';
import { getEmployeeDefaultValues } from '../../../entities/employee/schema';
import { serializeError } from 'serialize-error';
import { toast } from 'react-toastify';
import _ from 'lodash';

export const EmployeeUpdateForm = () => {
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const company = usePrivateCompanyContext();

  const closeModal = useCallback(() => {
    navigate(generatePath(EMPLOYEES_PATH));
  }, [navigate]);

  const onBackClick = useCallback(() => {
    navigate(
      generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${EMPLOYEE_ACCOUNT_PATH}`, {
        employeeId: employeeId as string,
      }),
    );
  }, [employeeId, navigate]);

  const {
    data: employee,
    error: getEmployeeError,
    loading: getEmployeeLoading,
  } = useFragmentOrFetchEmployee({
    id: employeeId as string,
    companyId: company.id,
  });
  const title = useMemo(() => {
    if (employee?.fullName) {
      return `Update ${employee.fullName}`;
    } else {
      return 'Update Employee';
    }
  }, [employee?.fullName]);

  const { employeeFormFields, employeeSchema, loading: getOfficesLoading, error: getOfficesError } = useEmployeeForm();

  const [update] = useUpdateEmployeeMutation();

  const onSuccess = useCallback(
    (data: SingleExecutionResult<TUpdateEmployeeMutation>) => {
      if (data.errors) {
        for (const error of data.errors) {
          console.error(serializeError(error));
          toast.error(error.message);
        }
      } else {
        const address = data.data?.updateEmployee?.address;

        const {
          alert: addressAlert,
          address1,
          address2,
          city,
          state,
          country,
          zipCode,
          googleValidated,
          missingInfo,
          addressFromGoogle,
        } = address || {};

        if (
          data.data?.updateEmployee?.id &&
          googleValidated &&
          !_.isEmpty(addressFromGoogle) &&
          (address1 !== addressFromGoogle?.address1 ||
            address2 !== addressFromGoogle?.address2 ||
            city !== addressFromGoogle?.city ||
            state !== addressFromGoogle?.state ||
            country !== addressFromGoogle?.country ||
            zipCode !== addressFromGoogle?.zipCode)
        ) {
          // SHOW OPTION TO CHOOSE ADDRESS VALIDATED BY GOOGLE
          navigate(
            generatePath(`${EMPLOYEES_PATH}/${EMPLOYEE_PATH}/${ACCEPT_ADDRESS_PATH}`, {
              employeeId: data.data.updateEmployee.id,
            }),
          );
        } else {
          if (!_.isEmpty(address) && missingInfo) {
            toast.error(
              'The employee was updated but the address is missing some required fields. While these fields are not filled, gifts will not be sent to this address.',
            );
          } else if (!_.isEmpty(address) && !googleValidated && addressAlert) {
            toast.warning(
              'The employee was successfully updated but the address could not be validated by Google. Make sure it is correct otherwise it might be returned when sent.',
            );
          }

          onBackClick();
        }
      }
    },
    [navigate, onBackClick],
  );

  const onSubmitError = useCallback((error: ApolloError) => {
    if (error instanceof ApolloError) {
      for (const err of error.graphQLErrors) {
        console.error(serializeError(err));
        toast.error(err.message);
      }
    }
  }, []);

  const onSubmit = useCallback<TControlledFormProps<TUpdateEmployeeMutationInput>['onValidSubmit']>(
    (formData) => {
      console.log(formData);

      if (employeeId) {
        return update({
          variables: {
            id: employeeId,
            companyId: company.id,
            version: employee?._version as number,
            input: {
              ...formData,
              phoneNumber: formData?.phoneNumber == null ? [] : formData.phoneNumber,
              address: { ...formData.address, useGoogleAddress: false },
            },
          },
        })
          .then(onSuccess)
          .catch(onSubmitError);
      }
    },
    [company.id, employee?._version, employeeId, onSubmitError, onSuccess, update],
  );

  if (getEmployeeError || getOfficesError) {
    return <ErrorPage error={getEmployeeError || getOfficesError} />;
  }

  if (employee == null || getEmployeeLoading || getOfficesLoading) {
    return (
      <Modal onBackClick={onBackClick} closeModal={closeModal}>
        <LoadingPage />
      </Modal>
    );
  }

  return (
    <ModalForm
      closeModal={closeModal}
      onBackClick={onBackClick}
      controlledFormProps={{
        className: styles.form,
        fields: employeeFormFields,
        formProps: {
          defaultValues: getEmployeeDefaultValues(employee as TEmployee),
          resolver: joiResolver(employeeSchema, {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
      }}
      headline={title}
      size='large'
      supportingText='Updates might take up to 4 weeks to take effect since gifts might already be in production or in transit.'
    />
  );
};
