import { useMemo } from 'react';
import { ErrorPage, InfoCardInner, LoadingPage, readableDate } from '@chocolate-soup-inc/cs-frontend-components';
import { useParams } from 'react-router-dom';
import { TGetShipmentQuery } from '../../../generated/graphql';

import { StatusCell, TrackingLink } from '../../../shared/shipments/utils';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';
import { useFragmentOrFetchShipment } from '../../../entities/shipment/shared';
import { ShipmentModal } from './ShipmentModal';

import styles from './ShipmentDetails.module.scss';

type TShipment = Exclude<TGetShipmentQuery['getShipment'], null | undefined>;

export const ShipmentDetails = () => {
  const { deliveryId } = useParams();
  const { id: companyId } = usePrivateCompanyContext();

  const {
    data: shipment,
    error,
    loading,
  } = useFragmentOrFetchShipment({
    id: deliveryId as string,
    companyId,
  });

  const recipientName = useMemo(() => {
    if (shipment) {
      return shipment.name;
    }
  }, [shipment]);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage error={error} />;
  if (!shipment) return null;

  return (
    <ShipmentModal headline='Shipment Details'>
      <div className={styles.shipment}>
        <InfoCardInner
          data={shipment as TShipment}
          sections={[
            {
              columns: [
                {
                  label: 'Status',
                  render: () => {
                    return StatusCell(shipment as TShipment);
                  },
                },
                {
                  label: 'Shipping Date',
                  render: (data) => readableDate(data?.actualShippingDate || data?.shippingDate),
                },
                {
                  label: 'Tracking Number',
                  render: () => {
                    return TrackingLink(shipment as TShipment);
                  },
                  accessor: 'trackingNumber',
                },
                {
                  label: 'Recipient Name',
                  render: () => {
                    return <span>{recipientName}</span>;
                  },
                },
              ],
            },
          ]}
        />
        {shipment.address && (
          <InfoCardInner
            data={shipment as TShipment}
            sections={[
              {
                title: 'Address Information',
                columns: [
                  {
                    label: 'Address line 1',
                    render: (data) => data?.address?.address1,
                  },
                  {
                    label: 'Address line 2',
                    render: (data) => data?.address?.address2,
                  },
                  {
                    label: 'City',
                    render: (data) => data?.address?.city,
                  },
                  {
                    label: 'State',
                    render: (data) => data?.address?.state,
                  },
                  {
                    label: 'Country',
                    render: (data) => data?.address?.country,
                  },
                  {
                    label: 'Zip Code',
                    render: (data) => data?.address?.zipCode,
                  },
                ],
              },
            ]}
          />
        )}
      </div>
    </ShipmentModal>
  );
};
